import React from "react";
import {Box, Grid} from "@material-ui/core";
import TopMatches from "../../../common/matches/top/topMatches";
import HeaderTitle from "../../../common/headerTitle";


export default function Top({leagueId}: { leagueId: number }) {
    return (
        <Box mb={2} style={{
            minHeight: 300,
            backgroundColor: "white"
        }}>
            <HeaderTitle title={"Top zápasy ligy"}/>
            <Grid container>
                <Grid container spacing={1} direction={"column"} >
                    <Grid item><TopMatches statsType={"GOALS"} leagueId={leagueId}/> </Grid>
                    <Grid item><TopMatches statsType={"YELLOW_CARDS"} leagueId={leagueId}/> </Grid>
                    <Grid item><TopMatches statsType={"RED_CARDS"} leagueId={leagueId}/> </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}