import React, {useContext} from "react";
import {Box} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {serverUrl} from "../../../../index";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {UserContext} from "../../../auth/userContext";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";

interface Media {
    fb?: string,
    instagram?: string,
    youtube?: string,
    website?: string
}


export default function SocMedia({clubId}: { clubId: number }) {

    const [editMode, setEditMode] = React.useState<Boolean>(false);
    const [media, setMedia] = React.useState<Media | null>(null);
    const {userData, isLogged} = useContext(UserContext);
    const [tmpMedia, setTmpMedia] = React.useState<Media | null>(null);

    React.useEffect(() => {
        fetch(serverUrl + '/club/' + clubId + '/media')
            .then(res => res.json() as Promise<Media>)
            .then((result) => {
                setMedia(result)
            })
            .catch(console.log)

    }, [clubId]);

    const saveButtonClickHandler = () => {

        fetch(serverUrl + "/club/" + clubId + "/media",
            {
                method: "POST",
                headers: {
                    "X-Auth": userData.token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },

                body: JSON.stringify({
                    "fb": media?.fb ?? null,
                    "instagram": media?.instagram ?? null,
                    "youtube": media?.youtube ?? null,
                    "website": media?.website ?? null
                }, null, 2)
            })
            .then(res => res.json() as Promise<Media>)
            .then((result) => {
                console.log(result)
                setMedia(result);
            })
            .catch(console.log)

        setEditMode(false);
    }

    const backToNoEditMode = () => {
        setMedia(tmpMedia);
        setEditMode(false);

    }

    const changeEditMode = () => {
        setTmpMedia(media);
        setEditMode(true);
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        let name = event.target.name;
        let value = event.target.value;

        setMedia(prevState => ({
            ...prevState,
            [name]: value
        }))
    };

    const editModeActive = () => {
        const imageSize = 20;
        return (
            <Grid container style={{paddingTop: 10, paddingBottom: 10}}>
                <Grid container item xs={12} style={{paddingLeft: 10, marginBottom: 10}}>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item>
                            <img height={imageSize} width={imageSize}
                                 src={"https://www.kdesehraje.cz/media/socmedia/FB-logo.png"}/>
                        </Grid>
                        <Grid item>
                            <TextField id="fb-input" label="Odkaz na FB stránku" name={"fb"} value={media?.fb}
                                       onChange={handleChange}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} style={{paddingLeft: 10, marginBottom: 10}}>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item>
                            <img height={imageSize} width={imageSize}
                                 src={"https://www.kdesehraje.cz/media/socmedia/ig.webp"}/>
                        </Grid>
                        <Grid item>
                            <TextField id="instagram-input" label="Odkaz na Instragram" name={"instagram"}
                                       onChange={handleChange}
                                       value={media?.instagram}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} style={{paddingLeft: 10, marginBottom: 10}}>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item>
                            <img height={imageSize} width={imageSize}
                                 src={"https://www.kdesehraje.cz/media/socmedia/yt.png"}/>
                        </Grid>
                        <Grid item>
                            <TextField id="youtube-input" label="Odkaz na Youtube kanál" name={"youtube"}
                                       onChange={handleChange}
                                       value={media?.youtube}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} style={{paddingLeft: 10, marginBottom: 10}}>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item>
                            <img height={imageSize} width={imageSize}
                                 src={"https://www.kdesehraje.cz/media/socmedia/web.png"}/>
                        </Grid>
                        <Grid item>
                            <TextField id="website-input" label="Odkaz na Web" name={"website"} value={media?.website}
                                       onChange={handleChange}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} style={{textAlign: "center", marginTop: 10}}>
                    <Grid item xs={6}><Button variant="contained" size={"small"} color="primary"
                                              onClick={saveButtonClickHandler}>Uložit</Button></Grid>
                    <Grid item xs={6}><Button variant="contained" size={"small"}
                                              onClick={backToNoEditMode}>Zpět</Button></Grid>
                </Grid>
            </Grid>
        );
    }

    const mediaItem = (value: string | undefined, imgSource: string) => {
        const imageSize = 40;
        const img = <img height={imageSize} width={imageSize} src={imgSource}/>;

        return (
            <Grid item>
                {
                    value == undefined ?
                        img
                        :
                        <Link href={value} target={"_blank"} rel="external nofollow">
                            {img}
                        </Link>
                }
            </Grid>
        );
    }

    const noEditModeActive = () => {
        return (

            <Grid container style={{paddingTop: 20}} justify={"center"} spacing={2}>
                {mediaItem(media?.fb, "https://www.kdesehraje.cz/media/socmedia/FB-logo.png")}
                {mediaItem(media?.instagram, "https://www.kdesehraje.cz/media/socmedia/ig.webp")}
                {mediaItem(media?.youtube, "https://www.kdesehraje.cz/media/socmedia/yt.png")}
                {mediaItem(media?.website, "https://www.kdesehraje.cz/media/socmedia/web.png")}
                <Container>
                    <Grid item xs={12} style={{textAlign: "center", marginTop: 10, marginBottom: 10, borderTop: "1px solid #dce0e4"}}>
                        <Button size={"small"} color="secondary" onClick={changeEditMode}>
                            <Typography variant={"caption"}>Přidat odkazy soc. medií klubu</Typography>
                        </Button>
                    </Grid>
                </Container>
            </Grid>
        );
    }

    return (
        <Box style={{
            border: "1px solid #dce0e4",
            backgroundColor: "white"
        }}>
            {
                editMode ? editModeActive() : noEditModeActive()
            }
        </Box>
    );
}