import React, {useContext, useEffect} from "react";
import Navbar from "../navbar/Navbar";
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import userContextProvider from "../auth/userContextProvider";
import Container from "@material-ui/core/Container";
import {Link} from "react-router-dom";
import SimpleDisplayPlayers from "./data/simpleDisplayPlayers";
import SimpleDisplayLeagues from "./data/simpleDisplayLeagues";
import SimpleDisplayClubs from "./data/simpleDisplayClubs";
import SimpleDisplayTeams from "./data/simpleDisplayTeams";


export default function Profile() {
    const userLoggedContext = useContext(userContextProvider.context);

    useEffect(() => {

        }, []
    );

    const notLoggedUser = () => {
        return (
            <Box marginTop={10}>
                <Typography variant={"h4"} align={"center"}>
                    <Link to={"/prihlaseni"}>Přihlásit se...</Link>
                </Typography>
            </Box>
        );
    };


    const loggedUser = () => {

        return (
            <>
                <Box marginTop={5}>
                    <Typography variant={"h3"}
                                align={"center"}>
                        {userLoggedContext.userData.user.firstName + " " + userLoggedContext.userData.user.lastName}
                    </Typography>
                </Box>

                <Box marginTop={5}>
                    <SimpleDisplayClubs/>
                </Box>

                <Box marginTop={5}>
                    <SimpleDisplayTeams/>
                </Box>

                <Box marginTop={5}>
                    <SimpleDisplayLeagues/>
                </Box>
                <Box marginTop={5}>
                    <SimpleDisplayPlayers/>
                </Box>
            </>
        );
    };


    return (
        <Box>
            <Navbar/>
            <Container>
                {
                    userLoggedContext.isLogged() ?
                        loggedUser()
                        : notLoggedUser()
                }
            </Container>
        </Box>
    );

}
