import React from "react"
import {serverUrl} from "../../../../index";
import {LeagueTableRow, TableWrapper} from "../../../common/table/leagueTable";
import HeaderTitle from "../../../common/headerTitle";
import {Table, TableHead, TableRow, Typography} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import Box from "@material-ui/core/Box";
import TableBody from "@material-ui/core/TableBody";
import {Link} from "react-router-dom";
import {Skeleton} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 8
    }
}));

export default function SimpleTable({teamId, teamName}: { teamId: number, teamName: string }) {

    const classes = useStyles();

    const [table, setTable] = React.useState<TableWrapper | null>(null);

    React.useEffect(() => {
            fetch(serverUrl + '/team/' + teamId + '/table?teamForms=false')
                .then(res => res.json() as Promise<TableWrapper>)
                .then((result) => {
                    setTable(result)
                })
                .catch(console.log)
        }
        , [teamId]);

    const renderSkeleton = () => {

        const result: any = [];
        for (let i = 0; i < 10; i++) {
            result.push(
                <>
                    <Skeleton variant="rect" height={40} style={{marginTop: 10}}/>
                    <Skeleton variant="text"/>
                </>
            )
        }
        return result;
    }

    const renderTable = () => {

        if (table == null)
            return;

        return (
            <>
                <Link to={"/liga/" + table.league.nameUrl + "/" + table.league.id} style={{textDecoration: "none"}}>
                    <Typography variant="body2" color={"textSecondary"} gutterBottom
                                style={{
                                    paddingTop: 20,
                                    paddingLeft: 10,
                                    fontSize: 16
                                }}>
                        {table.league.name}</Typography></Link>
                <Table aria-label="simple table" style={{padding: 10, borderTop: '1px solid lightGrey'}}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.root} align={"center"}>#</TableCell>
                            <TableCell className={classes.root} >Tým</TableCell>
                            <TableCell className={classes.root} align={"center"}>Body</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            table.leagueTables.map((row: LeagueTableRow) => (
                                <TableRow key={row.position}
                                          style={row.team.id == teamId ? {backgroundColor: "#c3e6cb"} : {}}>
                                    <TableCell className={classes.root} align="center">{row.position}</TableCell>
                                    <TableCell className={classes.root} align="left"><Link
                                        style={{textDecoration: "none", color: "rgb(30, 65, 100)", fontSize: 13}}
                                        to={"/tym/" + row.team.nameUrl + "/" + row.team.id}>
                                        <img width={25} height={25} src={row.logoCrop}
                                             style={{
                                                 paddingRight: 10,
                                                 verticalAlign: "middle"
                                             }}/>
                                        {row.team.name}
                                    </Link></TableCell>
                                    <TableCell className={classes.root} align="center">{row.points}</TableCell>
                                </TableRow>
                            ))
                        }
                        {
                            <TableRow style={{borderBottom: "none"}}>
                                <TableCell className={classes.root} colSpan={3} align="center"><Link
                                    style={{textDecoration: "none", color: "black"}}
                                    to={"/tym/" + teamName + "/" + teamId + "/tabulka"}>{"Podrobnější tabulka >>"}</Link></TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </>
        )
    }

    return (
        <Box style={{backgroundColor: "white", border: '1px solid lightGrey'}}>
            <HeaderTitle title={"Tabulka"}/>
            {
                table == null ?
                    renderSkeleton()
                    :
                    renderTable()
            }
        </Box>
    );
}