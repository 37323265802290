import React from "react";
import {Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {serverUrl} from "../../../../index";
import HeaderTitle from "../../../common/headerTitle";
// @ts-ignore
import parse from 'html-react-parser'


export default function AboutClub({clubId}: { clubId: number }) {

    const [aboutText, setAboutText] = React.useState<string>("");

    React.useEffect(() => {
            fetch(serverUrl + '/club/' + clubId + '/info-text')
                .then(res => res.text())
                .then((result) => {
                    setAboutText(result)
                })
                .catch(console.log)
        }
        , [clubId]);

    return (
        <Box mt={2} style={{
            borderTop: "1px solid #dce0e4",
            borderRight: "1px solid #dce0e4",
            borderLeft: "1px solid #dce0e4",
            minHeight: 500,
            backgroundColor: "white"
        }}>
            <HeaderTitle title={"O klubu"}/>
            <Typography component={"div"} display={"block"} align={"justify"} style={{fontSize: 15}}>
                <Box m={2}> {parse(aboutText)}</Box>
            </Typography>
            <Typography variant={"body2"} style={{color: "grey", fontSize: 13}} component={"div"}>
                <Box m={2} pb={1}> Tento text byl vygenerován automaticky. Podrobnější informace o klubu nám můžete zaslat na <strong>info@kdesehraje.cz</strong> a my je zde doplníme.</Box>
            </Typography>
        </Box>
    );
}