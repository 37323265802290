import React from "react";
import {noLogo, serverUrl} from "../../index";
import {Box, Typography} from "@material-ui/core";
import HeaderTitle from "../common/headerTitle";
import {Match} from "../common/matches/matches";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from "@material-ui/core/Grid";
import {getMatchEventIcon, MatchEvent} from "../common/matches/matchEvents";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Accordion from "@material-ui/core/Accordion";
import {Link} from "react-router-dom";


export default function PlayerMatchesBox({playerId}: { playerId: number }) {

    const [matches, setMatches] = React.useState<Array<Match>>([]);
    const [expanded, setExpanded] = React.useState<number>(0);

    const handleChange = (matchId: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? matchId : 0);
    };

    React.useEffect(() => {
        fetch(serverUrl + '/player/' + playerId + '/matches')
            .then(response => {
                if (response.status < 300) {
                    return Promise.resolve(response.json()) as Promise<Array<Match>>;
                }
                return Promise.reject(response);
            })
            .then((result) => {
                setMatches(result)
            }, error => {
                console.log(error);
            })
            .catch(console.log)
    }, [playerId]);

    const renderPlayerEvents = (events: Array<MatchEvent> | null) => {
        let result: any = [];
        if (events === null || events === undefined || events.length === 0) {
            result.push(
                <>
                    <Grid item xs={12} style={{paddingBottom: 5}}>
                        <Typography display={"inline"} variant={"body2"}>Žádná událost hráče v tomto zápase</Typography>
                    </Grid>
                </>
            )
            return result;
        }

        for (let event of events) {
            result.push(
                <>
                    <Grid item xs={12} style={{paddingBottom: 5}}>
                        <Typography display={"inline"} variant={"body2"}>{event.matchMinute}'</Typography>
                        <Typography display={"inline"} style={{
                            paddingRight: 20,
                            paddingLeft: 20
                        }}>{getMatchEventIcon(event.eventType)}</Typography>
                        <Typography display={"inline"}
                                    variant={"body2"}>{event.player.firstName} {event.player.lastName}</Typography>
                    </Grid>
                </>
            );
        }
        return result;
    }

    const renderMatches = () => {
        let result = [];
        for (let match of matches) {
            result.push(
                <Accordion expanded={expanded === match.id} onChange={handleChange(match.id)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Grid container justify="center" alignItems="center">
                            <Grid item xs={2}>
                                <Typography
                                    variant={"body2"}>{match.formattedDate + " - " + match.formattedTime + " hod."}</Typography>
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>

                                <Link style={{textDecoration: "none", color: "#008bbb"}}
                                      to={"/tym/" + match.home.nameUrl + "/" + match.home.id}>
                                    <Typography align={"right"} display={"inline"}
                                                variant={"body1"}>{match.home.name}</Typography>
                                </Link>

                                <img
                                    src={match.home.logo !== undefined ? match.home.logo : noLogo}
                                    height={30} alt={"Logo domácího týmu"}
                                    width={30} style={{paddingLeft: 20, verticalAlign: "middle"}}/>
                            </Grid>
                            <Grid item xs={2}>
                                <Link style={{color: "black", textDecoration: "none"}} to={`/zapas/${match.nameUrl}/${match.id}`} target={"_blank"}>
                                    <Typography variant={"body1"}
                                                align={"center"}>{match.homeTeamFullTimeScore} : {match.awayTeamFullTimeScore}</Typography>
                                </Link>
                            </Grid>
                            <Grid item xs={4}>
                                <img
                                    src={match.away.logo !== undefined ? match.away.logo : noLogo}
                                    height={30} alt={"Logo týmu hostů"}
                                    width={30} style={{paddingRight: 20, verticalAlign: "middle"}}/>

                                <Link style={{textDecoration: "none", color: "#008bbb"}}
                                      to={"/tym/" + match.away.nameUrl + "/" + match.away.id}>
                                    <Typography align={"right"} display={"inline"}
                                                variant={"body1"}>{match.away.name}</Typography>
                                </Link>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails style={{backgroundColor: "#f1f2f5"}}>
                        <Grid container alignContent={"flex-start"} style={{paddingTop: 10, paddingLeft: 20}}>
                            {renderPlayerEvents(match.events)}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            );
        }
        return result;
    }

    return (
        <Box style={{marginTop: 20, backgroundColor: "white", border: "1px solid #dce0e4"}}>
            <HeaderTitle title={"Zápasy hráče"}/>

            {
                matches == null ?
                    <Typography>Hráč nebyl zapsán na soupisce žádného zápasu v poslední době</Typography>
                    :
                    renderMatches()
            }
        </Box>

    );
}
