import React from 'react'
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

export interface EntityHeaderData {
    logoUrl: string;
    name: string,
    type?: string,
    useLogo: boolean,
    content?: any
}

/**
 * Common for Club, Team, League. LogoUrl and club name must be passed to props.
 *
 * @param props
 * @returns {*}
 */
export default function Header({logoUrl, name, type, useLogo, content}: EntityHeaderData) {

    const imageSize = 200;

    const sizeGrid = useLogo && logoUrl != null ? 8 : 12;

    return (
        <Box>
            <Box style={{
                // backgroundImage: "url('https://cdn.pixabay.com/photo/2016/11/29/02/05/audience-1866738_960_720.jpg')",
                backgroundImage: "url('https://www.kdesehraje.cz/media/header-background.jpg')",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundColor: "linear-gradient(180deg,transparent 40%,rgba(0,0,0,.95))",
                minHeight: 700,
            }}
            />
            <Container
                style={{
                    borderTop: "1px solid darkGrey",
                    borderLeft: "1px solid darkGrey",
                    borderRight: "1px solid darkGrey",
                    marginTop: -306,
                    backgroundColor: "white",
                    borderRadius: "20px 20px 0px 0px"
                }}
            >
                <Grid container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      style={{paddingTop: 20, paddingBottom: 40, minHeight: 264}}
                >
                    {
                        useLogo && logoUrl != null &&
                            (
                                <Grid item sm={4} style={{textAlign: "center"}}>

                                    <img
                                        src={logoUrl}
                                        width={imageSize} height={imageSize} alt={"Logo"}/>
                                </Grid>
                            )
                    }
                    <Grid item sm={sizeGrid}>
                        <div>
                            <Typography align={"center"}
                                        variant={"h3"}
                                        component={"h1"}
                                        gutterBottom
                                        style={{color: "black", fontWeight: "bolder"}}
                            >{name}</Typography>
                            <Typography align={"center"}
                                        variant={"h5"}
                                        component={"h2"}
                                        gutterBottom
                                        style={{color: "grey", fontWeight: "bolder"}}
                            >{type}</Typography>
                        </div>
                    </Grid>
                </Grid>
                {
                    content
                }
            </Container>
        </Box>
    );
}