import React, {useContext, useEffect} from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {CircularProgress, CircularProgressProps, Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import axios from 'axios';
import {serverUrl} from "../../../index";
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import Grid from "@material-ui/core/Grid";
import {UserContext} from "../../auth/userContext";
import {Link} from "react-router-dom";
import ReactGA from "react-ga";


function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

export default function Upload({matchId}: { matchId: number }) {

    const [progress, setProgress] = React.useState<number>(0);
    const [uploading, setUploading] = React.useState<boolean>(false);
    const {userData, isLogged} = useContext(UserContext);


    useEffect(() => {
        ReactGA.initialize("UA-116060364-1");
    }, []);

    const onFileChangeHandler = (e: any) => {

        if (e.target.files.length > 0) {
            setUploading(true);
        }

        const formData = new FormData();
        for (let i = 0; i < e.target.files.length; i++) {
            formData.append('files', e.target.files[i])
        }

        axios.post(serverUrl + "/media/upload/match/" + matchId, formData,
            {
                headers: {
                    "X-Auth": userData.token
                },
                onUploadProgress: (progressEvent) => {
                    const {loaded, total} = progressEvent;
                    // @ts-ignore
                    let percent = Math.floor((loaded * 100) / total);
                    console.log(`${loaded}kb of ${total}kb | ${percent}%`)

                    setProgress(percent);
                }
            }
        ).then(() => {
            setUploading(false);
            setProgress(0);
            alert("Fotografie byly úspěšně nahrány na server.")
        }).catch(() => {
            setUploading(false);
            setProgress(0);
            alert("Problém při nahrávání fotografií na server.")
        })
    };

    return (
        <Box pt={2} pb={1}>
            <Grid container spacing={2}>
                <Grid item xs={12} style={{paddingLeft: 20}}>
                    <Typography component={"span"} variant={"body1"}>
                        Máte nějaké zajímavé fotografie ze zápasu?
                    </Typography>
                    <Typography style={{fontWeight: "bold"}} variant={"body1"}>
                        Nahrajte je k nám na server a ukažte je ostatním fanouškům.
                    </Typography>
                </Grid>
                {!isLogged() && <Grid item xs={12}>
                    <Typography style={{paddingLeft: 15}} variant={"body2"}>
                        Pro nahrátí fotografií je nutné se <Link to={"/prihlaseni"}>přihlásit</Link>.
                    </Typography>
                </Grid>}
                {isLogged() && !uploading && <Grid item xs={12} style={{textAlign: "center"}}>
                    <input
                        accept="image/*"
                        id="uploadedImage"
                        type="file"
                        multiple
                        hidden
                        onChange={onFileChangeHandler}
                        onClick={() => {ReactGA.event(
                            {
                                category: 'MatchGallery',
                                action: 'Click photogallery',
                                value: matchId
                            })}}
                    />
                    <Tooltip title="Vybrat fotografie a nahrát">
                        <label htmlFor="uploadedImage">
                            <IconButton
                                color="primary"
                                aria-label="upload image"
                                component="span"
                            >
                                <AddPhotoAlternateIcon fontSize="large"/>
                            </IconButton>
                        </label>
                    </Tooltip>
                </Grid>
                }
                {
                    <Grid item xs={12} style={{textAlign: "center"}}>
                        {uploading && <CircularProgressWithLabel value={progress}/>}
                    </Grid>
                }
                <Grid item xs={12} style={{marginLeft: 15}}>
                    <Typography variant={"caption"}>Fotky může nahrávat pouze registrovaný uživatel a každá fotka, nahraná na server, musí být schválená
                        administrátorem serveru nebo administrátorem klubu/týmu, aby mohla být zobrazena.</Typography>
                    {/*<Typography variant={"caption"} display={"block"} style={{marginTop: 10}}>*/}
                    {/*    Pokud se chcete stát administrátorem klubu/týmu, můžete o to zažádat zde.*/}
                    {/*</Typography>*/}
                </Grid>
            </Grid>
        </Box>
    );
}