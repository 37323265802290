import React, {useContext} from "react";
import HeaderTitle from "../headerTitle";
import {Box, createStyles, Theme, Typography} from "@material-ui/core";
import {PlayerDetail} from "../../player/player";
import {serverUrl} from "../../../index";
import {makeStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import {Alert} from "@material-ui/lab";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {UserContext} from "../../auth/userContext";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: 8
        },
        details: {
            display: 'flex',
            flexDirection: 'column',
        },
        content: {
            flex: '1 0 auto',
        },
        cover: {
            width: 151,
        },
        controls: {
            display: 'flex',
            alignItems: 'center',
            paddingLeft: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        playIcon: {
            height: 38,
            width: 38,
        },
        playerName: {
            color: "#008bbb",
            textDecoration: "none"
        }
    }),
);

export default function Players({teamId}: { teamId: number }) {
    const classes = useStyles();
    const [players, setPlayers] = React.useState<Array<PlayerDetail>>([]);
    const [editMode, setEditMode] = React.useState<boolean>(false);
    const {userData} = useContext(UserContext);

    React.useEffect(() => {

        fetch(serverUrl + '/team/' + teamId + '/players')
            .then(res => res.json())
            .then((result) => {
                setPlayers(result)
            })
            .catch(console.log)
    }, [teamId]);


    const handleChangingMode = (mode: boolean) => {
        setEditMode(mode);
    }


    const handleChange = (event: React.ChangeEvent<{ value: unknown }>, playerId: number, playerIndex: number, position: boolean) => {

        let insertedValue: string = event.target.value as string
        let payload: any;
        if (position) {
            payload = {"position": insertedValue}
        } else if (insertedValue === '') {
            let playersCopy: Array<PlayerDetail> = [...players]
            let player = players[playerIndex]
            playersCopy[playerIndex] = {...player, number: 0};
            setPlayers(playersCopy);
            return;
        } else if (isNaN(+insertedValue)) {
            return;
        } else {
            payload = {"number": insertedValue}
        }


        fetch(serverUrl + "/player/" + playerId + "/update",
            {
                method: "POST",
                headers: {
                    "X-Auth": userData.token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload, null, 2)
            })
            .then(res => res.json() as Promise<PlayerDetail>)
            .then((player) => {
                let playersCopy: Array<PlayerDetail> = [...players]
                playersCopy[playerIndex] = player;
                setPlayers(playersCopy);
            })
            .catch(console.log)
    }

    const editModeActive = (player: PlayerDetail, playerIndex: number) => {
        return (
            <TableRow>
                <TableCell className={classes.root} align={"center"}>
                    <TextField style={({textAlign: "center"})} label="Číslo dresu"
                               defaultValue={player.number === 0 ? "" : player.number}
                               onChange={(e) => handleChange(e, player.id, playerIndex, false)}/>
                </TableCell>
                <TableCell className={classes.root}>
                    <Typography className={classes.playerName} component="a" variant={"body2"}
                                href={"/hrac/" + player.nameUrl + "/" + player.id} target={"_blank"}>
                        {player.firstName + ' ' + player.lastName}
                    </Typography>
                </TableCell>
                <TableCell className={classes.root}>
                    <FormControl>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={player.position}
                            onChange={(e) => handleChange(e, player.id, playerIndex, true)}
                        >
                            <MenuItem value={"UNKNOWN"}>Neurčeno</MenuItem>
                            <MenuItem value={"GOALKEEPER"}>Brankář</MenuItem>
                            <MenuItem value={"DEFENDER"}>Obránce</MenuItem>
                            <MenuItem value={"MIDFIELDER"}>Záložník</MenuItem>
                            <MenuItem value={"OFFENDER"}>Útočník</MenuItem>
                        </Select>
                    </FormControl>
                </TableCell>
            </TableRow>
        );
    }

    const noEditMode = (player: PlayerDetail) => {
        return (
            <TableRow>
                <TableCell className={classes.root}
                           align={"center"}>{player.number == 0 ? "-" : player.number}</TableCell>
                <TableCell className={classes.root}>
                    <Typography className={classes.playerName} component="a" variant={"body2"}
                                href={"/hrac/" + player.nameUrl + "/" + player.id} target={"_blank"}>
                        {player.firstName + ' ' + player.lastName}
                    </Typography>
                </TableCell>
                <TableCell className={classes.root}> {player.positionRole}</TableCell>
            </TableRow>
        )
    }


    const renderPlayers = () => {

        if (players.length === 0) {
            return <TableRow>
                <TableCell colSpan={3}>
                    <Typography>Hráči nejsou k dispozici.</Typography>
                </TableCell>
            </TableRow>
        } else {
            let allPlayers = [];
            let playerIndex: number = 0;
            for (let player of players) {
                const renderedPlayer = editMode ? editModeActive(player, playerIndex) : noEditMode(player);
                allPlayers.push(renderedPlayer);
                playerIndex++;
            }

            return allPlayers;
        }
    };


    return (<>
            <Alert icon={false} elevation={6} variant={"filled"} severity="info"
                   style={{marginBottom: 10}}>
                Není pozice nebo číslo hráče správné? Pomozte nám je upravit
                kliknutím <Button variant="contained" color="primary" style={{marginLeft: 10}}
                                  onClick={() => handleChangingMode(true)}>ZDE</Button>
            </Alert>
            <Box style={{
                borderTop: "1px solid #dce0e4",
                borderRight: "1px solid #dce0e4",
                borderLeft: "1px solid #dce0e4",
                minHeight: 500,
                backgroundColor: "white"
            }}>
                <HeaderTitle title={"Hráči"}/>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width={80} align={"center"}>Číslo dresu</TableCell>
                            <TableCell>Jméno hráče</TableCell>
                            <TableCell>Pozice</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            renderPlayers()
                        }
                        <TableRow>
                            <TableCell colSpan={3}>
                                {
                                    editMode ?
                                        (<Box style={{textAlign: "center"}}><Button variant={"outlined"}
                                                                                    onClick={() => {
                                                                                        handleChangingMode(false)
                                                                                    }}>Zpět</Button></Box>)
                                        :
                                        (<Typography variant={"body2"}>Seznam hráčů, kteří za posledních 6 měsíců
                                            odehráli
                                            alespoň jeden zápas.</Typography>)
                                }

                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Box>
        </>
    );
}
