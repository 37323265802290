import React from "react";
import {Match} from "../common/matches/matches";
import {Helmet} from "react-helmet";

export default function MatchHelmet({selectedMatch}: { selectedMatch: Match }) {
    return (
        <Helmet>
            <meta charSet="utf-8"/>
            <title>Zápas {selectedMatch.home.name} vs. {selectedMatch.away.name} - kdesehraje.cz</title>
            <meta name="description"
                  content={"kdesehraje, kde se hraje, fotbal, fotbalové zápasy, fotbalové statistiky, fotbalové výsledky, " +
                  selectedMatch.home.name + ", " + selectedMatch.away.name}/>
        </Helmet>
    );
}