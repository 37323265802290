import React from 'react'
import {useOutletContext} from "react-router-dom";
import {LeagueSubPageContext} from "../../league";
import {Menu, MenuContent} from "../../../common/content/content";
import LeagueTable from "../../../common/table/leagueTable";
import DognetAd, {AdType} from "../../../common/ads/dognetAd";
import Ad from "../../../common/ads/ad";

export default function LeagueTableTab() {
    const context = useOutletContext<LeagueSubPageContext>();

    return (
        <>
            <Menu menuTabs={context.menuTabs} selectedTab={2}/>
            <MenuContent tabId={2} content={
                <>
                    <LeagueTable leagueId={context.leagueId}/>
                    <Ad ad={<ins className="adsbygoogle"
                                 style={{display:"block"}}
                                 data-ad-client="ca-pub-7325589669239459"
                                 data-ad-slot="2653121138"
                                 data-ad-format="auto"
                                 data-full-width-responsive="true"></ins>}
                        height={100} marginY={3}
                    />
                    {/*<DognetAd marginY={3} webAdType={AdType.HORIZONTAL} mobileAdType={AdType.CUBE}/>*/}
                </>
            }/>
        </>
    );
}