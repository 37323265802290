import React, {useEffect} from "react";
import {serverUrl} from "../../index";
import {Box, Container, Typography} from "@material-ui/core";
import Navbar from "../navbar/Navbar";
import {Link, useParams} from "react-router-dom";
import { useSearchParams } from "react-router-dom";


export default function Activation() {

    const [searchParams, setSearchParams] = useSearchParams();

    const [result, setResult] = React.useState<number>(0);
    const [error, setError] = React.useState<string | undefined>(undefined);

    useEffect(() => {

            const userId = searchParams.get("userId")
            const token = searchParams.get("token")

            if (userId === undefined || token === undefined) {
                return;
            }

            fetch(serverUrl + '/user/activate?userId=' + userId + '&token=' + token)
                .then(response => {
                    if (response.ok) {
                        setResult(1)
                    } else {
                        throw new Error("Problem with activation.");
                    }
                })
                .catch(reason => {
                    setError("Nespecifikovaný problém při aktivaci účtu, prosím opakujte pozdeji.");
                });

        }, []
    )

    return (
        <Box>
            <Navbar/>
            <Container>
                <Box pt={10}>
                    {
                        result === 1 &&
                            (
                                <Typography>
                                    Účet byl úspěšně aktivován, nyní se můžete
                                    <Link to={"/prihlaseni"}> přihlásit</Link>.
                                </Typography>
                            )
                    }
                    {
                        error !== undefined &&
                        (
                            <Typography>{error}</Typography>
                        )
                    }
                </Box>
            </Container>
        </Box>
    )

}

