import React from "react";
import {Box, Typography} from "@material-ui/core";


export default function NoMatch() {

    return (
        <Box>
            <Typography>No Match</Typography>
        </Box>
    );
}