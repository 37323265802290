import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Navbar from "../navbar/Navbar";
import {Field, Form, Formik} from "formik";
import {LinearProgress} from "@material-ui/core";
import {TextField} from "formik-material-ui";
import {Link} from "react-router-dom";
import {serverUrl} from "../../index";
import {Alert} from "@material-ui/lab";
import Divider from "@material-ui/core/Divider";
import Footer from "../common/footer/footer";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        minHeight: 550,
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(0, 0, 2),
    },
}));

export default function ResetPassword() {
    const classes = useStyles();
    const [errorMsg, setErrorMsg] = useState<boolean>(false);
    const [infoMsg, setInfoMsg] = useState<boolean>(false);

    const resetPwdForm = () => {
        return (
            <Formik
                initialValues={{
                    email: ''
                }}
                validate={values => {
                    // @ts-ignore
                    const errors: Partial<Values> = {};
                    if (!values.email) {
                        errors.email = 'Email je vyžadován. ';
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
                    ) {
                        errors.email = 'Emailová adresa není validní.';
                    }
                    return errors;
                }}
                onSubmit={(values, {setSubmitting, resetForm}) => {
                    setTimeout(() => {
                        setSubmitting(false);

                        fetch(serverUrl + "/user/reset-password", {
                            method: "post",
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },

                            //make sure to serialize your JSON body
                            body: JSON.stringify(values, null, 2)
                        })
                            .then((response) => {
                                if (!response.ok) {
                                    throw new Error("Problem s resetováním hesla.");
                                }
                                setInfoMsg(true);
                            })
                            .catch(reason => {
                                setErrorMsg(true);
                            });

                        resetForm({})
                    }, 500);
                }}
            >
                {({submitForm, isSubmitting}) => (
                    // @ts-ignore
                    <Form className={classes.form}>
                        <Field
                            component={TextField}
                            name="email"
                            type="email"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Emailová adresa"
                            autoComplete="email"
                            autoFocus
                        />
                        <br/>
                        {isSubmitting && <LinearProgress/>}
                        <br/>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                            onClick={submitForm}
                            fullWidth
                            className={classes.submit}
                        >
                            Obnovit heslo
                        </Button>
                    </Form>
                )}
            </Formik>
        );
    };

    return (
        <>
            <Navbar/>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Obnovit heslo
                    </Typography>
                    {
                        errorMsg &&
                        <Alert severity="error">Problém s resetováním hesla. Prosím opakujte akci za chvíli nebo nás
                            kontaktujte.</Alert>
                    }
                    {
                        infoMsg &&
                        <Alert severity="success">Na zadaný email byly odeslány pokyny k nastavení nového hesla.</Alert>
                    }
                    {
                        resetPwdForm()
                    }
                    <Grid container>
                        <Grid item xs>
                            <Link to="/prihlaseni" style={{textDecoration: "none", fontSize: 16}}>
                                Přihlásit
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link to="/registrace" style={{textDecoration: "none", fontSize: 16}}>
                                {"Nemáte účet? Zaregistrujte se"}
                            </Link>
                        </Grid>
                    </Grid>
                </div>
            </Container>
            <Divider/>
            <Footer/>
        </>
    );
}