import React from "react";
import {Link, useParams} from "react-router-dom";
import {League} from "../league/league";
import {LeagueType} from "../team/team";
import {Box, createStyles, Paper, Theme} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {Helmet} from "react-helmet";
import Navbar from "../navbar/Navbar";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {serverUrl} from "../../index";
import Divider from "@material-ui/core/Divider";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Footer from "../common/footer/footer";

export interface Region {
    id: number,
    name: string,
    nameUrl?: string
}

interface Competition {
    type: LeagueType,
    lvl1?: Array<League>,
    lvl2?: Array<League>,
    lvl3?: Array<League>,
    lvl4?: Array<League>,
    lvl5?: Array<League>,
    lvl6?: Array<League>,
    lvl7?: Array<League>,
}

interface RegionDataLeagues {
    region: Region,
    parentRegion: Region,
    dependentRegions?: Array<Region>
    competitions: Array<Competition>
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            paddingBottom: 20
        },
        paperRegion: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: "white",
            backgroundColor: "#37a935"
        },
        paperLeagueParent: {
            marginBottom: 10
        },
        paperLeague: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: "white",
            backgroundColor: "#1E90FF"
        },
        formControl: {
            minWidth: 120,
            marginTop: 20,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }),
);


export default function Competitions() {

    const classes = useStyles();

    const [data, setData] = React.useState<RegionDataLeagues | null>(null);
    const [selectedCategory, setSelectedCategory] = React.useState<string>("");

    // @ts-ignore
    let {competitionName, competitionId}: { competitionName: string, competitionId: number } = useParams();

    React.useEffect(() => {
        fetch(serverUrl + '/competitions/' + competitionId)
            .then(res => res.json() as Promise<RegionDataLeagues>)
            .then((result) => {
                setData(result);
                setSelectedCategory(result.competitions[0].type.name)
            })
            .catch(console.log)
    }, [competitionId]);

    const renderLeagues = () => {
        if (data == null) return;

        let competition: Competition | null = null;
        for (let competitionTmp of data.competitions) {

            if (competitionTmp.type.name === selectedCategory) {
                competition = competitionTmp;
                break;
            }
        }

        if (competition == null) {
            return [];
        }

        const result: any = [];
        let lvl1leagues = renderLvlLeagues(1, competition.lvl1);
        let lvl2leagues = renderLvlLeagues(2, competition.lvl2);
        let lvl3leagues = renderLvlLeagues(3, competition.lvl3);
        let lvl4leagues = renderLvlLeagues(4, competition.lvl4);
        let lvl5leagues = renderLvlLeagues(5, competition.lvl5);
        let lvl6leagues = renderLvlLeagues(6, competition.lvl6);
        let lvl7leagues = renderLvlLeagues(7, competition.lvl7);

        result.push(<Box style={{marginTop: 10, marginBottom: 20}}>
            <Grid container spacing={1} className={classes.paperLeagueParent}>{lvl1leagues}</Grid>
            <Grid container spacing={1} className={classes.paperLeagueParent}>{lvl2leagues}</Grid>
            <Grid container spacing={1} className={classes.paperLeagueParent}>{lvl3leagues}</Grid>
            <Grid container spacing={1} className={classes.paperLeagueParent}>{lvl4leagues}</Grid>
            <Grid container spacing={1} className={classes.paperLeagueParent}>{lvl5leagues}</Grid>
            <Grid container spacing={1} className={classes.paperLeagueParent}>{lvl6leagues}</Grid>
            <Grid container spacing={1} className={classes.paperLeagueParent}>{lvl7leagues}</Grid>
        </Box>);

        return result;
    }

    const renderLvlLeagues = (lvl: number, leagues?: Array<League>) => {

        const result: any = [];
        if (leagues == null) {
            return result;
        }

        for (let league of leagues) {
            result.push(
                <Grid item>
                    <Link to={"/liga/" + league.nameUrl + "/" + league.id} style={{textDecoration: 'none'}}>
                        <Paper
                            className={classes.paperLeague}>{league.name}</Paper>
                    </Link>
                </Grid>
            );
        }

        return result;
    }

    const renderRegions = (regions?: Array<Region>) => {

        const result: any = [];

        if (regions == null) {
            return result;
        }

        for (let region of regions) {
            result.push(
                <Grid item sm={3}><Link style={{textDecoration: "none"}}
                                        to={"/souteze/" + region?.nameUrl + "/" + region.id}><Paper
                    className={classes.paperRegion}>{region.name}</Paper></Link></Grid>
            )
        }


        return result;
    }

    const getLeagueCategories = () => {

        const result: Array<any> = [];
        if (data == null || data.competitions.length == 0) {
            return result;
        }

        for (let leagueCategory of data.competitions) {

            result.push(<option value={leagueCategory.type.name}>{leagueCategory.type.name}</option>);
        }

        return result;
    }

    const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {

        console.log(event.target.value);
        setSelectedCategory(
            // @ts-ignore
            event.target.value,
        );
    };


    return (
        <Box>
            <Navbar/>

            {
                data !== null &&
                <>
                    <Helmet>
                        <meta charSet="utf-8"/>
                        <title>{data.region.name}</title>
                        <meta name="description"
                              content={"kdesehraje, kde se hraje, fotbal, fotbalové zápasy, fotbalové statistiky, fotbalové výsledky, " + data.region.name}/>
                    </Helmet>

                    <Container style={{marginTop: 15, color: "grey"}}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link color="textSecondary" to={"/"} style={{textDecoration: "none", color: "grey"}}>
                                Domů
                            </Link>
                            {

                                data.parentRegion != null &&
                                (<Link color="textSecondary" style={{textDecoration: "none", color: "grey"}}
                                       to={"/souteze/" + data.parentRegion.nameUrl + "/" + data.parentRegion.id}>
                                    {data.parentRegion.name}
                                </Link>)
                            }
                            {
                                <Link color="textSecondary" style={{textDecoration: "none", color: "black"}}
                                      to={"#"}>
                                    {data.region.name}
                                </Link>
                            }
                        </Breadcrumbs>
                    </Container>

                    <Container style={{marginTop: 50}}>
                        <Typography align={"center"} variant={"h3"} component={"h1"}>{data.region.name}</Typography>
                    </Container>
                    {
                        data.dependentRegions?.length !== 0 &&

                        (<Container style={{paddingTop: 20}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}><Typography variant={"h6"} component={"h2"}
                                                                   color={"textSecondary"}>Regiony</Typography></Grid>
                                    {
                                        renderRegions(data.dependentRegions)
                                    }
                                </Grid>
                            </Container>
                        )
                    }
                    <Container style={{marginTop: 30, minHeight: 500}}>
                        <Divider style={{marginBottom: 10}}/>
                        <FormControl className={classes.formControl} variant="outlined">
                            <InputLabel id="league-category">Kategorie</InputLabel>
                            <Select
                                native
                                value={selectedCategory}
                                label={"kategorie"}
                                onChange={handleChange}
                                inputProps={{
                                    name: 'kategorie',
                                    id: 'league-category',
                                }}
                            >
                                {getLeagueCategories()}
                            </Select>
                        </FormControl>
                        <Typography variant={"h6"} component={"h2"} color={"textSecondary"}
                                    style={{marginTop: 20}}>Ligy</Typography>
                        {
                            renderLeagues()
                        }

                    </Container>
                </>
            }
            <Divider/>
            <Footer/>
        </Box>
    );
}