import React, {useEffect} from "react"
import {Box, Container, Grid, Hidden} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Navbar from "../navbar/Navbar";
import Divider from "@material-ui/core/Divider";
import Footer from "../common/footer/footer";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import {noLogo, serverUrl} from "../../index";
import {parseString} from 'xml2js'
import ReactGA from "react-ga";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import {
    CompassControl,
    KeyboardControl,
    Map,
    Marker,
    MarkerLayer,
    MouseControl,
    SyncControl,
    ZoomControl
} from "react-mapycz";
import _ from "lodash"
import {isMobile} from "react-device-detect";

function FormControlLabelWrapperDay(props) {
    return (
        <FormControlLabel
            control={<Checkbox value={props.day} onChange={props.doChange} checked={props.checked}
                               disabled={props.disabled}/>}
            label={
                <>
                    <Typography display={"inline"} variant={"body2"}>{props.dayText}</Typography>
                    <Typography display={"inline"} variant={"body2"}
                                color={"textSecondary"}>{" (" + props.date + ")"}</Typography>
                </>
            }
        />
    );
}

function FormControlLabelWrapperCategory(props) {
    return (
        <FormControlLabel
            control={<Checkbox value={props.category} onChange={props.doChange} checked={props.checked}
                               disabled={props.disabled}/>}
            label={
                <Typography display={"inline"} variant={"body2"}
                            style={{color: "#" + props.color}}>{props.categoryText}</Typography>
            }
        />
    );
}

export default function SeznamMap() {

    const [disabled, setDisabled] = React.useState(true);
    const [days, setDays] = React.useState({});
    const [categories, setCategories] = React.useState({});

    const [selectedDays, setSelectedDays] = React.useState(["SATURDAY", "SUNDAY"]);
    const [selectedCategories, setSelectedCategories] = React.useState([]);

    const [files, setFiles] = React.useState({});
    const [markers, setMarkers] = React.useState({});
    const [selectedMarkers, setSelectedMarkers] = React.useState([]);
    const [forceRender, setForceRender] = React.useState(false);
    const asyncCounter = React.useRef(0);

    const [currentLocation, setCurrentLocation] = React.useState({pos: {lat: 49.751101, lng: 15.324296}, zoom: 8});

    useEffect(() => {

        ReactGA.initialize("UA-116060364-1");
        ReactGA.pageview(window.location.pathname);

        setDisabled(true);
        getCurrentPosition();

        fetch(serverUrl + "/map/settings")
            .then(res => res.json())
            .then(result => {
                setDays(result["days"]);
                setCategories(result["categories"]);
                let dayCategoryFileUrl = result["dayCategoryFileUrl"];
                setFiles(dayCategoryFileUrl);
                setSelectedCategories(["MUZI"])
            })
            .catch(console.log);
        setDisabled(false);
    }, []);

    function getCurrentPosition() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {

                const pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                setCurrentLocation({pos: pos, zoom: 13})
            }, function (error) {
                console.log(error);
            });
        }
    }

    const handleDayChange = (event) => {
        setDisabled(true);
        const dayChanged = event.target.value;
        let result;
        if (event.target.checked) {
            result = [...selectedDays, dayChanged];
        } else {
            result = selectedDays.filter(day => day !== dayChanged);
        }

        setSelectedDays(result);
        setDisabled(false);
    };


    const handleCategoryChange = (event) => {
        setDisabled(true);
        const categoryChanged = event.target.value;
        let result;

        if (event.target.checked) {
            result = [...selectedCategories, categoryChanged];
        } else {
            result = selectedCategories.filter(category => category !== categoryChanged);
        }

        setSelectedCategories(result);
        setDisabled(false);
    };

    const convertUrlToMarkers = (day, category) => {

        fetch(files[day][category])
            .then(response => response.text())
            .then(xmlStr => {
                const result = [];
                parseString(xmlStr, {explicitArray: false}, function (err, resultData) {
                    let matchData = resultData.matches.match;
                    if (!Array.isArray(resultData.matches.match)) {
                        matchData = [matchData];
                    }

                    matchData.map(match => {
                        result.push(
                            <Marker
                                coords={{lat: match.coords.lat, lng: match.coords.lng}}
                                options={{url: "https://www.kdesehraje.cz/media" + match.icon_url}}
                                card={{
                                    // header: ({lat, lng}) => <strong>Card header {lat} {lng}</strong>,
                                    body: ({lat, lng}) => <ContentWindow match={match}/>,
                                    // footer: "Card footer",
                                    options: {
                                        width: isMobile ? 350 : 550,
                                        height: isMobile? 500 : 320,
                                    }
                                }}
                            />
                        )
                    });
                });

                const resultObj = {[day]: {[category]: result}}

                setMarkers(prevState => _.merge(prevState, resultObj))

                if (result.length !== 0) {
                    setSelectedMarkers(prevState => {
                        return [...prevState, ...result];
                    });
                }

                asyncCounter.current = asyncCounter.current - 1;

                if (asyncCounter.current < 1) {
                    setForceRender(prevState => !prevState);
                }
            }).catch(() => {
            asyncCounter.current = asyncCounter.current - 1
            if (asyncCounter.current < 1) {
                setForceRender(prevState => !prevState);
            }
        });
    };

    useEffect(() => {

        for (let day of selectedDays) {
            if (files.hasOwnProperty(day)) {
                for (let category of selectedCategories) {
                    if (files[day].hasOwnProperty(category)) {
                        if (markers.hasOwnProperty(day) && markers[day].hasOwnProperty(category)) {
                            setSelectedMarkers(prevState => [...prevState, ...markers[day][category]]);
                        } else {
                            asyncCounter.current = asyncCounter.current + 1;
                            convertUrlToMarkers(day, category);
                        }
                    }
                }
            }
        }

    }, [selectedCategories, selectedDays, forceRender]);

    const markAllDays = (event) => {
        setDisabled(true);
        const result = [];
        if (event.target.checked) {
            for (let day of Object.keys(days)) {
                result.push(day);
            }
        }

        setSelectedDays(result);
        setDisabled(false);
    };

    const markAllCategories = (event) => {
        setDisabled(true);
        const result = [];
        if (event.target.checked) {
            for (let category of Object.keys(categories)) {
                result.push(category);
            }
        }

        setSelectedCategories(result);
        setDisabled(false);
    };


    const renderCategories = () => {

        let result = [];
        for (let key in categories) {
            if (categories.hasOwnProperty(key)) {
                result.push(<FormControlLabelWrapperCategory key={key} category={key}
                                                             categoryText={categories[key].name}
                                                             disabled={disabled}
                                                             color={categories[key].color}
                                                             checked={selectedCategories.includes(key)}
                                                             doChange={handleCategoryChange}/>)
            }
        }
        return result;
    };

    const style = {
        width: '100%',
        height: '100%'
    };

    const renderMenu = (mobile) => {

        const style = {
            backgroundColor: "#f2f5f6"
        };

        if (!mobile) {
            style["height"] = "100%";
            style["overflowY"] = "auto";
        } else {
            style["paddingBottom"] = "20px";
        }

        return <Grid item md={2} xs={12} style={style}>
            <Typography align={"center"} variant={"h5"}
                        style={{paddingTop: 30, color: "#1e4164", fontWeight: "bold"}}>
                Nastavení Mapy
            </Typography>
            <ExpansionPanel style={{marginTop: 20, marginLeft: 10, marginRight: 10}}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                    id="additional-actions1-header"
                >
                    <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={markAllDays}
                        onFocus={event => event.stopPropagation()}
                        control={<Checkbox/>}
                        label="Hrací dny"
                    />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <FormControl component="fieldset">
                        <FormGroup>
                            <FormControlLabelWrapperDay key={"MONDAY"} day={"MONDAY"} dayText={"Pondělí"}
                                                        disabled={disabled}
                                                        date={days["MONDAY"]} doChange={handleDayChange}
                                                        checked={selectedDays.includes("MONDAY")}/>
                            <FormControlLabelWrapperDay key={"TUESDAY"} day={"TUESDAY"} dayText={"Úterý"}
                                                        disabled={disabled}
                                                        date={days["TUESDAY"]} doChange={handleDayChange}
                                                        checked={selectedDays.includes("TUESDAY")}/>
                            <FormControlLabelWrapperDay key={"WEDNESDAY"} day={"WEDNESDAY"}
                                                        dayText={"Středa"}
                                                        disabled={disabled}
                                                        date={days["WEDNESDAY"]} doChange={handleDayChange}
                                                        checked={selectedDays.includes("WEDNESDAY")}/>
                            <FormControlLabelWrapperDay key={"THURSDAY"} day={"THURSDAY"}
                                                        dayText={"Čtvrtek"}
                                                        disabled={disabled}
                                                        date={days["THURSDAY"]} doChange={handleDayChange}
                                                        checked={selectedDays.includes("THURSDAY")}/>
                            <FormControlLabelWrapperDay key={"FRIDAY"} day={"FRIDAY"} dayText={"Pátek"}
                                                        disabled={disabled}
                                                        date={days["FRIDAY"]}
                                                        doChange={handleDayChange}
                                                        checked={selectedDays.includes("FRIDAY")}/>
                            <FormControlLabelWrapperDay key={"SATURDAY"} day={"SATURDAY"} dayText={"Sobota"}
                                                        disabled={disabled}
                                                        date={days["SATURDAY"]} doChange={handleDayChange}
                                                        checked={selectedDays.includes("SATURDAY")}/>
                            <FormControlLabelWrapperDay key={"SUNDAY"} day={"SUNDAY"} dayText={"Neděle"}
                                                        disabled={disabled}
                                                        date={days["SUNDAY"]} doChange={handleDayChange}
                                                        checked={selectedDays.includes("SUNDAY")}/>
                        </FormGroup>
                    </FormControl>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel style={{marginTop: 10, marginLeft: 10, marginRight: 10}}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                    id="additional-actions1-header"
                >
                    <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={markAllCategories}
                        onFocus={event => event.stopPropagation()}
                        control={<Checkbox/>}
                        label="Kategorie"
                    />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <FormControl component="fieldset">
                        <FormGroup>
                            {
                                renderCategories()
                            }
                        </FormGroup>
                    </FormControl>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </Grid>
    };

    return (
        <Box>
            <Navbar/>
            <Container style={{marginTop: 30}}>
                <Typography gutterBottom align={"center"} variant={"h3"} component={"h1"}
                            style={{color: "#1e4164", paddingBottom: 20}}>
                    Mapa s aktuálními zápasy
                </Typography>
                <Typography paragraph={true}>
                    Jednotlivé fotbalové zápasy jsou reprezentovány na mapě jako barevné piny. Každá barva
                    představuje různou kategorii.
                </Typography>
            </Container>
            <Grid container>
                <Hidden mdUp>
                    {
                        renderMenu(true)
                    }
                </Hidden>
            </Grid>


            <Grid container style={{borderBottom: "1px solid grey", borderTop: "1px solid grey", height: 780}}>
                <Grid item md={10} xs={12} style={{position: 'relative'}}>
                    <Map
                        height={isMobile ? "60%" : "100%"} width={"100%"}
                        style={style}
                        center={currentLocation.pos}
                        zoom={currentLocation.zoom}

                    >
                        <KeyboardControl/>
                        <ZoomControl left={10}/>
                        <MouseControl zoom={true} pan={true} wheel={true}/>
                        <CompassControl left={10} top={50}/>
                        <Markers markers={selectedMarkers}></Markers>
                    </Map>

                </Grid>
                <Hidden smDown>
                    {renderMenu(false)}
                </Hidden>
            </Grid>
            <Divider/>
            <Footer/>
        </Box>
    );
}

function Markers({markers}) {
    return (
        <MarkerLayer id={"mojeid"} >
            {markers}
        </MarkerLayer>
    )
}

function ContentWindow({match}) {
    console.log(match.home_team.logo_url)

    return (
        <Grid container>
            <Grid container>
                <Grid item container sm={4} direction="column" alignItems="center" justify={"center"}>
                    <Grid item>
                        <img alt={"Logo domácího týmu."} style={{width: "100px", height: "100px"}}
                             src={match.home_team.logo_url ? match.home_team.logo_url :  noLogo}
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant={"h6"} component={"a"} style={{
                            color: "#008B8B",
                            textDecoration: "none",
                            fontWeight: "bold"
                        }}
                                    href={match.home_team.url} target="_blank" align={"center"}>
                            {match.home_team.name}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container sm={4} direction={"column"} alignContent={"center"}
                      justify={"center"}>
                    <Grid item><Typography variant="h6" align={"center"}>{match.date}</Typography></Grid>
                    <Grid item><Typography variant="h5" align={"center"}>{match.time}</Typography></Grid>
                </Grid>
                <Grid item container sm={4} direction="column" alignItems="center" justify={"center"}>
                    <Grid item>
                        <img alt={"Logo hostujícího týmu."} style={{width: "100px", height: "100px"}}
                             src={match.away_team.logo_url ? match.away_team.logo_url :  noLogo}
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant={"h6"} component={"a"} style={{
                            color: "#008B8B",
                            textDecoration: "none",
                            fontWeight: "bold"
                        }}
                                    href={match.away_team.url} target="_blank" align={"center"}>
                            {match.away_team.name}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid style={{paddingTop: 10, paddingBottom: 10}} item xs={12}><Divider/></Grid>
            <Grid xs={12} item><Typography variant={"body1"}><b>Hřiště:</b> {match.field}</Typography></Grid>
            <Grid xs={12} item>
                <Typography>
                    <b>Liga: </b>
                    <Typography target="_blank" href={match.league.url} component={"a"}
                                style={{color: "#008B8B", textDecoration: "none"}}>
                        {match.league.name}
                    </Typography>
                    &nbsp;[&nbsp;{match.category}&nbsp;]
                </Typography></
                Grid>
        </Grid>
    );
}
