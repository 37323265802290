import React from "react";
import {Backdrop, Box, CircularProgress, Container, createStyles, Theme, Typography} from "@material-ui/core";
import Navbar from "../navbar/Navbar";
import Divider from "@material-ui/core/Divider";
import {useParams} from "react-router-dom";
import {noLogo, serverUrl} from "../../index";
import {ClubDetail} from "../club/club";
import Footer from "../common/footer/footer";
import ReactGA from "react-ga";
import HeaderHelmet from "../header/HeaderHelmet";
import PlayerTransferBox from "./playerTransferBox";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PlayerMatchesBox from "./playerMatchesBox";
import Follow from "../common/follow/follow";
import DognetAd, {AdType} from "../common/ads/dognetAd";
import Ad from "../common/ads/ad";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        large: {
            width: theme.spacing(7),
            height: theme.spacing(7),
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }),
);

export interface Player {
    id: number,
    firstName: string,
    lastName: string,
    nameUrl: string
}

export interface PlayerDetail extends Player {
    club: ClubDetail,
    number: number,
    positionRole: string,
    position: string
}

export function Player() {

    const classes = useStyles();

    // @ts-ignore
    let {playerName, playerId}: { playerName: string, playerId: number } = useParams();

    const [playerDetail, setPlayerDetail] = React.useState<PlayerDetail>({
        id: playerId,
        firstName: "Jméno",
        lastName: "Přijmení",
        nameUrl: playerName,
        club: {
            id: 0,
            logoCrop: "",
            name: "Jméno klubu",
            nameUrl: "urlklubu"
        },
        number: 0,
        positionRole: "nedefinované",
        position: "UNKNOWN"
    });

    const [openCircularProgress, setOpenCircularProgress] = React.useState(false);

    React.useEffect(() => {
        ReactGA.initialize("UA-116060364-1");
        ReactGA.pageview(window.location.pathname);

        if (playerId == 261400) {
            window.location.href = "https://www.kdesehraje.cz";
        }

        setOpenCircularProgress(true);

        fetch(serverUrl + '/player/' + playerId + '/detail')
            .then(response => {
                console.log(response.status);
                if (response.status < 300) {
                    return Promise.resolve(response.json()) as Promise<PlayerDetail>;
                }
                return Promise.reject(response);
            })
            .then((result) => {
                setPlayerDetail(result)
                setTimeout(() => {
                    setOpenCircularProgress(false);
                }, 200);
            }, error => {
                setTimeout(() => {
                    setOpenCircularProgress(false);
                }, 200);
                console.log(error);
            })
            .catch(error => {
                setTimeout(() => {
                    setOpenCircularProgress(false);
                }, 200);
            })
    }, [playerId]);

    const handleClose = () => {
        setOpenCircularProgress(false);
    };

    const title: string = "Hráč " + playerDetail.firstName + " " + playerDetail.lastName + " - kdesehraje.cz";
    const description: string = playerDetail.firstName + " " + playerDetail.lastName;

    return (
        <Box style={{backgroundColor: "#edeff4"}}>

            <HeaderHelmet title={title} description={description}/>
            <Navbar/>
            <Backdrop className={classes.backdrop} open={openCircularProgress} onClick={handleClose}>
                <CircularProgress color="inherit"/>
            </Backdrop>

            <Container style={{backgroundColor: "#edeff4", paddingBottom: 30}}>
                <Grid container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      style={{
                          backgroundColor: "white",
                          border: "1px solid #dce0e4",
                          overflow: "hidden",
                          marginTop: 50
                      }}>
                    <Grid item xs={12} sm={6} style={{marginTop: "40px", display: "flex", verticalAlign: "middle"}}>
                        <Avatar style={{marginLeft: 20}} className={classes.large}/>
                        <Typography variant={"h4"} component={"h1"} align={"center"}
                                    style={{paddingLeft: 30, paddingTop: 8}}>
                            {playerDetail.firstName + " " + playerDetail.lastName}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Follow entityId={playerId} entityType={"player"}/>
                    </Grid>
                    <Grid container
                          style={{backgroundColor: "#f1f2f5", clear: "both", margin: 20, borderRadius: "10px"}}>
                        <Grid item xs={12} container
                              direction="row"
                              justify="space-around"
                              alignItems="center">
                            <Grid item container xs={12} sm={3}
                                  direction="row"
                                  justify="flex-start"
                                  alignItems="center">
                                <Grid item xs={12} style={{display: "flex", verticalAlign: "middle"}}>
                                    <Avatar alt="Logo klubu" style={{margin: 20}}
                                            src={playerDetail.club?.logoCrop ?? noLogo}/>

                                    <Typography
                                        style={{paddingTop: 30}}>{playerDetail.club?.name ?? "Neznámý klub"}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} sm={2}>
                                <Grid item xs={6} sm={12}>
                                    <Typography style={{
                                        color: "rgb(64, 64, 64)",
                                        textAlign: "center"
                                    }}>{playerDetail.positionRole}</Typography>
                                </Grid>
                                <Grid item xs={6} sm={12}>
                                    <Typography style={{color: "#808080", textAlign: "center"}}>Pozice</Typography>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} sm={2}>
                                <Grid item xs={6} sm={12}>
                                    <Typography style={{
                                        color: "rgb(64, 64, 64)",
                                        textAlign: "center"
                                    }}>{playerDetail.number}</Typography>
                                </Grid>
                                <Grid item xs={6} sm={12}>
                                    <Typography style={{color: "#808080", textAlign: "center"}}>Číslo dresu</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <PlayerMatchesBox playerId={playerId}/>
                {/*<DognetAd marginY={3} webAdType={AdType.HORIZONTAL} mobileAdType={AdType.CUBE}/>*/}
                <Ad ad={<ins className="adsbygoogle"
                             style={{display:"block"}}
                             data-ad-client="ca-pub-7325589669239459"
                             data-ad-slot="2653121138"
                             data-ad-format="auto"
                             data-full-width-responsive="true"></ins>}
                    height={100} marginY={3}
                />

                <PlayerTransferBox playerId={playerId}/>
                <Ad ad={<ins className="adsbygoogle"
                             style={{display:"block"}}
                             data-ad-client="ca-pub-7325589669239459"
                             data-ad-slot="2653121138"
                             data-ad-format="auto"
                             data-full-width-responsive="true"></ins>}
                    height={100} marginY={3}
                />
                {/*<DognetAd marginY={3} webAdType={AdType.HORIZONTAL} mobileAdType={AdType.CUBE}/>*/}
            </Container>
            <Divider/>
            <Footer/>
        </Box>
    );
}