import React from "react";
import {PlayerStat} from "../../../common/statistics/statistics";
import {serverUrl} from "../../../../index";
import Box from "@material-ui/core/Box";
import HeaderTitle from "../../../common/headerTitle";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import {makeStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 8
    }
}));



export default function BestShooters({teamId, teamName}: { teamId: number, teamName: string }) {

    const classes = useStyles();

    const [shooters, setShooters] = React.useState<Array<PlayerStat>>([])

    React.useEffect(() => {
        fetch(serverUrl + '/team/' + teamId + '/statistics?type=GOALS&limited=false')
            .then(res => res.json() as Promise<Array<PlayerStat>>)
            .then((result) => {
                setShooters(result)
            })
            .catch(console.log)
    }, [teamId]);

    const renderShooters = () => {
        const result: any = [];
        let i = 1;
        for (let shooter of shooters) {
            result.push(
                <TableRow>
                    <TableCell className={classes.root} align={"center"}>{i + "."}</TableCell>
                    <TableCell className={classes.root}>
                        <Link to={"/hrac/"+shooter.player.nameUrl + "/" + shooter.player.id}
                              style={{textDecoration: "none", color: "rgb(30, 65, 100)", fontSize: 14}}>
                            {shooter.player.firstName + " " + shooter.player.lastName}
                        </Link>
                    </TableCell>
                    <TableCell className={classes.root}>{shooter.player.positionRole}</TableCell>
                    <TableCell className={classes.root} align={"center"}>{shooter.count}</TableCell>
                </TableRow>
            )
            i++;
        }


        return result;
    }

    return (
        <Box mt={2} style={{backgroundColor: "white", border: '1px solid lightGrey'}}>
            <HeaderTitle title={"Nejlepší střelci týmu"}/>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.root} align={"center"}>Poř.</TableCell>
                        <TableCell className={classes.root}>Hráč</TableCell>
                        <TableCell className={classes.root}>Pozice</TableCell>
                        <TableCell className={classes.root} align={"center"}>Góly</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        renderShooters()
                    }
                    <TableRow>
                        <TableCell colSpan={4} align={"center"}>
                            <Link to={"/tym/"+ teamName + "/" + teamId + "/statistiky"}
                                  style={{textDecoration: "none", color: "black", fontSize: 14}}>
                            {"Další statistiky >>"}</Link>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Box>
    );
}