import React, {useContext} from "react";
import {Backdrop, Box, Button, CircularProgress, createStyles, Theme} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {Link} from "react-router-dom";
import {serverUrl} from "../../../index";
import {UserContext} from "../../auth/userContext";
import UserContextProvider from "../../auth/userContextProvider";
import makeStyles from "@material-ui/core/styles/makeStyles";

const entityMapping = {
    "player": {
        "name": "Sledovaný hráč",
        "follow": "Sleduj hráče",
    },
    "team": {
        "name": "Sledovaný tým",
        "follow": "Sleduj tým",
    },
    "league": {
        "name": "Sledovaná liga",
        "follow": "Sleduj ligu",
    },
    "club": {
        "name": "Sledovaný klub",
        "follow": "Sleduj klub",
    }

}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        large: {
            width: theme.spacing(7),
            height: theme.spacing(7),
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }),
);


export default function Follow({entityId, entityType}: { entityId: number, entityType: string }) {

    const classes = useStyles();

    const [openCircularProgress, setOpenCircularProgress] = React.useState(false);
    const {userData, isLogged} = useContext(UserContext);
    const [liked, setLiked] = React.useState<boolean>(false);
    const [dialogNotLogged, setDialogNotLogged] = React.useState<boolean>(false);

    React.useEffect(() => {

        if (!isLogged()) {
            return;
        }

        fetch(serverUrl + '/user/follow/' + entityType + '?id=' + entityId,
            {
                method: "GET",
                headers: {
                    "X-Auth": userData.token
                }
            })
            .then(res => res.json())
            .then((result) => {
                setLiked(result.following)
            })
            .catch(console.log)
    }, [entityId, isLogged]);

    const handleClickOpen = () => {
        setDialogNotLogged(true);
    };

    const handleClose = () => {
        setDialogNotLogged(false);
    };

    const getMapping = (type: string) => {
        // @ts-ignore
        return entityMapping[entityType][type];
    }

    const notLogged = () => {
        return (
            <>
                <Button size="small" variant="outlined" color="primary" onClick={handleClickOpen}>
                 {getMapping("follow")}
                </Button>
                <Dialog
                    open={dialogNotLogged}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Nejste přihlášeni."}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Pro sledování hráče je nutné se přihlásit. Jakmile se přihlásíte, budete moci
                            sledovat všechny hráče/týmy/kluby a jejich statistiky na jednom místě ve Vašem
                            profilu.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Link to="/prihlaseni">
                            <Button onClick={handleClose} color="primary">
                                PŘIHLÁŠENÍ
                            </Button>
                        </Link>
                        <Link to="/registrace">
                            <Button onClick={handleClose} color="primary">
                                REGISTRACE
                            </Button>
                        </Link>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    const likeUnliked = (followed: boolean) => {
        setOpenCircularProgress(true);
        fetch(serverUrl + '/user/follow/' + entityType,
            {
                method: "POST",
                headers: {
                    "X-Auth": userData.token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({"id": entityId, "followed": followed}, null, 2)
            })
            .then(() => {
                setTimeout(() => {
                    setOpenCircularProgress(false);
                }, 300);
            })
            .catch(console.log)
    }

    const like = () => {
        likeUnliked(true);
        setLiked(true);
    }

    const notLikedButton = () => {
        return (
            <Button size="small" variant="outlined" color="primary" onClick={like}>
                {getMapping("follow")}
            </Button>
        );
    }

    const unlike = () => {
        likeUnliked(false);
        setLiked(false);
    }

    const likedButton = () => {
        return (
            <Button size="small" variant="contained" color="primary" onClick={unlike}
                    style={{backgroundColor: "#1976d2", fontWeight: "bold"}}>
               {getMapping("name")}
            </Button>
        )
    }


    return (
        <Box style={{textAlign: "right", paddingRight: 20}}>
            <Backdrop className={classes.backdrop} open={openCircularProgress} onClick={handleClose}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <UserContextProvider.context.Consumer>
                {
                    context => (
                        context.isLogged() ?
                            (
                                liked ?
                                    (likedButton())
                                    :
                                    (notLikedButton())

                            )
                            :
                            (
                                notLogged()
                            )
                    )
                }
            </UserContextProvider.context.Consumer>
        </Box>
    );

}
