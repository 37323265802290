import React from "react";
import {Helmet} from "react-helmet";

interface HeaderAttributes {
    title: string,
    description: string
}

export default function HeaderHelmet({title, description}: HeaderAttributes) {

    return (
        <Helmet>
            <meta charSet="utf-8"/>
            <title>{title}</title>
            <meta name="description"
                  content={"kdesehraje, kde se hraje, fotbal, fotbalové zápasy, fotbalové statistiky, fotbalové výsledky, " + description}/>
        </Helmet>
    );


}