import React, {useEffect} from "react"
import {Container, createStyles, Grid, Theme, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Navbar from "../navbar/Navbar";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import Divider from "@material-ui/core/Divider";
import Footer from "../common/footer/footer";
import ReactGA from "react-ga";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Popular from "./popular/popular";
import Top from "./top/top";
import {Alert} from "@material-ui/lab";
import Ad from "../common/ads/ad";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            paddingBottom: 20
        },
        paper1: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: "white",
            backgroundColor: "darkGreen"
        },
        paper2: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: "white",
            backgroundColor: "green"
        },
        paper3: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: "white",
            backgroundColor: "#37a935"
        },
        linkNoneDecoration: {
            textDecoration: "none"
        }
    }),
);


export function Home() {
    const classes = useStyles();
    const logoSize = 200;

    useEffect(() => {
        ReactGA.initialize("UA-116060364-1");
        ReactGA.pageview(window.location.pathname);
    }, []);


    return (
        <Box>
            {/*<ExampleDial/>*/}
            {/*<FloatDial/>*/}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Kdesehraje.cz - Aktuální fotbalové zápasy, výsledky a statistiky</title>
                <meta name="description"
                      content="Fotbalové zápasy ze všech fotbalových soutěží ve Vašem nejbližším okolí. Aktuální fotbalové výsledky s nejrůznějšími statistikami"/>
            </Helmet>

            <Navbar/>
            <Container>
                <Box py={2}>
                    <Alert severity="warning">Omlouváme se za potíže s přihlášením/registrací, nyní by to mělo být v pořádku.</Alert>
                </Box>
            </Container>
            <Container style={{paddingTop: 40, marginTop: 40, marginBottom: 30, paddingBottom: 20}}>
                <Grid container alignContent={"center"} alignItems={"center"}>
                    <Grid md={3} xs={12} item style={{textAlign: "center"}}>
                        <img height={logoSize} width={logoSize} alt="Kdesehraje.cz - logo"
                             src={"https://www.kdesehraje.cz/media/logo.png"}/>
                    </Grid>
                    <Grid sm={9} item>
                        <Typography gutterBottom align={"center"} variant={"h3"} component={"h1"}
                                    style={{color: "#1e4164"}}>
                            Kdesehraje.cz
                        </Typography>
                        <Typography align={"center"} variant={"h6"} style={{color: "grey"}}>
                            Český fotbal na jednom místě.
                        </Typography>
                        <Typography align={"center"} variant={"h6"} style={{color: "grey"}}>
                            Aktuální fotbalové zápasy, výsledky a statistiky všech soutěží a
                            všech kategorií.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>

            <Container style={{textAlign: "center"}}>
                <Divider/>
                <Ad ad={<ins className="adsbygoogle"
                             style={{display: "block"}}
                             data-ad-client="ca-pub-7325589669239459"
                             data-ad-slot="2162467635"
                             data-ad-format="auto"
                             data-full-width-responsive="true"></ins>} height={100} marginY={3}/>
                {/*<DognetAd marginY={3} webAdType={AdType.HORIZONTAL} mobileAdType={AdType.CUBE}/>*/}
            </Container>
            <Box pb={3}>
                <Container>
                    <Divider/>
                    <Top/>
                    <Divider/>
                </Container>
            </Box>
            <Box py={3}>
                <Container>
                    <Grid container alignContent={"center"} alignItems={"center"}>
                        <Grid sm={5} item>
                            <Link to={"/mapa"}>
                                <img alt="Ukázka mapy s aktuálními zápasy." width={"60%"}
                                     style={{display: "block", margin: "0 auto"}}
                                     src="https://www.kdesehraje.cz/media/map-mobile.png"/></Link>
                        </Grid>
                        <Grid sm={7} item>

                            <Link to="/mapa" style={{textDecoration: "none"}}>
                                <Typography align={"center"} gutterBottom variant={"h4"} component={"h2"}
                                            style={{color: "#1e4164"}}>
                                    Interaktivní mapa s aktuálnímí zápasy
                                </Typography>
                            </Link>

                            <Typography variant={"body1"} paragraph align={"center"}>Na interaktivní mapě je možné
                                zobrazit zápasy ve Vašem okolí v
                                nadcházejícím týdnu. Je možné si vybrat konkrétní den, typ soutěže. </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Container style={{textAlign: "center"}}>
                <Divider/>
                <Ad ad={<ins className="adsbygoogle"
                             style={{display:"block"}}
                             data-ad-client="ca-pub-7325589669239459"
                             data-ad-slot="3220312942"
                             data-ad-format="auto"
                             data-full-width-responsive="true"></ins>} height={100} marginY={3}/>
                {/*<DognetAd marginY={3} webAdType={AdType.HORIZONTAL} mobileAdType={AdType.CUBE}/>*/}
            </Container>

            <Box pb={5}>
                <Container>
                    <Divider/>
                    <Popular/>
                    <Divider/>
                </Container>
            </Box>

            <Box style={{backgroundColor: "#f0f2f4", paddingBottom: 90, paddingTop: 60}}>
                <Container className={classes.root}>
                    <Typography variant={"h5"} component={"h2"} style={{paddingBottom: 20}}>Přehled soutěží</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}><Link to={"/souteze/celostatni-soutez/1"}
                                                 className={classes.linkNoneDecoration}><Paper
                            className={classes.paper1}>Celostátní soutěže</Paper></Link></Grid>
                        <Grid item xs={12} sm={6}><Link to={"/souteze/cechy/2"}
                                                        className={classes.linkNoneDecoration}><Paper
                            className={classes.paper2}>Čechy</Paper></Link></Grid>
                        <Grid item xs={12} sm={6}><Link to={"/souteze/morava/3"}
                                                        className={classes.linkNoneDecoration}><Paper
                            className={classes.paper2}>Morava</Paper></Link></Grid>
                        <Grid item xs={12} sm={3}><Link to={"/souteze/hl-mesto-praha/4"}
                                                        className={classes.linkNoneDecoration}><Paper
                            className={classes.paper3}>Hl. město Praha</Paper></Link></Grid>
                        <Grid item xs={12} sm={3}><Link to={"/souteze/stredocesky-kraj/5"}
                                                        className={classes.linkNoneDecoration}><Paper
                            className={classes.paper3}>Středočeský kraj</Paper></Link></Grid>
                        <Grid item xs={12} sm={3}><Link to={"/souteze/vysocina-kraj/57"}
                                                        className={classes.linkNoneDecoration}><Paper
                            className={classes.paper3}>Vysočina kraj</Paper></Link></Grid>
                        <Grid item xs={12} sm={3}><Link to={"/souteze/pardubicky-kraj/63"}
                                                        className={classes.linkNoneDecoration}><Paper
                            className={classes.paper3}>Pardubický kraj</Paper></Link></Grid>
                        <Grid item xs={12} sm={3}><Link to={"/souteze/jihocesky-kraj/18"}
                                                        className={classes.linkNoneDecoration}><Paper
                            className={classes.paper3}>Jihočeský kraj</Paper></Link></Grid>
                        <Grid item xs={12} sm={3}><Link to={"/souteze/plzensky-kraj/26"}
                                                        className={classes.linkNoneDecoration}><Paper
                            className={classes.paper3}>Plzeňský kraj</Paper></Link></Grid>
                        <Grid item xs={12} sm={3}><Link to={"/souteze/jihomoravsky-kraj/68"}
                                                        className={classes.linkNoneDecoration}><Paper
                            className={classes.paper3}>Jihomoravský kraj</Paper></Link></Grid>
                        <Grid item xs={12} sm={3}><Link to={"/souteze/olomoucky-kraj/76"}
                                                        className={classes.linkNoneDecoration}><Paper
                            className={classes.paper3}>Olomoucký kraj</Paper></Link></Grid>
                        <Grid item xs={12} sm={3}><Link to={"/souteze/ustecky-kraj/38"}
                                                        className={classes.linkNoneDecoration}><Paper
                            className={classes.paper3}>Ústecký kraj</Paper></Link></Grid>
                        <Grid item xs={12} sm={3}><Link to={"/souteze/liberecky-kraj/46"}
                                                        className={classes.linkNoneDecoration}><Paper
                            className={classes.paper3}>Liberecký kraj</Paper></Link></Grid>
                        <Grid item xs={12} sm={3}><Link to={"/souteze/zlinsky-kraj/82"}
                                                        className={classes.linkNoneDecoration}><Paper
                            className={classes.paper3}>Zlínský kraj</Paper></Link></Grid>
                        <Grid item xs={12} sm={3}><Link to={"/souteze/moravskoslezsky-kraj/87"}
                                                        className={classes.linkNoneDecoration}><Paper
                            className={classes.paper3}>Moravskoslezský kraj</Paper></Link></Grid>
                        <Grid item xs={12} sm={3}><Link to={"/souteze/kralovehradecky-kraj/51"}
                                                        className={classes.linkNoneDecoration}><Paper
                            className={classes.paper3}>Královehradecký kraj</Paper></Link></Grid>
                        <Grid item xs={12} sm={3}><Link to={"/souteze/karlovarsky-kraj/34"}
                                                        className={classes.linkNoneDecoration}><Paper
                            className={classes.paper3}>Karlovarský kraj</Paper></Link></Grid>

                    </Grid>
                </Container>
            </Box>
            <Box style={{paddingBottom: 90, paddingTop: 60}}>
                <Container>
                    <Typography gutterBottom variant={"h4"} component={"h2"} align={"left"}
                                style={{color: "#008e45"}}>
                        Hrdinové v nižších soutěžích
                    </Typography>
                    <Typography variant={"body1"} component={"div"} align={"justify"}>
                        Seznam profesionálních hráčů, kteří momentálně hrají za některý klub z nižších soutěží. Podívej
                        se na <Link to={"/hrdinove-v-nizsich-soutezich"}>jejich profil</Link> a zjisti, kde budou
                        hrát <Link to={"/hrdinove-v-nizsich-soutezich"}>nasledující víkend</Link>.
                    </Typography>
                </Container>
            </Box>
            <Container style={{textAlign: "center"}}>
                <Divider/>
                <Ad ad={<ins className="adsbygoogle"
                             style={{display:"block"}}
                             data-ad-client="ca-pub-7325589669239459"
                             data-ad-slot="7229223600"
                             data-ad-format="auto"
                             data-full-width-responsive="true"></ins>} height={100} marginY={3}/>
                {/*<DognetAd marginY={3} webAdType={AdType.HORIZONTAL} mobileAdType={AdType.CUBE}/>*/}
            </Container>
            <Divider/>
            <Footer/>
            {/*<FloatDial/>*/}
        </Box>
    );
}
