import React from "react";
import {Box, Typography} from "@material-ui/core";
import HeaderTitle from "../../../common/headerTitle";


export default function News() {

    return (
        <Box style={{
            borderTop: "1px solid #dce0e4",
            borderRight: "1px solid #dce0e4",
            borderLeft: "1px solid #dce0e4",
            minHeight: 300,
            backgroundColor: "white"
        }}>
            <HeaderTitle title={"Novinky"}/>
            <Typography style={{padding: 20}}>Novinky budou postupně přidávány.</Typography>
        </Box>
    );
}