import React from "react";
import {Box, Divider, LinearProgress, Typography} from "@material-ui/core";
import FBLogo from "./img/FB-logo.png";
import Grid from "@material-ui/core/Grid";
import {Field, Form, Formik} from "formik";
import {serverUrl} from "../../../index";
import {TextField} from "formik-material-ui";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import {Alert} from "@material-ui/lab";
import {Link} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    footerLink: {
        color: "#ffa500",
        textDecoration: "none"
    }
}));

export default function Footer() {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState(false);


    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        setError(false);
    };

    const newslettterForm = () => {
        return <Formik
            initialValues={{
                email: ''
            }}
            validate={values => {
                // @ts-ignore
                const errors: Partial<Values> = {};
                if (values.email &&
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
                ) {
                    errors.email = 'Emailová adresa není validní.';
                }
                return errors;
            }}
            onSubmit={(values, {setSubmitting}) => {
                setTimeout(() => {
                    setSubmitting(false);

                    fetch(serverUrl + "/newsletter/add", {
                        method: "post",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },

                        //make sure to serialize your JSON body
                        body: JSON.stringify(values, null, 2)
                    })
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error("Problém s přihlášením do newsletteru.");
                            }
                        }).then(data => {
                        setOpen(true);
                    })
                        .catch(error => {
                            setError(true);
                        });
                }, 500);

            }}
        >
            {({submitForm, isSubmitting}) => (
                // @ts-ignore
                <Form className={classes.form}>
                    <Grid container direction="column" item xs={12} sm={8} spacing={2}>
                        <Grid item>
                            <Field
                                component={TextField}
                                name="email"
                                type="email"
                                variant="filled"
                                fullWidth
                                id="email"
                                label="Zadejte emailovou adresu"
                                autoComplete="email"
                                style={{backgroundColor: "white"}}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting}
                                onClick={submitForm}
                                fullWidth
                            >
                                Odebírat novinky
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {isSubmitting && <LinearProgress/>}
                    </Grid>
                </Form>
            )}
        </Formik>
    }

    return (
        <Box style={{backgroundColor: "#043b69", minHeight: 300, padding: 30}}>
            <Grid container spacing={3}>
                <Grid container item xs={12} sm={3} direction={"column"}>
                    <Grid item>
                        <Typography variant={"h6"} gutterBottom
                                    style={{color: "white"}}>Kdesehraje.cz</Typography>
                        <Divider variant={"fullWidth"} style={{height: 1, backgroundColor: "lightgrey"}}/>
                    </Grid>
                    <Grid item>
                        <Typography style={{color: "lightgrey", marginTop: 10}} align={"justify"} variant={"body1"}>
                            Cílem projektu Kdesehraje.cz je poskytnout veškeré informace ohledně českého fotbalu -
                            jak amatérského, tak i profesionálního. Jde nám o to, aby jste měli veškeré informace
                            ohledně klubů, týmů, zápasů a hráčů na jednom místě.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={2} direction={"column"}>
                    <Grid item>
                        <Typography variant={"h6"} gutterBottom style={{color: "white"}}>
                            Navigace
                        </Typography>
                        <Divider variant={"fullWidth"} style={{height: 1, backgroundColor: "lightgrey"}}/>
                    </Grid>
                    <Grid item style={{marginTop: 10}}>
                        <Link to="/mapa" className={classes.footerLink}>
                            Fotbalová mapa
                        </Link>
                    </Grid>
                    <Grid item style={{marginTop: 10}}>
                        <Link to="/kontakt" className={classes.footerLink}>
                            Kontakt
                        </Link>
                    </Grid>
                    <Grid item style={{marginTop: 10}}>
                        <Link to="/prihlaseni" className={classes.footerLink}>
                            Přihlášení
                        </Link>
                    </Grid>
                    <Grid item style={{marginTop: 10}}>
                        <Link to="/co-je-noveho" className={classes.footerLink}>
                            Seznam změn
                        </Link>
                    </Grid>
                    <Grid item style={{marginTop: 10}}>
                        <Link to="/spoluprace" className={classes.footerLink}>
                            Spolupráce
                        </Link>
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={2} direction={"column"}>
                    <Grid item>
                        <Typography variant={"h6"} gutterBottom style={{color: "white"}}>
                            Sledujte nás
                        </Typography>
                        <Divider variant={"fullWidth"} style={{height: 1, backgroundColor: "lightgrey"}}/>
                    </Grid>
                    <Grid item container direction={"row"} spacing={2}>
                        <Grid item style={{marginTop: 10}}>
                            <a href="https://www.facebook.com/pg/Kdesehrajecz-894905800683224" target="_blank">
                                <img src={"https://www.kdesehraje.cz/media/socmedia/FB-logo.png"} alt={"facebook-logo"}/></a>
                        </Grid>
                        <Grid item style={{marginTop: 5}}>
                            <a href="https://www.instagram.com/kdesehraje" target="_blank">
                                <img src={"https://www.kdesehraje.cz/media/socmedia/ig.webp"} height={67}
                                     alt={"instagram-logo"}/></a>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={5}>
                    <Grid item xs={12}>
                        <Typography variant={"h6"} gutterBottom style={{color: "white"}}>
                            Odebírejte nás
                        </Typography>
                        <Divider variant={"fullWidth"} style={{height: 1, backgroundColor: "lightgrey"}}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"body1"} gutterBottom style={{color: "lightgrey", paddingTop: 10}}
                                    align={"justify"}>
                            Zasíláme informace ze světa fotbalu, zajímavé statistiky a nové funkce našeho webu.
                        </Typography>
                    </Grid>
                    <Grid item container xs={12}>
                        {newslettterForm()}
                    </Grid>
                </Grid>
            </Grid>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    Email byl úspěšně přidán!
                </Alert>
            </Snackbar>
            <Snackbar open={error} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    Problém s přidáním emailu.
                </Alert>
            </Snackbar>
        </Box>
    );
}