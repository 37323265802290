import React from 'react'
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Header from "../common/header/header";
import {Mapping, MenuItem} from "../common/content/content";
import Navbar from "../navbar/Navbar";
import {Link, Outlet, useParams} from "react-router-dom";
import Footer from "../common/footer/footer";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga";
import {serverUrl} from "../../index";
import {Breadcrumbs} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Follow from "../common/follow/follow";

export interface ClubDetail {
    name: string,
    logoCrop: string,
    nameUrl: string,
    id: number
}

export type ClubSubPageContext = { clubName: string, clubId:number };

const mapping: Mapping = {
    header: "klubu",
    navigation: "Klub"
};

export function getMenuItems(clubName:string, clubId:number) {
    const base:string = "/klub/" + clubName + "/" + clubId;

    const news: MenuItem = {
        name: "Novinky",
        nameUrl: base
    };

    const matches: MenuItem = {
        name: "Zápasy",
        nameUrl: base + "/zapasy"
    };

    const teams: MenuItem = {
        name: "Týmy",
        nameUrl: base + "/tymy"
    };

    return [
        news,
        matches,
        teams
    ];
}

export default function Club() {

    let params = useParams()

    let {clubName, clubId}: { clubName: string, clubId: number } = {clubName: params.clubName!, clubId: Number(params.clubId)}

    const [clubDetail, setClubDetail] = React.useState<ClubDetail>({
        name: "--",
        logoCrop: "--",
        nameUrl: "",
        id: clubId
    });

    React.useEffect(() => {

        ReactGA.initialize("UA-116060364-1");
        ReactGA.pageview(window.location.pathname);

        fetch(serverUrl + '/club/' + clubId + '/detail')
            .then(res => res.json())
            .then((result) => {
                setClubDetail(result)
            })
            .catch(console.log)
    }, [clubId]);


    return (
        <Box>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Klub {clubDetail.name} - www.kdesehraje.cz</title>
                <meta name="description"
                      content={"kdesehraje, kde se hraje, fotbal, fotbalové zápasy, fotbalové statistiky, fotbalové výsledky, " + clubDetail.name}/>
            </Helmet>
            <Navbar/>

            <Header
                logoUrl={clubDetail.logoCrop}
                name={clubDetail.name}
                useLogo={true}
                content={
                    <Grid container style={{paddingBottom: 10}}>
                        <Grid item sm={6} xs={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link color={"inherit"} to="/" style={{textDecoration: "none", color: "grey"}}>
                                    Domů
                                </Link>
                                <Link color={"inherit"} to="#" style={{textDecoration: "none", color: "black"}}>
                                    {"Klub " + clubDetail.name}
                                </Link>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Follow entityId={clubId} entityType={"club"}/>
                        </Grid>
                    </Grid>
                }
            />
            <Divider/>
            <Outlet context={{"clubId":clubId, "clubName":clubName}} />
            <Footer/>
        </Box>
    );
}