import React from "react"
import Box from "@material-ui/core/Box";
import Navbar from "../navbar/Navbar";
import Header from "../common/header/header"
import Divider from "@material-ui/core/Divider";
import {useParams} from "react-router";
import {Mapping, MenuItem} from "../common/content/content";
import {Breadcrumbs} from "@material-ui/core";
import {ClubDetail} from "../club/club";
import "./team.css"
import Footer from "../common/footer/footer";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga";
import {serverUrl} from "../../index";
import Grid from "@material-ui/core/Grid";
import Follow from "../common/follow/follow";
import {Link, Outlet} from "react-router-dom";

function getMenuTabs(teamName: string, teamId: number) {

    const base:string = "/tym/" + teamName + "/" + teamId;

    const news: MenuItem = {
        name: "Novinky",
        nameUrl: base + ""
    };

    const matches: MenuItem = {
        name: "Zápasy",
        nameUrl: base + "/zapasy"
    };

    const teams: MenuItem = {
        name: "Tabulka",
        nameUrl: base + "/tabulka"
    };

    const players: MenuItem = {
        name: "Hráči",
        nameUrl: base + "/hraci"
    };

    const stats: MenuItem = {
        name: "Statistiky",
        nameUrl: base + "/statistiky"
    };

    return [
        news,
        matches,
        teams,
        players,
        stats
    ];
}

export type TeamSubPageContext = { teamName: string, teamId:number, menuTabs: Array<MenuItem> };

const mapping: Mapping = {
    header: "týmu",
    navigation: "Tým"
};

export interface LeagueType {
    name: string,
    color: string
}

export interface Team {
    name: string,
    leagueName: string,
    id: number,
    nameUrl: string,
    logo?: string
}

interface TeamDetail {
    name: string,
    type: LeagueType,
    club: ClubDetail
}

const teamDefault: TeamDetail = {
    name: "",
    type: {name: "", color: ""},
    club: {name: "", logoCrop: "", nameUrl: "", id: 0}
};

export function Team() {

    const [teamDetail, setTeamDetail] = React.useState<TeamDetail>(teamDefault);

    // @ts-ignore
    let {teamName, teamId}: { teamName: string, teamId: number } = useParams();

    React.useEffect(() => {
        ReactGA.initialize("UA-116060364-1");
        ReactGA.pageview(window.location.pathname);

        fetch(serverUrl + '/team/' + teamId + '/detail')
            .then(res => res.json())
            .then((result) => {
                setTeamDetail(result)
            })
            .catch(console.log)
    }, [teamId]);

    return (
        <Box>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Tým {teamDetail.name} - www.kdesehraje.cz</title>
                <meta name="description"
                      content={"Detail týmu, kdesehraje, kde se hraje, fotbal, fotbalové zápasy, fotbalové statistiky, fotbalové výsledky, " + teamDetail.name}/>
            </Helmet>
            <Navbar/>

            <Header
                logoUrl={teamDetail.club.logoCrop}
                name={teamDetail.name}
                type={teamDetail.type.name}
                useLogo={true}
                content={
                    <Grid container style={{paddingBottom: 10}}>
                        <Grid item xs={12} sm={6}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link to="/" style={{textDecoration: "none", color: "grey"}}>
                                    Domů
                                </Link>
                                <Link style={{textDecoration: "none", color: "grey"}}
                                      to={"/klub/" + teamDetail.club.nameUrl + "/" + teamDetail.club.id}>
                                    Klub {teamDetail.club.name}
                                </Link>
                                <Link to={"#"} style={{textDecoration: "none", color: "black"}}>
                                     Tým {teamDetail.name}
                                </Link>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Follow entityId={teamId} entityType={"team"}/>
                        </Grid>
                    </Grid>
                }
            />

            <Divider/>
            <Outlet context={{"teamId":teamId, "teamName":teamName, menuTabs: getMenuTabs(teamName, teamId)}} />
            <Footer/>
        </Box>
    );
}