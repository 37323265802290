import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Field, Form, Formik} from "formik";
import {LinearProgress} from "@material-ui/core";
import {TextField} from "formik-material-ui";
import Navbar from "../navbar/Navbar";
import {Link} from 'react-router-dom';
import {serverUrl} from "../../index";
import UserContextProvider from "../auth/userContextProvider";
import {Alert} from "@material-ui/lab";
import Divider from "@material-ui/core/Divider";
import Footer from "../common/footer/footer";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        minHeight: 700,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(2, 0, 2),
    },
}));

interface RegistrationContext {
    registered: boolean,
    email: string
}

export default function SignUp() {
    const classes = useStyles();
    const [apiError, setApiError] = useState<string | undefined>(undefined);
    const [registrationContext, setRegistrationContext] = useState<RegistrationContext>({registered: false, email: ""});

    const signUpForm = () => {
        return (
            <UserContextProvider.context.Consumer>
                {
                    // @ts-ignore
                    context => (
                        <Formik
                            initialValues={{
                                firstName: '',
                                lastName: '',
                                email: '',
                                password: '',
                                matchingPassword: '',
                            }}
                            validate={values => {
                                // @ts-ignore
                                const errors: Partial<Values> = {};

                                if (!values.firstName) {
                                    errors.firstName = "Jméno je povinné."
                                }

                                if (!values.lastName) {
                                    errors.lastName = "Přijmení je povinné."
                                }

                                if (!values.email) {
                                    errors.email = 'Email je vyžadován.';
                                } else if (
                                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
                                ) {
                                    errors.email = 'Emailová adresa není validní.';
                                }

                                if (!values.password) {
                                    errors.password = 'Heslo nesmí být prázdné.'
                                }

                                if (!values.matchingPassword || values.matchingPassword !== values.password) {
                                    errors.matchingPassword = 'Hesla nejsou stejné.'
                                }


                                return errors;
                            }}
                            onSubmit={(values, {setSubmitting, resetForm}) => {
                                setTimeout(() => {
                                    setSubmitting(false);
                                    //alert(JSON.stringify(values, null, 2));

                                    fetch(serverUrl + "/user/register", {
                                        method: "post",
                                        headers: {
                                            'Accept': 'application/json',
                                            'Content-Type': 'application/json'
                                        },

                                        //make sure to serialize your JSON body
                                        body: JSON.stringify(values, null, 2)
                                    })
                                        .then((response) => {
                                                if (response.ok) {
                                                    return response.json();
                                                } else {
                                                    throw response;
                                                    //throw new Error("Problém při registraci.");
                                                }
                                            }
                                        ).then(data => {
                                        setRegistrationContext({registered: true, email: data.email});
                                        resetForm({});
                                    })
                                        .catch(reason => {
                                            setApiError("Problém při registraci. Prosím opakujte za chvíli.");
                                        });
                                }, 200);
                            }}
                        >
                            {({submitForm, isSubmitting}) => (
                                // @ts-ignore
                                <Form className={classes.form}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                component={TextField}
                                                name="firstName"
                                                variant="outlined"
                                                margin={"normal"}
                                                required
                                                fullWidth
                                                id="firstName"
                                                label="Jméno"
                                                autoComplete="fname"
                                                autoFocus
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                component={TextField}
                                                name="lastName"
                                                variant="outlined"
                                                margin={"normal"}
                                                required
                                                fullWidth
                                                id="lastName"
                                                label="Přijmení"
                                                autoComplete="lname"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                component={TextField}
                                                name="email"
                                                type="email"
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="email"
                                                label="Emailová adresa"
                                                autoComplete="email"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                component={TextField}
                                                type="password"
                                                label="Heslo"
                                                name="password"
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="password"
                                                autoComplete="current-password"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                component={TextField}
                                                type="password"
                                                label="Heslo znovu"
                                                name="matchingPassword"
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="matchingPassword"
                                                autoComplete="password-match"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant={"body2"} color={"textSecondary"}>* povinné údaje</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant={"body2"} color={"textPrimary"}>
                                                Po registraci Vám dojde na zadaný email aktivační odkaz, na který je nutné kliknout.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    {isSubmitting && <LinearProgress/>}
                                    <br/>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}
                                        onClick={submitForm}
                                        fullWidth
                                        className={classes.submit}
                                    >
                                        Registrovat
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    )}
            </UserContextProvider.context.Consumer>
        );
    };

    return (
        <>
            <Navbar/>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Registrace
                    </Typography>
                    {
                        apiError !== undefined &&
                        (
                            <Alert severity="error">{apiError}</Alert>
                        )
                    }
                    {
                        registrationContext.registered &&
                        (
                            <Alert
                                severity="success">{"Účet byl úspěšně vytvořen. Prosím zkontrolujte si svůj email " + registrationContext.email + " a " +
                            "klikněte na aktivační odkaz"}.</Alert>
                        )
                    }
                    {
                        signUpForm()
                    }
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link to="/prihlaseni" style={{textDecoration: "none", fontSize: 16}}>
                                Už máte účet? Přihlašte se.
                            </Link>
                        </Grid>
                    </Grid>
                </div>
            </Container>
            <Divider/>
            <Footer/>
        </>
    );
}