import React, {useContext} from "react";
import {Box} from "@material-ui/core";
import {UserContext} from "../../auth/userContext";
import {serverUrl} from "../../../index";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {Link} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import {League} from "../../league/league";


export default function SimpleDisplayLeagues() {
    const {userData, isLogged} = useContext(UserContext);
    const [leagues, setLeagues] = React.useState<Array<League>>([]);

    React.useEffect(() => {

        if (!isLogged()) {
            return;
        }

        fetch(serverUrl + '/user/profile/followed/leagues',
            {
                method: "GET",
                headers: {
                    "X-Auth": userData.token
                }
            })
            .then(res => res.json() as Promise<Array<League>>)
            .then((result) => {
                setLeagues(result)
            })
            .catch(console.log)
    }, [isLogged]);


    return (
        <Box>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Oblíbené ligy</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            leagues.length == 0 ?
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Nemáte v oblíbených žádné ligy. Běžte na profil Vaši oblíbené ligy a
                                        klikněte na tlačítko
                                        <Typography display={"inline"}
                                                    style={{fontWeight: "bold"}}> SLEDUJ</Typography> v
                                        pravém horním rohu.
                                    </TableCell>
                                </TableRow>
                                :
                                leagues.map((league) => (
                                    <TableRow key={league.id}>
                                        <TableCell component="th" scope="row">
                                            <Link target={"_blank"}
                                                  to={"/liga/" + league.nameUrl + "/" + league.id}> {league.name}</Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </Box>
    )
}