import React from "react";
import Box from "@material-ui/core/Box";
import {Container} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import makeStyles from "@material-ui/core/styles/makeStyles";
import "./content.css";
import {Link} from "react-router-dom";
import {isMobile} from "react-device-detect";
import {MenuItem, MenuProps} from "./content";
import Grid from "@material-ui/core/Grid";

const menuStyles = makeStyles(theme => ({
    menuWrapper: {
        backgroundColor: "white",
    },
    menuContainer: {
        backgroundColor: "white"
    },
    menuTabs: {
        color: "black",
        fontSize: 15,
        fontWeight: 800,
    }
}));

function a11yProps(index: Number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export function Menu({menuTabs, selectedTab}: MenuProps) {

    const classes = menuStyles();
    const tabs: Array<Object> = [];

    menuTabs.forEach((val: MenuItem, index: number) => {
        tabs.push(<Tab component={Link} to={val.nameUrl} label={val.name} key={index} {...a11yProps(index)}
                       className={classes.menuTabs}/>);
    });

    return (
        <div className={classes.menuWrapper}>
            <Container className={classes.menuContainer}>
                <Tabs
                    variant="fullWidth"
                    orientation={isMobile ? "vertical" : "horizontal"}
                    aria-label="nav tabs example"
                    value={selectedTab}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "#38a9ff"
                        }
                    }}
                >
                    {tabs}
                </Tabs>
            </Container>
        </div>
    );
}

export function MenuContent2({contentLeft, contentRight, tabId, leftSize, rightSize}: { contentLeft: Object, contentRight: Object, tabId: number, leftSize: number, rightSize: number }) {


    return (
        <Box style={{backgroundColor: "#edeff4", paddingTop: 40, paddingBottom: 40}}>
            <Container style={{backgroundColor: "#edeff4"}}
                       component="div"
                       role="tabpanel"
                       id={`simple-tabpanel-${tabId}`}
                       aria-labelledby={`simple-tab-${tabId}`}
            >

                <Grid container spacing={3}>
                    {
                        <>
                            {/*// @ts-ignore*/}
                            <Grid item xs={12} sm={leftSize}>{contentLeft}</Grid>
                            {/*// @ts-ignore*/}
                            <Grid item xs={12} sm={rightSize}>{contentRight}</Grid>
                        </>
                    }
                </Grid>
            </Container>
        </Box>
    );
}


