import React, {useContext} from "react";
import {Box} from "@material-ui/core";
import {UserContext} from "../../auth/userContext";
import {serverUrl} from "../../../index";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {Link} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import {ClubDetail} from "../../club/club";


export default function SimpleDisplayClubs() {
    const {userData, isLogged} = useContext(UserContext);
    const [clubs, setClubs] = React.useState<Array<ClubDetail>>([]);

    React.useEffect(() => {

        if (!isLogged()) {
            return;
        }

        fetch(serverUrl + '/user/profile/followed/clubs',
            {
                method: "GET",
                headers: {
                    "X-Auth": userData.token
                }
            })
            .then(res => res.json() as Promise<Array<ClubDetail>>)
            .then((result) => {
                setClubs(result)
            })
            .catch(console.log)
    }, [isLogged]);


    return (
        <Box>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Oblíbené kluby</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            clubs.length == 0 ?
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Nemáte v oblíbených žádné kluby. Běžte na profil Vašho oblíbeného klubu a
                                        klikněte na tlačítko
                                        <Typography display={"inline"}
                                                    style={{fontWeight: "bold"}}> SLEDUJ</Typography> v
                                        pravém horním rohu.
                                    </TableCell>
                                </TableRow>
                                :
                                clubs.map((club) => (
                                    <TableRow key={club.id}>
                                        <TableCell component="th" scope="row">
                                            <Link target={"_blank"}
                                                  to={"/klub/" + club.nameUrl + "/" + club.id}> {club.name}</Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </Box>
    )
}