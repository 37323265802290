import React from "react";
import {Helmet} from "react-helmet";
import Navbar from "../navbar/Navbar";
import Box from "@material-ui/core/Box";
import {Container, LinearProgress, TextareaAutosize, Typography} from "@material-ui/core";
import Footer from "../common/footer/footer";
import {Field, Form, Formik} from "formik";
import {serverUrl} from "../../index";
import {TextField} from "formik-material-ui";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));


export default function Contact() {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState(false);


    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        setError(false);
    };


    const contactForm = () => {
        return (
            <Formik
                initialValues={{
                    email: '',
                    text: '',
                }}
                validate={values => {
                    // @ts-ignore
                    const errors: Partial<Values> = {};
                    if (!values.email) {
                        errors.email = 'Email je vyžadován.';
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
                    ) {
                        errors.email = 'Emailová adresa není validní.';
                    }

                    if (!values.text) {
                        errors.text = "Text nesmí být prázdný!";
                    }

                    return errors;
                }}
                onSubmit={(values, {setSubmitting, resetForm}) => {
                    setTimeout(() => {
                        setSubmitting(false);

                        fetch(serverUrl + "/feedback/add", {
                            method: "post",
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },

                            //make sure to serialize your JSON body
                            body: JSON.stringify(values, null, 2)
                        })
                            .then((response) => {
                                if (!response.ok) {
                                    throw new Error("Problém s odesláním zprávy.");
                                }
                            }).then(data => {
                            setOpen(true);
                            resetForm({});
                        })
                            .catch(reason => {
                                setError(true);
                            });
                    }, 500);

                }}
            >
                {({submitForm, isSubmitting}) => (
                    // @ts-ignore
                    <Form className={classes.form}>
                        <Field
                            component={TextField}
                            name="email"
                            type="email"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Emailová adresa"
                            autoComplete="email"
                            autoFocus
                        />
                        <br/>
                        {
                            <Field as={TextareaAutosize}
                                   rows={10}
                                   cols={53}
                                   name="text"
                                   type="text"
                                   variant="outlined"
                                   margin="normal"
                                   required
                                   fullWidth
                                   outlined
                                   id="text"
                                   label="Text"
                                   autoComplete="text"
                            />
                        }
                        {isSubmitting && <LinearProgress/>}
                        <br/>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                            onClick={submitForm}
                            fullWidth
                            className={classes.submit}
                        >
                            Odeslat
                        </Button>
                    </Form>
                )}
            </Formik>
        )
    }

    return (
        <Box>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Kontakt - Kdesehraje.cz</title>
                <meta name="description"
                      content={"kdesehraje, kde se hraje, fotbal, fotbalové zápasy, fotbalové statistiky, fotbalové výsledky"}/>
            </Helmet>
            <Navbar/>
            <Container style={{minHeight: 554, paddingTop: 50}} component="main" maxWidth="xs">
                <Typography variant={"h4"} component={"h1"} align={"center"}>
                    Kontaktujte nás!
                </Typography>

                {
                    contactForm()
                }

                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                          anchorOrigin={{vertical: "top", horizontal: "center"}}>
                    <Alert onClose={handleClose} severity="success">
                        Zpráva byla úspěšně odeslána!
                    </Alert>
                </Snackbar>
                <Snackbar open={error} autoHideDuration={6000} onClose={handleClose}
                          anchorOrigin={{vertical: "top", horizontal: "center"}}>
                    <Alert onClose={handleClose} severity="error">
                        Problém s odesláním zprávy.
                    </Alert>
                </Snackbar>
            </Container>
            <Footer/>
        </Box>
    );
}

