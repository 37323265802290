import React, {useState} from "react";
import {Field, Form, Formik} from "formik";
import {serverUrl} from "../../index";
import {TextField} from "formik-material-ui";
import {LinearProgress} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import Navbar from "../navbar/Navbar";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import {Alert} from "@material-ui/lab";
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import Footer from "../common/footer/footer";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        minHeight: 550,
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(0, 0, 2),
    },
}));


export default function SetNewPassword() {

    const classes = useStyles();
    const [errorMsg, setErrorMsg] = useState<boolean>(false);
    const [initError, setInitError] = useState<boolean>(false);
    const [infoMsg, setInfoMsg] = useState<boolean>(false);

    const getParams = () => {
        const params = new URLSearchParams(window.location.search)
        let token = params.get('token')
        let userId = params.get('userId')

        console.log("token: "+ token + ", userId: " + userId)

        return {"token":token, "userId": userId}
    }


    React.useEffect(() => {

        fetch(serverUrl + '/user/check-set-user-password-token-password',
            {
                method: "post",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },

                //make sure to serialize your JSON body
                body: JSON.stringify(getParams(), null, 2)
            })
            .then(res => res.json())
            .then((response) => {
                if (!response.ok) {
                    setInitError(true)
                }
            })
            .catch(console.log)
    }, []);

    const resetPwdForm = () => {
        return (
            <Formik
                initialValues={{
                    password: '',
                    againPassword: ''
                }}
                validate={values => {
                    // @ts-ignore
                    const errors: Partial<Values> = {};
                    if (!values.password) {
                        errors.password = 'Heslo je vyžadováno. ';
                    }

                    if (!values.againPassword) {
                        errors.againPassword = 'Heslo je nutné zadat znovu.';
                    } else if (values.password !== values.againPassword) {
                        errors.againPassword = 'Hesla se neshodují';
                    }

                    return errors;
                }}
                onSubmit={(values, {setSubmitting, resetForm}) => {
                    setTimeout(() => {
                        setErrorMsg(false);
                        setInfoMsg(false);
                        setSubmitting(false);
                        let payload = {
                            ...values,
                            ...getParams()
                        }

                        fetch(serverUrl + "/user/set-new-password", {
                            method: "post",
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },


                            //make sure to serialize your JSON body
                            body: JSON.stringify(payload, null, 2)
                        })
                            .then((response) => {
                                if (response.ok) {
                                    setInfoMsg(true);
                                } else {
                                    throw new Error("Problem s nastavením nového hesla.");
                                }
                            })
                            .catch(reason => {
                                setErrorMsg(true);
                            });

                        resetForm({})
                    }, 500);
                }}
            >
                {({submitForm, isSubmitting}) => (
                    // @ts-ignore
                    <Form className={classes.form}>
                        <Field
                            component={TextField}
                            name="password"
                            type="password"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            label="Heslo"
                            autoFocus
                        />
                        <Field
                            component={TextField}
                            name="againPassword"
                            type="password"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="againPassword"
                            label="Heslo znovu"
                        />
                        <br/>
                        {isSubmitting && <LinearProgress/>}
                        <br/>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                            onClick={submitForm}
                            fullWidth
                            className={classes.submit}
                        >
                            Nastavit nové heslo.
                        </Button>
                    </Form>
                )}
            </Formik>
        );
    };
    return (
        <>
            <Navbar/>
            <Container component="main" maxWidth="xs" >
                <CssBaseline/>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    {
                        initError ?
                            <Alert severity="error">Platnost odkazu vypršela. Prosím opakujte akci za chvíli nebo
                                nás kontaktujte.</Alert>
                            : <>
                                <Typography component="h1" variant="h5">
                                    Zadejte nové heslo
                                </Typography>
                                {
                                    errorMsg &&
                                    <Alert severity="error">Problém s nastavením nového hesla. Prosím opakujte akci za
                                        chvíli nebo
                                        nás kontaktujte.</Alert>
                                }
                                {
                                    infoMsg &&
                                    <Alert severity="success">Heslo bylo úspěšně změněno. Nyní se můžete přihlásit.</Alert>
                                }
                                {
                                    resetPwdForm()
                                }
                                <Grid container>
                                    <Grid item xs>
                                        <Link to="/prihlasit" style={{textDecoration: "none", fontSize: 16}}>
                                            Přihlásit
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link to="/registrace" style={{textDecoration: "none", fontSize: 16}}>
                                            {"Nemáte účet? Zaregistrujte se"}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </>
                    }
                </div>
            </Container>
            <Divider/>
            <Footer/>
        </>
    );

}