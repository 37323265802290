import React from "react";
import {Card, CardContent, Grid, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {serverUrl} from "../../../index";
import HeaderTitle from "../../common/headerTitle";
import {Link} from "react-router-dom";

interface ClubTeam {
    teamName: string,
    leagueName: string,
    id: number,
    nameUrl: string
}

interface TypeTeams {
    [key: string]: ClubTeam[]
}

export default function Teams({clubId}: { clubId: number }) {

    const [teams, setTeams] = React.useState<TypeTeams>({});

    React.useEffect(() => {
        fetch(serverUrl + '/club/' + clubId + '/teams')
            .then(res => res.json())
            .then((result) => {
                setTeams(result);
            })
            .catch(
                console.log
            )
    }, [clubId]);

    return (
        <Box style={{minHeight: 500}}>
            <HeaderTitle title={"Týmy"}/>
            {
                Object.keys(teams).map((teamType) => (
                    <>
                        <Grid container style={{paddingBottom: 10}}>
                            <Grid item sm={12}>
                                <Typography style={{paddingTop: 20, paddingLeft: 20, paddingBottom: 10, color: "#303030", fontSize: 16}}
                                            variant={"body1"}
                                            color={"textSecondary"}>{teamType}</Typography>
                            </Grid>
                            <Grid item container spacing={1} style={{paddingLeft: 10, paddingRight: 10}}>
                                {
                                    teams[teamType].map(team => (
                                        <Grid item sm={3} xs={12}>
                                            <Link to={"/tym/" + team.nameUrl + "/" + team.id}
                                                  style={{textDecoration: "none"}}>
                                                <Card variant="outlined">
                                                    <CardContent>
                                                        <Typography style={{color: "#008B8B"}}
                                                                    variant={"h6"}
                                                                    gutterBottom>{team.teamName}</Typography>
                                                        <Typography variant="body1" color="textSecondary"
                                                                    component="div">
                                                            {team.leagueName}
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Link>

                                        </Grid>
                                    ))
                                }
                            </Grid>

                        </Grid>
                    </>
                ))
            }
        </Box>
    );


}