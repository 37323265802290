import React from "react"
import {Menu, MenuContent} from "../../../common/content/content";
import {useOutletContext} from "react-router-dom";
import {ClubSubPageContext, getMenuItems} from "../../club";
import Teams from "../teams";
import DognetAd, {AdType} from "../../../common/ads/dognetAd";
import Ad from "../../../common/ads/ad";


export default function ClubTeams() {
    const context = useOutletContext<ClubSubPageContext>();

    return (
        <>
            <Menu menuTabs={getMenuItems(context.clubName, context.clubId)} selectedTab={2}/>
            <MenuContent tabId={2} content={
                <>
                    <Teams clubId={context.clubId}/>
                    {/*<DognetAd marginY={3} webAdType={AdType.HORIZONTAL} mobileAdType={AdType.CUBE}/>*/}
                    <Ad ad={<ins className="adsbygoogle"
                                 style={{display:"block"}}
                                 data-ad-client="ca-pub-7325589669239459"
                                 data-ad-slot="2948634607"
                                 data-ad-format="auto"
                                 data-full-width-responsive="true"></ins>}
                        height={100} marginY={3}
                    />
                </>
            }/>
        </>
    )
}