import React from 'react'
import {useOutletContext} from "react-router-dom";
import {TeamSubPageContext} from "../../team";
import {Menu} from "../../../common/content/content";
import {MenuContent3} from "../../../common/content/content3";
import Players from "../../../common/players/players";
import DognetAd, {AdType} from "../../../common/ads/dognetAd";
import Ad from "../../../common/ads/ad";

export default function TeamPlayers() {
    const context = useOutletContext<TeamSubPageContext>();

    return (
        <>
            <Menu menuTabs={context.menuTabs} selectedTab={3}/>
            <MenuContent3 tabId={3} content={
                <>
                    <Players teamId={context.teamId}/>
                    {/*<DognetAd marginY={3} webAdType={AdType.HORIZONTAL} mobileAdType={AdType.CUBE}/>*/}
                    <Ad ad={<ins className="adsbygoogle"
                                 style={{display:"block"}}
                                 data-ad-client="ca-pub-7325589669239459"
                                 data-ad-slot="2653121138"
                                 data-ad-format="auto"
                                 data-full-width-responsive="true"></ins>}
                        height={100} marginY={3}
                    />
                </>

            }/>
        </>
    )
}