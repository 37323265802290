import React from 'react'
import {useOutletContext} from "react-router-dom";
import {TeamSubPageContext} from "../../team";
import {MenuContent2} from "../../../common/content/content2";
import {Menu} from "../../../common/content/content";
import LastMatch from "./lastMatch";
import News from "./news";
import BestShooters from "./bestShooters";
import SimpleTable from "./simpletable";
import Box from "@material-ui/core/Box";
import DognetAd, {AdType} from "../../../common/ads/dognetAd";
import Ad from "../../../common/ads/ad";

export default function TeamHome() {
    const context = useOutletContext<TeamSubPageContext>();

    return (
        <>
            <Menu menuTabs={context.menuTabs} selectedTab={0}/>
            <MenuContent2 tabId={0} contentLeft={
                <>
                    <LastMatch teamId={context.teamId}/>
                    {/*<DognetAd marginY={3} webAdType={AdType.HORIZONTAL} mobileAdType={AdType.CUBE}/>*/}
                    <Ad ad={<ins className="adsbygoogle"
                                 style={{display:"block"}}
                                 data-ad-client="ca-pub-7325589669239459"
                                 data-ad-slot="2520849845"
                                 data-ad-format="auto"
                                 data-full-width-responsive="true"></ins>} height={100} marginY={3}/>
                    <News/>
                    <BestShooters teamId={context.teamId} teamName={context.teamName}/>
                    <Ad ad={<ins className="adsbygoogle"
                                 style={{display:"block"}}
                                 data-ad-client="ca-pub-7325589669239459"
                                 data-ad-slot="9053043800"
                                 data-ad-format="auto"
                                 data-full-width-responsive="true"></ins>}
                        height={100} marginY={3}
                    />
                </>
            } contentRight={
                <>
                    <SimpleTable teamId={context.teamId} teamName={context.teamName}/>
                    {/*<DognetAd marginY={3} webAdType={AdType.CUBE} mobileAdType={AdType.CUBE}/>*/}
                    <Ad ad={<ins className="adsbygoogle"
                                 style={{display:"block"}}
                                 data-ad-client="ca-pub-7325589669239459"
                                 data-ad-slot="9925322096"
                                 data-ad-format="auto"
                                 data-full-width-responsive="true"></ins>}
                        height={300} marginY={3}
                    />

                </>

            } leftSize={9} rightSize={3}/>
        </>
    )
}