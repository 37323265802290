import React from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, TextField} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {serverUrl} from "../../index";

export enum CollabTypeE {AD, PROFIL, OTHER}

function getText(collabType: CollabTypeE, textType: string): string {

    switch (collabType) {
        case CollabTypeE.AD:
            if (textType === "title") {
                return "Reklamy na kdesehraje.cz";
            } else if (textType === "text") {
                return "Děkujeme za zájem o reklamní spolupráci na portálu kdesehraje.cz. Zadáním emailu a " +
                    "popř. dalších doplňujících informací ohledně spolupráce odešlete nezávazný požadavek. " +
                    "Po odeslání požadavku se Vám obratem ozveme na zadanou emailovou adresu. ";
            }
            break;
        case CollabTypeE.PROFIL:
            if (textType === "title") {
                return "Správa profilů klubu nebo týmu";
            } else if (textType === "text") {
                return "Děkujeme za zájem o správu profilu klubu nebo týmu. Zadáním emailu a " +
                    "popř. dalších doplňujících informací, jako je název klubu, odešlete nezávazný požadavek. " +
                    "Po odeslání požadavku se Vám obratem ozveme na zadanou emailovou adresu. ";
            }
            break;
        case CollabTypeE.OTHER:
            if (textType === "title") {
                return "Ostatní spolupráce"
            } else if (textType === "text") {
                return "Děkujeme za zájem o spolupráci na portálu kdesehraje.cz. Zadáním emailu a " +
                    "popř. dalších doplňujících informací ohledně spolupráce odešlete nezávazný požadavek. " +
                    "Po odeslání požadavku se Vám obratem ozveme na zadanou emailovou adresu. ";
            }
            break;
    }

    return "";
}


interface InterestData {
    email: string,
    text: string,
}

export default function CollaborationInterest({collabType}: { collabType: CollabTypeE }) {
    const [open, setOpen] = React.useState(false);
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [data, setData] = React.useState<InterestData>({email: "", text: ""});


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenSnackBar = () => {
        setOpenSnackBar(true);
    };

    const handleCloseSnackBar = () => {
        setOpenSnackBar(false);
    };

    const handleSubmit = () => {
        setOpen(false);

        if (data.email === "") {
            setOpen(false);
            return;
        }

        fetch(serverUrl + "/feedback/add", {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },

            //make sure to serialize your JSON body
            body: JSON.stringify({"text": getText(collabType, "title") + ": " + data.text, "email": data.email}, null, 2)
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Problém s odesláním zprávy.");
                }
            }).then(data => {
            setOpen(false);
            handleOpenSnackBar()
        })
            .catch(reason => {
                alert("Omlouváme se, ale nastal problém se odesíláním formuláře." + reason)
            });
    }


    const handleEmailChange = (event: React.ChangeEvent<{ value: unknown }>) => {

        let insertedValue: string = event.target.value as string
        setData(prevState => ({
                ...prevState,
                email: insertedValue
            })
        )
    }


    const handleTextChange = (event: React.ChangeEvent<{ value: unknown }>) => {

        let insertedValue: string = event.target.value as string
        setData(prevState => ({
                ...prevState,
                text: insertedValue
            })
        )
    }

    return (
        <>
            <Button variant="outlined" style={{color: "red", marginTop: 20}} onClick={handleClickOpen}>
                Mám zájem o spolupráci
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{getText(collabType, "title")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {getText(collabType, "text")}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Emailová adresa *"
                        type="email"
                        fullWidth
                        onChange={(e) => handleEmailChange(e)}
                    />
                    <Typography style={{marginTop: 20, marginBottom: 10, color: "grey", fontSize: 14}}>
                        Detaily spolupráce
                    </Typography>
                    <TextareaAutosize aria-label="minimum height" rowsMin={6} style={{width: "100%"}}
                                      onChange={(e) => handleTextChange(e)}/>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Zrušit
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Odeslat
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={openSnackBar}
                autoHideDuration={6000}
                onClose={handleCloseSnackBar}
                message="Děkujeme za odeslání požadavku na spolupráci."
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackBar}>
                            <CloseIcon fontSize="small"/>
                        </IconButton>
                    </React.Fragment>
                }
            />
        </>
    );
}