import React, {useEffect} from "react";
import {UserContext} from "./userContext";

const defaultUser = {
    user: {
        nickname: "nickname",
        firstName: "firstName",
        lastName: "lastName",
        email: "email@email.cz"
    },
    logged: false,
    token: "",
    expireAt: undefined,
};

export default function UserContextProvider({children}) {

    const [userData, setUserData] = React.useState(defaultUser);

    useEffect(() => {
        const savedData = localStorage.getItem("user");

        if (savedData !== null) {
            setUserData(JSON.parse(savedData));
        }
    }, []);


    return (
        <UserContext.Provider
            value={{
                userData: userData,
                setLoggedUser: (newLoggedUser) => {

                    let token = newLoggedUser.token;
                    let expiryAt = newLoggedUser.expiryDate;

                    delete newLoggedUser.token;
                    delete newLoggedUser.expiryDate;

                    const newState = {
                        user: newLoggedUser,
                        logged: true,
                        token: token,
                        expireAt: expiryAt
                    };

                    localStorage.setItem("user", JSON.stringify(newState, null, 2))
                    setUserData(newState)
                },
                logoutUser: () => {
                    const newState = {
                        logged: false,
                        token: null,
                        user: {},
                        expireAt: null
                    };

                    localStorage.setItem("user", JSON.stringify(newState, null, 2))
                    setUserData(newState);
                },
                isLogged: () => {
                    return userData.logged;
                }
            }}
        >
            {children}
        </UserContext.Provider>
    );
}

UserContextProvider.context = UserContext;