import React from 'react'
import {Menu} from "../../../common/content/content";
import {useOutletContext} from "react-router-dom";
import {LeagueSubPageContext} from "../../league";
import {MenuContent2} from "../../../common/content/content2";
import BestShooters from "./bestShooters";
import SimpleTable from "./simpletable";
import Top from "./top";
import Box from "@material-ui/core/Box";
import DognetAd, {AdType} from "../../../common/ads/dognetAd";
import Ad from "../../../common/ads/ad";

export default function LeagueHome() {

    const context = useOutletContext<LeagueSubPageContext>();

    return (
        <>
            <Menu menuTabs={context.menuTabs} selectedTab={0}/>
            <MenuContent2 tabId={0} contentLeft={
                <>
                    <Top leagueId={context.leagueId}/>
                    {/*<DognetAd marginY={3} webAdType={AdType.HORIZONTAL} mobileAdType={AdType.CUBE}/>*/}
                    <Ad ad={<ins className="adsbygoogle"
                                 style={{display:"block"}}
                                 data-ad-client="ca-pub-7325589669239459"
                                 data-ad-slot="2653121138"
                                 data-ad-format="auto"
                                 data-full-width-responsive="true"></ins>}
                        height={100} marginY={3}
                    />
                    {/*<News/>*/}
                    <BestShooters leagueId={context.leagueId} leagueName={context.leagueName}/>
                </>
            } contentRight={
                <>
                    <SimpleTable leagueId={context.leagueId}/>
                    <Ad ad={<ins className="adsbygoogle"
                                 style={{display:"block"}}
                                 data-ad-client="ca-pub-7325589669239459"
                                 data-ad-slot="9925322096"
                                 data-ad-format="auto"
                                 data-full-width-responsive="true"></ins>}
                        height={300} marginY={3}
                    />
                    {/*<DognetAd marginY={3} webAdType={AdType.CUBE} mobileAdType={AdType.CUBE}/>*/}
                </>
            } leftSize={8} rightSize={4}/>
        </>
    );
}