import React from "react";
import {Box, Container, createStyles, Theme} from "@material-ui/core";
import HeaderHelmet from "../header/HeaderHelmet";
import Navbar from "../navbar/Navbar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import ReactGA from "react-ga";
import Divider from "@material-ui/core/Divider";
import Footer from "../common/footer/footer";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerTitle: {
            fontWeight: "bold",
            color: "#008BBB"
        },
        itemLink: {
            color: "black"
        },
        itemLinkFirst: {
            color: "black",
            fontWeight: "bold"
        }
    }),
);


export default function HeroesInLowerLeagues() {
    const classes = useStyles();

    React.useEffect(() => {
        ReactGA.initialize("UA-116060364-1");
        ReactGA.pageview(window.location.pathname);
        
    }, []);

    return (
        <Box>
            <HeaderHelmet title={"Hrdinové v nižšísch soutěžích"}
                          description={"Ligový hráči, kteří pokračují ve své aktivní kariéře v amaterských nižších soutěžích."}/>
            <Navbar/>

            <Container style={{paddingBottom: 30}}>
                <Typography variant={"h3"} component={"h1"} align={"center"}
                            style={{paddingTop: 40, color: "rgb(64, 64, 64)", paddingBottom: 30}}>
                    Hrdinové v nižších soutěžích
                </Typography>
                <Typography align={"justify"} component={"p"} variant={"body1"}>
                    Hráči, kteří dříve hrávali fotbal jako profesionálové a nyní hrají za týmy nižších soutěží.
                    Podívejte se, v jakém týmu hrají a kde se na ně můžete dojít podívat.
                </Typography>
                <Grid container style={{paddingTop: 40}}>
                    <Grid container xs={12}>
                        <Grid item xs={4}><Typography className={classes.headerTitle}
                                                      variant={"button"}>Hráč</Typography></Grid>
                        <Grid item xs={4}><Typography className={classes.headerTitle}
                                                      variant={"button"}>Tým</Typography></Grid>
                        <Grid item xs={4}><Typography className={classes.headerTitle}
                                                      variant={"button"}>Zápasy</Typography></Grid>
                    </Grid>
                    <Grid container xs={12}
                          style={{borderTop: "1px dashed lightgray", paddingTop: 10, paddingBottom: 10}}>
                        <Grid item xs={4}>
                            <Typography variant={"button"} component={"p"}>
                                <Link to={"/hrac/milan-baros/1140"} target={"_blank"} className={classes.itemLinkFirst}>
                                    Milan Baroš
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant={"button"} component={"p"}>
                                <Link to={"/tym/vigantice/11167"} target={"_blank"} className={classes.itemLink}>
                                    Vigantice
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant={"button"} component={"p"}>
                                <Link to={"/tym/vigantice/11167/zapasy"} target={"_blank"} className={classes.itemLink}>
                                    Další zápasy
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container xs={12}
                          style={{borderTop: "1px dashed lightgray", paddingTop: 10, paddingBottom: 10}}>
                        <Grid item xs={4}>
                            <Typography variant={"button"} component={"p"}>
                                <Link to={"/hrac/rene-bolf/218287"} target={"_blank"} className={classes.itemLinkFirst}>
                                    René Bolf
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant={"button"} component={"p"}>
                                <Link to={"/tym/vigantice/11167"} target={"_blank"} className={classes.itemLink}>
                                    Vigantice
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant={"button"} component={"p"}>
                                <Link to={"/tym/vigantice/11167/zapasy"} target={"_blank"} className={classes.itemLink}>
                                    Další zápasy
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container xs={12}
                          style={{borderTop: "1px dashed lightgray", paddingTop: 10, paddingBottom: 10}}>
                        <Grid item xs={4}>
                            <Typography variant={"button"} component={"p"}>
                                <Link to={"/hrac/pavel-nedved/189960"} target={"_blank"}
                                      className={classes.itemLinkFirst}>
                                    Pavel Nedvěd
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant={"button"} component={"p"}>
                                <Link to={"/tym/skalna/5533"} target={"_blank"} className={classes.itemLink}>
                                    Skalná
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant={"button"} component={"p"}>
                                <Link to={"/tym/skalna/5533/zapasy"} target={"_blank"} className={classes.itemLink}>
                                    Další zápasy
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container xs={12}
                          style={{borderTop: "1px dashed lightgray", paddingTop: 10, paddingBottom: 10}}>
                        <Grid item xs={4}>
                            <Typography variant={"button"} component={"p"}>
                                <Link to={"/hrac/jan-koller/186955"} target={"_blank"}
                                      className={classes.itemLinkFirst}>
                                    Jan Koller
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant={"button"} component={"p"}>
                                <Link to={"/tym/smetanova-lhota/20936"} target={"_blank"} className={classes.itemLink}>
                                    Smetanova Lhota
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant={"button"} component={"p"}>
                                <Link to={"/tym/smetanova-lhota/20936/zapasy"} target={"_blank"}
                                      className={classes.itemLink}>
                                    Další zápasy
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container xs={12}
                          style={{borderTop: "1px dashed lightgray", paddingTop: 10, paddingBottom: 10}}>
                        <Grid item xs={4}>
                            <Typography variant={"button"} component={"p"}>
                                <Link to={"/hrac/vladimir-smicer/16585"} target={"_blank"}
                                      className={classes.itemLinkFirst}>
                                    Vladimír Šmicer
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant={"button"} component={"p"}>
                                <Link to={"/tym/sk-dolni-chabry/824"} target={"_blank"} className={classes.itemLink}>
                                    Sk Dolní Chabry
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant={"button"} component={"p"}>
                                <Link to={"/tym/sk-dolni-chabry/824/zapasy"} target={"_blank"}
                                      className={classes.itemLink}>
                                    Další zápasy
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container xs={12}
                          style={{borderTop: "1px dashed lightgray", paddingTop: 10, paddingBottom: 10}}>
                        <Grid item xs={4}>
                            <Typography variant={"button"} component={"p"}>
                                <Link to={"/hrac/marek-heinz/139434"} target={"_blank"}
                                      className={classes.itemLinkFirst}>
                                    Marek Heinz
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant={"button"} component={"p"}>
                                <Link to={"/tym/vsechovice/19046"} target={"_blank"} className={classes.itemLink}>
                                    Všechovice
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant={"button"} component={"p"}>
                                <Link to={"/tym/vsechovice/19046/zapasy"} target={"_blank"}
                                      className={classes.itemLink}>
                                    Další zápasy
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container xs={12}
                          style={{borderTop: "1px dashed lightgray", paddingTop: 10, paddingBottom: 10}}>
                        <Grid item xs={4}>
                            <Typography variant={"button"} component={"p"}>
                                <Link to={"/hrac/tomas-rosicky/45029"} target={"_blank"}
                                      className={classes.itemLinkFirst}>
                                    Tomáš Rosický
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant={"button"} component={"p"}>
                                <Link to={"/tym/sk-sestajovice/20453"} target={"_blank"} className={classes.itemLink}>
                                    SK Šestajovice
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant={"button"} component={"p"}>
                                <Link to={"/tym/sk-sestajovice/20453/zapasy"} target={"_blank"}
                                      className={classes.itemLink}>
                                    Další zápasy
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container xs={12}
                          style={{borderTop: "1px dashed lightgray", paddingTop: 10, paddingBottom: 10}}>
                        <Grid item xs={4}>
                            <Typography variant={"button"} component={"p"}>
                                <Link to={"/hrac/jaromir-blazek/14571"} target={"_blank"}
                                      className={classes.itemLinkFirst}>
                                    Jaromír Blažek
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant={"button"} component={"p"}>
                                <Link to={"/tym/fk-ujezd-nad-lesy/19205"} target={"_blank"}
                                      className={classes.itemLink}>
                                    FK Újezd nad Lesy
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant={"button"} component={"p"}>
                                <Link to={"/tym/fk-ujezd-nad-lesy/19205/zapasy"} target={"_blank"}
                                      className={classes.itemLink}>
                                    Další zápasy
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Typography align={"justify"} component={"p"} variant={"body1"} style={{marginTop: 40, paddingBottom: 20}}>
                    Pokud Vás zajímají ostatní zápasy ve Vašem okolí, mrkněte na <Link to={"/mapa"}>interaktivní
                    mapu</Link>, kde jsou zápasy zobrazeny podle polohy. Pokud by jste zde měli nějakého jiného hrdinu,
                    dejte nám vědět <Link to={"/kontakt"}>zde.</Link>
                </Typography>

            </Container>
            <Divider/>
            <Footer/>
        </Box>
    );
}