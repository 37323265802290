import React, {useContext} from "react";
import {Box} from "@material-ui/core";
import {UserContext} from "../../auth/userContext";
import {serverUrl} from "../../../index";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {Link} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import {Team} from "../../team/team";


export default function SimpleDisplayTeams() {
    const {userData, isLogged} = useContext(UserContext);
    const [teams, setTeams] = React.useState<Array<Team>>([]);

    React.useEffect(() => {

        if (!isLogged()) {
            return;
        }

        fetch(serverUrl + '/user/profile/followed/teams',
            {
                method: "GET",
                headers: {
                    "X-Auth": userData.token
                }
            })
            .then(res => res.json() as Promise<Array<Team>>)
            .then((result) => {
                setTeams(result)
            })
            .catch(console.log)
    }, [isLogged]);


    return (
        <Box>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Oblíbené týmy</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            teams.length == 0 ?
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Nemáte v oblíbených žádné týmy. Běžte na profil Vašeho oblíbeného týmu a
                                        klikněte na tlačítko
                                        <Typography display={"inline"}
                                                    style={{fontWeight: "bold"}}> SLEDUJ</Typography> v
                                        pravém horním rohu.
                                    </TableCell>
                                </TableRow>
                                :
                                teams.map((team) => (
                                    <TableRow key={team.id}>
                                        <TableCell component="th" scope="row">
                                            <Link target={"_blank"}
                                                  to={"/tym/" + team.nameUrl + "/" + team.id}> {team.name}</Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </Box>
    )
}