import React, {useEffect, useState} from "react";
import Box from "@material-ui/core/Box";
import {Hidden, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {Team} from "../../team/team";
import {League} from "../../league/league";
import camelcaseKeys from "camelcase-keys";
import LaunchIcon from '@material-ui/icons/Launch';
import MatchEvents, {EventType, MatchEvent} from "./matchEvents";
import HeaderTitle from "../headerTitle";
import {Player} from "../../player/player";
import "./matches.css"
import {noLogo, serverUrl} from "../../../index";
import {isMobile} from "react-device-detect";
import {Link} from "react-router-dom";

export interface MatchPlayer {
    player: Player,
    number: number,
    captain: boolean,
    matchEventsMap?: Map<EventType, number>
}

export enum MatchResultStatus {
    WIN = "WIN",
    WIN_PENALTY = "WIN_PENALTY",
    DRAW = "DRAW",
    LOSE = "LOSE",
    LOSE_PENALTY = "LOSE_PENALTY",
    WIN_CONTUMACY = "WIN_CONTUMACY",
    LOSE_CONTUMACY = "LOSE_CONTUMACY"
}

export interface Match {
    id: number,
    home: Team,
    away: Team,
    league: League,
    homeTeamHalfTimeScore: number,
    awayTeamHalfTimeScore: number,
    homeTeamFullTimeScore: number,
    awayTeamFullTimeScore: number,
    homeTeamFullTimeScoreWithKicks: number,
    awayTeamFullTimeScoreWithKicks: number,
    formattedTime: string,
    formattedDate: string,
    nameUrl: string,
    field: string,
    events: Array<MatchEvent>,
    homeTeamPlayers: Array<MatchPlayer>,
    awayTeamPlayers: Array<MatchPlayer>,
    matchResultStatus?: MatchResultStatus,
    homeTeamKicks: number,
    awayTeamKicks: number,
    enableKicks: boolean,
    mainReferee?: Referee,
    assistants?: Array<Referee>,
    otherReferees?: Array<Referee>,
    contumacyHomeTeamResult: number,
    contumacyAwayTeamResult: number,
    bothSideContumacy: boolean,
    resignation: boolean,
    contumacy: boolean,
    homeTeamRedCards: number,
    awayTeamRedCards: number,
    tvcomVideoCode?:string
}

export interface Referee {
    firstName: string,
    lastName: string
}

export enum MatchesContext {
    TEAM,
    CLUB,
    LEAGUE
}

function getMatchesUrl(context: MatchesContext, id: number, timestamp: number, isNext: boolean, matchId: number): string {
    let contextName = "";
    switch (context) {
        case MatchesContext.CLUB:
            contextName = "club";
            break;
        case MatchesContext.TEAM:
            contextName = "team";
            break;
        case MatchesContext.LEAGUE:
            contextName = "league";
            break;
    }

    const url = serverUrl + "/" + contextName + "/" + id + "/matches?timestamp=" + timestamp + "&isNext=" + isNext + "&matchId=" + matchId;
    return url;
}

export function resolveMatchResultStatus(matchResultStatus: MatchResultStatus) {
    let letter = "";
    let classes = "";
    switch (matchResultStatus) {
        case MatchResultStatus.WIN:
            letter = "V";
            classes = "team-form-win";
            break;
        case MatchResultStatus.WIN_PENALTY:
            letter = "VP";
            classes = "team-form-win-penalty";
            break;
        case MatchResultStatus.WIN_CONTUMACY:
            letter = "VK";
            classes = "team-form-win-contumacy";
            break;
        case MatchResultStatus.DRAW:
            letter = "R";
            classes = "team-form-draw";
            break;
        case MatchResultStatus.LOSE:
            letter = "P";
            classes = "team-form-lose";
            break;
        case MatchResultStatus.LOSE_PENALTY:
            letter = "PP";
            classes = "team-form-lose-penalty";
            break;
        case MatchResultStatus.LOSE_CONTUMACY:
            letter = "PK";
            classes = "team-form-lose-contumacy";
            break;
    }

    return <Typography style={{marginTop: 15, marginLeft: 20}} className={classes}>{letter}</Typography>
}

function resultWinningClass(match: Match, home: boolean) {
    if (match.homeTeamFullTimeScoreWithKicks === undefined || match.awayTeamFullTimeScoreWithKicks === undefined)
        return "textPrimary";

    if (match.homeTeamFullTimeScoreWithKicks > match.awayTeamFullTimeScoreWithKicks) {
        return home ? "textPrimary" : "textSecondary";
    } else if (match.homeTeamFullTimeScoreWithKicks < match.awayTeamFullTimeScoreWithKicks) {
        return home ? "textSecondary" : "textPrimary";
    } else {
        return "textPrimary";
    }
}

interface MatchesData {
    hasPrevious: boolean,
    hasNext: boolean,
    previousTimestamp: number,
    nextTimestamp: number,
    previousId: number,
    nextId: number,
    matches: Array<Match>
}


interface MatchesProps {
    context: MatchesContext,
    id: number
}

const currentDateSecs = new Date().setHours(0, 0, 0, 0).valueOf() / 1000;

function MatchTeamName({
                           name,
                           redCardsCount,
                           winningClass
                       }: { name: string, redCardsCount: number, winningClass: string }) {

    return (
        <>
            {
                // @ts-ignore
                <Typography color={winningClass} display={"inline"} component={"span"}
                            style={{paddingLeft: 10}}>
                    {name}
                </Typography>
            }
            {
                redCardsCount !== undefined && redCardsCount !== 0 &&
                (
                    <>
                        <img style={{display: "inline", paddingLeft: 10, verticalAlign: "middle"}}
                             width={10}
                             height={15}
                             src={"https://www.kdesehraje.cz/media/match-events/red-card.png"}
                             alt={"Červená karta"}/>
                        {
                            redCardsCount > 1 &&
                            (
                                <Typography color={"textSecondary"} component={"span"} display={"inline"}
                                            variant={"caption"}>{" x" + redCardsCount}</Typography>
                            )
                        }
                    </>
                )
            }
        </>
    );
}


export default function Matches({id, context}: MatchesProps) {
    const [matchesData, setMatchesData] = useState<MatchesData>({
        previousTimestamp: currentDateSecs,
        nextTimestamp: currentDateSecs,
        hasPrevious: true,
        hasNext: true,
        previousId: 0,
        nextId: 0,
        matches: []
    });

    const [selectedMatch, setSelectedMatch] = useState<Match | null>(null);

    // @ts-ignore
    const handleClick = (e: any, matchId: number, nameUrl: string) => {
        e.preventDefault();

        if (isMobile) {
            window.open("/zapas/" + nameUrl + "/" + matchId, "_blank");
            return;
        }

        // access to e.target here
        loadMatch(matchId);
    };

    const handleMatchControlClick = (e: any, isNext: boolean) => {
        e.preventDefault();
        loadData(isNext);
    };

    const loadMatch = (matchId: number) => {
        fetch(serverUrl + "/match/" + matchId + "/detail")
            .then(res => res.json())
            .then(result => {
                setSelectedMatch(camelcaseKeys(result, {deep: true}));
            }).catch(console.log);
    };

    const loadData = (isNext: boolean) => {
        let timestamp: number;
        let lastMatchId: number;
        if (isNext) {
            timestamp = matchesData.nextTimestamp;
            lastMatchId = matchesData.nextId;
        } else {
            timestamp = matchesData.previousTimestamp;
            lastMatchId = matchesData.previousId;
        }

        fetch(getMatchesUrl(context, id, timestamp, isNext, lastMatchId))
            .then(res => res.json())
            .then(result => {
                // @ts-ignore
                setMatchesData(camelcaseKeys(result, {deep: true}))
                setSelectedMatch(null);
            })
            .catch(console.log)
    };


    useEffect(() => {
        loadData(true);
    }, []);

    const matchesControl = <Grid container item sm={12} style={{
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        paddingTop: 10,
        paddingBottom: 10,
        backgroundColor: "#f2f4f5"
    }}>
        <Grid item xs={6}>
            <Typography color={"textPrimary"} variant={"subtitle1"} align={"left"}
                        style={{paddingLeft: 20, textDecoration: "none"}}>

                <Link to="#" onClick={(e: any) => handleMatchControlClick(e, false)} style={{textDecoration: "none"}}>
                    {matchesData.hasPrevious ? "« Předchozí" : " "}
                </Link>
            </Typography>
        </Grid>
        <Grid item xs={6}>
            <Typography color={"textPrimary"} variant={"subtitle1"} align={"right"}
                        style={{paddingRight: 20}}>
                <Link to="#" onClick={(e: any) => handleMatchControlClick(e, true)} style={{textDecoration: "none"}}>
                    {matchesData.hasNext ? "Následující »" : " "}</Link>
            </Typography>
        </Grid>
    </Grid>;

    const renderMatches = () => {

        let result: any = [];

        for (let match of matchesData.matches) {
            result.push(
                <Grid key={match.id} item container direction={"column"}
                      style={{
                          paddingTop: 10,
                          paddingBottom: 10,
                          borderBottom: "1px dashed rgba(0, 0, 0, 0.12)"
                      }}>
                    <Grid item container style={{marginBottom: 10}}
                          alignItems={"center"}>

                        <Grid item xs={6}>
                            <Link
                                to={"/liga/" + match.league.nameUrl + "/" + match.league.id}
                                style={{
                                    paddingLeft: 10,
                                    color: "#008B8B",
                                    textAlign: "left",
                                    textDecoration: "none"
                                }}>
                                        <span
                                            title={match.league.name}>{match.league.name.length > 30 ? match.league.name.substring(0, 27) + "..." : match.league.name}</span>
                            </Link>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography align={"right"} style={{paddingRight: 10, color: "grey"}}>
                                Hřiště: <span
                                title={match.field}>{match.field.length > 22 ? match.field.substring(0, 19) + "..." : match.field}</span>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container onClick={(e: any) => handleClick(e, match.id, match.nameUrl)}
                          component={"a"}
                          href={"#"}
                          style={{color: "black", textDecoration: "none"}}>
                        <Grid item container xs={12} sm={2} alignItems={"center"} alignContent={"center"}
                            //direction={"column"}
                              style={{borderRight: "1px solid grey", textAlign: "center"}}>
                            <Grid item sm={12} xs={6}>
                                <Typography>{match.formattedDate}</Typography>
                            </Grid>
                            <Grid item sm={12} xs={6}>
                                <Typography>{match.formattedTime}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container xs={9} sm={7} alignItems={"flex-start"} alignContent={"flex-start"}
                              direction={"column"}
                              style={{paddingLeft: 10}}>
                            <Grid item>
                                <MatchTeamName name={match.home.name} redCardsCount={match.homeTeamRedCards}
                                               winningClass={resultWinningClass(match, true)}/>
                            </Grid>
                            <Grid item>
                                <MatchTeamName name={match.away.name} redCardsCount={match.awayTeamRedCards}
                                               winningClass={resultWinningClass(match, false)}/>
                            </Grid>
                        </Grid>
                        <Grid item xs={1} container direction={"column"} alignItems={"center"}
                              alignContent={"center"}>
                            <Grid item>
                                <Typography color={resultWinningClass(match, true)}>
                                    {(match.homeTeamFullTimeScoreWithKicks !== undefined ? match.homeTeamFullTimeScoreWithKicks : "") + (match.homeTeamKicks > match.awayTeamKicks ? "p" : "")}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    color={resultWinningClass(match, false)}>
                                    {(match.awayTeamFullTimeScoreWithKicks !== undefined ? match.awayTeamFullTimeScoreWithKicks : "") + (match.awayTeamKicks > match.homeTeamKicks ? "p" : "")}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item container xs={1} direction={"column"} alignItems={"center"}
                              alignContent={"center"}
                              style={{borderLeft: match.matchResultStatus !== undefined ? "1px solid rgba(0, 0, 0, 0.24)" : ""}}>
                            <Grid item>
                                <Typography>{match.matchResultStatus !== undefined ? resolveMatchResultStatus(match.matchResultStatus) : ""}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )
        }

        return result;
    }

    return (
        <Box style={{minHeight: 500, paddingBottom: 40, backgroundColor: "white"}}>
            <HeaderTitle title={"Zápasy"}/>
            <Grid container
                  style={{
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                      borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
                  }}>
                <Grid container item sm={6} style={{borderRight: "1px solid rgba(0, 0, 0, 0.12)",}}>
                    {matchesControl}
                    {renderMatches()}
                </Grid>
                <Hidden xsDown>
                    <Grid item sm={6}
                          style={{
                              borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                              height: "100%",
                              overflowY: "auto",
                              minHeight: 500,
                              maxHeight: 570
                          }}>
                        {selectedMatch !== null &&
                        (<Box>
                            <Grid container>
                                <Grid style={{backgroundColor: "#f1f2f5"}} item sm={12}><Link target={"_blank"}
                                                                                              to={"/zapas/" + selectedMatch.nameUrl + "/" + selectedMatch.id}
                                                                                              style={{
                                                                                                  textDecoration: "none",
                                                                                                  color: "black"
                                                                                              }}>
                                    <Typography><LaunchIcon
                                        fontSize={"small"} style={{verticalAlign: "middle"}}/> Detail
                                        zápasu</Typography></Link> </Grid>
                                <Grid container item sm={12} alignItems={"center"} alignContent={"center"}
                                      style={{
                                          paddingTop: 20,
                                          paddingBottom: 20,
                                          backgroundColor: "#f1f2f5",
                                          borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
                                      }}>
                                    <Grid container item alignItems={"center"} alignContent={"center"}
                                          direction="column" sm={4}>
                                        <Grid item sm={12}>
                                            <img width={50} height={50}
                                                 src={selectedMatch.home.logo !== undefined ? selectedMatch.home.logo : noLogo}
                                                 alt={"Logo domácího týmu."}/>
                                        </Grid>
                                        <Grid item sm={12}>
                                            <Link style={{color: "#008B8B", textDecoration: "none"}}
                                                  to={"/tym/" + selectedMatch.home.nameUrl + "/" + selectedMatch.home.id}>{selectedMatch.home.name}</Link>
                                        </Grid>
                                    </Grid>
                                    <Grid container item sm={4} direction="column" alignItems={"center"}
                                          alignContent={"center"}>
                                        <Grid item sm={12}>
                                            {
                                                selectedMatch.homeTeamHalfTimeScore !== -1 ?
                                                    (
                                                        <React.Fragment>
                                                            <Typography variant={"h5"} display={"inline"}
                                                                        color={resultWinningClass(selectedMatch, true)}>
                                                                {selectedMatch.homeTeamFullTimeScoreWithKicks}
                                                            </Typography>
                                                            <Typography variant={"h5"}
                                                                        display={"inline"}> : </Typography>
                                                            <Typography variant={"h5"} display={"inline"}
                                                                        color={resultWinningClass(selectedMatch, false)}>{selectedMatch.awayTeamFullTimeScoreWithKicks}</Typography>
                                                        </React.Fragment>
                                                    )
                                                    :
                                                    (<Typography
                                                        variant={"h6"}>{selectedMatch.formattedDate}</Typography>)
                                            }
                                        </Grid>
                                        <Grid item sm={12}>
                                            {
                                                selectedMatch.homeTeamHalfTimeScore !== -1 ?
                                                    (
                                                        <Typography color={"textSecondary"}
                                                                    variant={"body2"}>{"( " + selectedMatch.homeTeamHalfTimeScore + " : " + selectedMatch.awayTeamHalfTimeScore + " )"}</Typography>
                                                    )
                                                    :
                                                    (
                                                        <Typography variant={"h6"}
                                                                    color={"textSecondary"}>{selectedMatch.formattedTime}</Typography>
                                                    )
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid container item sm={4} direction="column" alignItems={"center"}
                                          alignContent={"center"}>
                                        <Grid item sm={12}>
                                            <img width={50} height={50}
                                                 src={selectedMatch.away.logo !== undefined ? selectedMatch.away.logo : noLogo}
                                                 alt={"Logo domácího týmu."}/>
                                        </Grid>
                                        <Grid item sm={12}>
                                            <Link style={{color: "#008B8B", textDecoration: "none"}}
                                                  to={"/tym/" + selectedMatch.away.nameUrl + "/" + selectedMatch.away.id}>{selectedMatch.away.name}</Link>
                                        </Grid>
                                    </Grid>
                                    {selectedMatch.enableKicks && selectedMatch.homeTeamKicks !== selectedMatch.awayTeamKicks && (
                                        <Grid item sm={12} style={{paddingLeft: 15, paddingTop: 20}}>
                                            <Typography
                                                style={{color: "blue"}}> Penalty: {selectedMatch.homeTeamKicks + " : " + selectedMatch.awayTeamKicks}</Typography>
                                        </Grid>
                                    )
                                    }
                                </Grid>
                                <MatchEvents matchId={selectedMatch.id} events={selectedMatch.events}
                                             homeTeamId={selectedMatch.home.id}
                                             halfScoreHome={selectedMatch.homeTeamHalfTimeScore}
                                             halfScoreAway={selectedMatch.awayTeamHalfTimeScore}/>
                            </Grid>
                        </Box>)
                        }
                    </Grid>
                </Hidden>
            </Grid>
        </Box>
    );
}