import React from "react";
import {Box, Divider, Typography} from "@material-ui/core";


export default function HeaderTitle({title, color, beta = false}: { title: string, color?: string, beta?: boolean }) {

    if (color === undefined) {
        color = "#38a9ff";
    }
    return (
        <>
            <Box style={{borderLeft: "8px solid " + color, borderRadius: "3px 0 0 0", height: 70, width: "100%"}}>
                <Box
                    style={{height: 40, margin: "0 auto 0 auto", paddingLeft: 20, paddingBottom: 10, paddingTop: 15}}>
                    <Typography align={"left"} variant={"h4"} style={{
                        paddingTop: 15,
                        fontSize: 16,
                        textTransform: "uppercase",
                        color: "black",
                        fontWeight: 600,
                        fontFamily: "Montserrat,sans-serif"
                    }}>
                        {title}
                        {beta &&
                        <Typography component={"span"} variant={"body1"} style={{color: "green"}}> [ BETA ]</Typography>}
                    </Typography>
                </Box>
            </Box>
            <Divider/>
        </>
    );
}