import React from 'react'
import {Menu} from "../../../common/content/content";
import {MenuContent2} from "../../../common/content/content2";
import LastMatches from "./lastMatches";
import News from "./news";
import SocMedia from "./socMedia";
import AboutClub from "./aboutClub";
import {useOutletContext} from "react-router-dom";
import {ClubSubPageContext, getMenuItems} from "../../club";
import Ad from "../../../common/ads/ad";


export default function ClubHome() {

    const context = useOutletContext<ClubSubPageContext>();

    return (
        <>
            <Menu menuTabs={getMenuItems(context.clubName, context.clubId)} selectedTab={0}/>
            <MenuContent2 tabId={0} contentLeft={
                <>
                    <LastMatches clubId={context.clubId} clubNameUrl={context.clubName}/>
                    <Ad ad={<ins className="adsbygoogle"
                                 style={{display:"block"}}
                                 data-ad-client="ca-pub-7325589669239459"
                                 data-ad-slot="9053043800"
                                 data-ad-format="auto"
                                 data-full-width-responsive="true"></ins>}
                    height={100} marginY={3}
                    />

                    {/*<DognetAd marginY={3} webAdType={AdType.HORIZONTAL} mobileAdType={AdType.CUBE}/>*/}
                    <News/>
                </>
            } contentRight={
                <>
                    <SocMedia clubId={context.clubId}/>
                    <AboutClub clubId={context.clubId}/>

                    <Ad ad={<ins className="adsbygoogle"
                                 style={{display:"block"}}
                                 data-ad-client="ca-pub-7325589669239459"
                                 data-ad-slot="9925322096"
                                 data-ad-format="auto"
                                 data-full-width-responsive="true"></ins>}
                        height={300} marginY={3}
                    />
                    {/*<DognetAd marginY={3} webAdType={AdType.CUBE} mobileAdType={AdType.CUBE}/>*/}
                </>
            } leftSize={9} rightSize={3}/>
        </>
    );
}