import React from "react";
import {noLogo, serverUrl} from "../../../../index";
import {Grid, TableBody, TableHead, TableRow} from "@material-ui/core";
import {Link} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import {makeStyles} from "@material-ui/core/styles";
import {Match} from "../matches";
import {EventType, getMatchEventIcon} from "../matchEvents";
import {Skeleton} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 8
    }
}));

interface TopMatch {
    eventCount: number,
    match: Match
}

export default function TopMatches({statsType, leagueId}: { statsType: string, leagueId: number | undefined }) {

    const classes = useStyles();

    const [topMatches, setTopMatches] = React.useState<Array<TopMatch> | null>(null);

    React.useEffect(() => {

        let topMatchesUrl
        if (leagueId === undefined) {
            topMatchesUrl = serverUrl + '/suggest/last-week-top-matches?statsType=' + statsType;
        } else {
            topMatchesUrl = serverUrl + '/suggest/league-season-top-matches?statsType=' + statsType;
            topMatchesUrl += "&leagueId=" + leagueId;
        }

        fetch(topMatchesUrl)
            .then(response => {
                if (!response.ok) {
                    throw new Error("Problém se získáním top zápasů.");
                }
                return response.json() as Promise<Array<TopMatch>>
            })
            .then((result) => {
                setTopMatches(result);
            })
            .catch((error) => {
                setTopMatches([]);
            })
    }, []);

    const getEStatsType = () => {
        if (statsType === "GOALS") {
            return EventType.GOAL;
        } else if (statsType === "YELLOW_CARDS") {
            return EventType.YELLOW_CARD1;
        } else {
            return EventType.RED_CARD;
        }
    }

    const renderSkeleton = () => {

        const result: any = [];
        let iterations: number = leagueId === undefined ? 10 : 3;
        for (let i = 0; i < iterations; i++) {
            result.push(
                <>
                    <Skeleton variant="rect" height={40} style={{marginTop: 10}}/>
                    <Skeleton variant="text"/>
                </>
            )
        }
        return result;
    }

    const renderTable = () => {

        if (topMatches === null) return;

        return <Table aria-label="simple table" style={{padding: 10, border: "1px solid #e0e0e0"}}>
            <TableHead>
                <TableRow>
                    <TableCell className={classes.root}>
                        <Typography variant={"h6"} component={"h2"}
                                    style={{color: "#008e45"}}>Zápasy s nejvíce
                            ... {getMatchEventIcon(getEStatsType())}</Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    topMatches.map((topMatch: TopMatch) => (
                        <TableRow key={topMatch.match.id}>
                            <TableCell className={classes.root} align="left">
                                <Link style={{textDecoration: "none", color: "rgb(30, 65, 100)"}} target={"_blank"}
                                      to={"/zapas/" + topMatch.match.nameUrl + "/" + topMatch.match.id}>
                                    <Grid container>
                                        <Grid item xs={4} style={{textAlign: "right"}}>
                                            <img width={25} height={25} src={topMatch.match.home.logo ?? noLogo}
                                                 style={{
                                                     paddingRight: 10,
                                                     marginRight: 10,
                                                     verticalAlign: "middle"
                                                 }}/>
                                            <Typography component={"span"}
                                                        style={{fontSize: 14}}>{topMatch.match.home.name}</Typography>
                                        </Grid>
                                        <Grid container item xs={4} alignContent={"center"} alignItems={"center"}
                                              justify={"center"} direction={"column"}>
                                            <Grid item>
                                                <Typography component={"span"} style={{
                                                    fontWeight: "bold",
                                                    color: "orange",
                                                    fontSize: 22
                                                }}>{topMatch.eventCount + "x "}</Typography>
                                            </Grid>
                                            {
                                                leagueId === undefined &&
                                                <Grid item component={"span"}>
                                                    <Typography color={"textSecondary"} style={{fontSize: 11}}
                                                                component={"span"}
                                                                variant={"body2"}>{topMatch.match.formattedDate}</Typography>
                                                </Grid>
                                            }
                                        </Grid>
                                        <Grid item xs={4} style={{textAlign: "left"}}>
                                            <Typography component={"span"}
                                                        style={{fontSize: 14}}>{topMatch.match.away.name}</Typography>
                                            <img width={25} height={25} src={topMatch.match.away.logo ?? noLogo}
                                                 style={{
                                                     paddingLeft: 10,
                                                     marginLeft: 10,
                                                     verticalAlign: "middle"
                                                 }}/>
                                        </Grid>
                                    </Grid>
                                </Link>
                            </TableCell>
                        </TableRow>
                    ))
                }
            </TableBody>
        </Table>
    }

    return (
        <Grid>
            {topMatches === null ? renderSkeleton() : renderTable()}
        </Grid>
    );
}