import React, {useEffect, useState} from "react";
import {
    Box,
    FormControlLabel,
    FormGroup,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import {noLogo, serverUrl} from "../../../index";
import {League} from "../../league/league";
import {Team} from "../../team/team";
import {MatchResultStatus, resolveMatchResultStatus} from "../matches/matches";
import HeaderTitle from "../headerTitle";
import {isMobile} from "react-device-detect";
import TableContainer from "@material-ui/core/TableContainer";
import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 2
    }
}));


interface TeamForm {
    matchStatus: MatchResultStatus,
    url: string,
    title: string
}

export interface LeagueTableRow {
    team: Team,
    position: number,
    matchTotal: number,
    shotGoals: number,
    receivedGoals: number,
    winCount: number,
    winKicksCount: number,
    lostCount: number,
    lostKicksCount: number,
    drawCount: number,
    points: number,
    logoCrop: string,
    teamForms: Array<TeamForm>
}

export interface TableWrapper {
    league: League,
    year: number,
    enabledKicks: boolean,
    leagueTables: Array<LeagueTableRow>
}

const TableWrapperDefault: TableWrapper = {
    league: {id: 0, nameUrl: "", name: ""},
    year: 2019,
    enabledKicks: false,
    leagueTables: []
};

function prepareUrl(leagueId?: number, teamId?: number) {

    if (leagueId != undefined) {
        return "/league/" + leagueId + "/table";
    } else {
        return "/team/" + teamId + "/table";
    }
}

export default function LeagueTable({leagueId, teamId, homeTeamId, awayTeamId}: { leagueId?: number, teamId?: number, homeTeamId?: number, awayTeamId?: number }) {

    const classes = useStyles();

    const [leagueTable, setLeagueTable] = useState<TableWrapper>(TableWrapperDefault);
    const [useForm, setUseForm] = useState<boolean>(false);

    // @ts-ignore
    const handleChange = event => {
        setUseForm(event.target.checked);
    };

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        fetch(serverUrl + prepareUrl(leagueId, teamId))
            .then(res => res.json())
            .then(result => {
                setLeagueTable(result);
            })
            .catch(console.log)
    }, [leagueId]);

    return (
        <Paper elevation={5} style={{minHeight: 500, width: "100%"}}>
            <HeaderTitle title={"Tabulka"}/>

            {
                leagueTable.leagueTables.length === 0 ?
                    (
                        <Box style={{borderTop: "1px solid rgba(0, 0, 0, 0.12)"}}>
                            <Typography style={{paddingLeft: 15, paddingTop: 20}} color={"textSecondary"}>Tabulka není k
                                dispozici.</Typography>
                        </Box>
                    )
                    :
                    (
                        <Box style={{borderTop: "1px solid rgba(0, 0, 0, 0.12)"}}>
                            <Link to={"/liga/" + leagueTable.league.nameUrl + "/" + leagueTable.league.id}
                                  style={{textDecoration: "none"}}>
                                <Typography variant="h6" color={"textSecondary"} gutterBottom
                                            style={{
                                                paddingTop: 20,
                                                paddingLeft: 10
                                            }}>
                                    {leagueTable.league.name}</Typography></Link>

                            {
                                isMobile ?
                                    (
                                        <FormGroup row>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={useForm}
                                                        onChange={handleChange}
                                                        color={"primary"}
                                                    />
                                                }
                                                label="Forma týmů"
                                            />
                                        </FormGroup>
                                    ) : <React.Fragment/>
                            }
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align={"center"} className={classes.root}>#</TableCell>
                                            <TableCell className={classes.root}>Tým</TableCell>

                                            {
                                                !isMobile ?
                                                    (
                                                        <>
                                                            <TableCell className={classes.root}>Z</TableCell>
                                                            <TableCell className={classes.root}>V</TableCell>
                                                            {
                                                                leagueTable.enabledKicks ?
                                                                    (
                                                                        <>
                                                                            <TableCell
                                                                                className={classes.root}>VP</TableCell>
                                                                            <TableCell
                                                                                className={classes.root}>PP</TableCell>
                                                                        </>
                                                                    ) :
                                                                    (
                                                                        <TableCell
                                                                            className={classes.root}>R</TableCell>
                                                                    )
                                                            }
                                                            <TableCell className={classes.root}>P</TableCell>
                                                            <TableCell className={classes.root}>Skóre</TableCell>
                                                            <TableCell className={classes.root}
                                                                       align={"center"}>Forma</TableCell>
                                                            <TableCell className={classes.root}>B</TableCell>
                                                        </>
                                                    )
                                                    : useForm ?
                                                    (

                                                        <TableCell className={classes.root}
                                                                   align={"center"}>Forma</TableCell>
                                                    )
                                                    :
                                                    (
                                                        <>
                                                            <TableCell className={classes.root}>Z</TableCell>
                                                            <TableCell className={classes.root}>V</TableCell>
                                                            {
                                                                leagueTable.enabledKicks ?
                                                                    (
                                                                        <>
                                                                            <TableCell className={classes.root}>VP</TableCell>
                                                                            <TableCell className={classes.root}>PP</TableCell>
                                                                        </>
                                                                    ) :
                                                                    (
                                                                        <TableCell className={classes.root}>R</TableCell>
                                                                    )
                                                            }
                                                            <TableCell className={classes.root}>P</TableCell>
                                                            <TableCell className={classes.root}>B</TableCell>
                                                        </>
                                                    )
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            leagueTable.leagueTables.map((row: LeagueTableRow) => (
                                                <TableRow key={row.position} hover={true}
                                                          style={row.team.id == homeTeamId ? {backgroundColor: "#c3e6cb"} : row.team.id === awayTeamId ? {backgroundColor: "#b8daff"} : {}}>
                                                    <TableCell className={classes.root} align={"center"}>{row.position}</TableCell>
                                                    <TableCell className={classes.root}><img width={25} height={25} src={row.logoCrop ?? noLogo}
                                                                    style={{
                                                                        paddingRight: 10,
                                                                        verticalAlign: "middle"
                                                                    }}/>
                                                        <Link style={{textDecoration: "none", color: "#1e4164"}}
                                                              to={"/tym/" + row.team.nameUrl + "/" + row.team.id}>{row.team.name}</Link>
                                                    </TableCell>
                                                    {
                                                        !isMobile ?
                                                            (
                                                                <>
                                                                    <TableCell className={classes.root}>{row.matchTotal}</TableCell>
                                                                    <TableCell className={classes.root}>{row.winCount}</TableCell>
                                                                    {
                                                                        leagueTable.enabledKicks ?
                                                                            (
                                                                                <>
                                                                                    <TableCell className={classes.root}>{row.winKicksCount}</TableCell>
                                                                                    <TableCell className={classes.root}>{row.lostKicksCount}</TableCell>
                                                                                </>
                                                                            )
                                                                            :
                                                                            (
                                                                                <TableCell className={classes.root}>{row.drawCount}</TableCell>
                                                                            )
                                                                    }

                                                                    <TableCell className={classes.root}>{row.lostCount}</TableCell>

                                                                    <TableCell className={classes.root}>{row.shotGoals + " : " + row.receivedGoals}</TableCell>
                                                                    <TableCell className={classes.root} align={"center"}>
                                                                        {
                                                                            row.teamForms.map(teamForm =>
                                                                                (
                                                                                    <Link to={teamForm.url}
                                                                                          title={teamForm.title}
                                                                                          target={"_blank"}>{resolveMatchResultStatus(teamForm.matchStatus)}</Link>
                                                                                )
                                                                            )
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell className={classes.root}>{row.points}</TableCell>
                                                                </>
                                                            )
                                                            : useForm
                                                            ?
                                                            (
                                                                    <TableCell className={classes.root} align={"center"}>
                                                                        {
                                                                            row.teamForms.map(teamForm =>
                                                                                (
                                                                                    <Link to={teamForm.url}
                                                                                          title={teamForm.title}
                                                                                          target={"_blank"}>{resolveMatchResultStatus(teamForm.matchStatus)}</Link>
                                                                                )
                                                                            )
                                                                        }
                                                                    </TableCell>
                                                            )
                                                            :
                                                            (
                                                                <>
                                                                    <TableCell className={classes.root}>{row.matchTotal}</TableCell>
                                                                    <TableCell className={classes.root}>{row.winCount}</TableCell>
                                                                    {
                                                                        leagueTable.enabledKicks ?
                                                                            (
                                                                                <>
                                                                                    <TableCell className={classes.root}>{row.winKicksCount}</TableCell>
                                                                                    <TableCell className={classes.root}>{row.lostKicksCount}</TableCell>
                                                                                </>
                                                                            )
                                                                            :
                                                                            (
                                                                                <TableCell className={classes.root}>{row.drawCount}</TableCell>
                                                                            )
                                                                    }

                                                                    <TableCell className={classes.root}>{row.lostCount}</TableCell>
                                                                    <TableCell className={classes.root}>{row.points}</TableCell>
                                                                </>)
                                                    }
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    )}
        </Paper>
    );
}