import React from "react";
import Box from "@material-ui/core/Box";
import {Container} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import makeStyles from "@material-ui/core/styles/makeStyles";
import "./content.css";
import {Link} from "react-router-dom";
import {isMobile} from "react-device-detect";

export interface EntityData {
    mapping: Mapping;
    menu: Array<MenuItem>;
}

export interface Mapping {
    header: string;
    navigation: string;
}

export interface MenuProps {
    menuTabs: Array<MenuItem>;
    // onChangeCall: Function;
    selectedTab: number;
}

export interface MenuItem {
    name: string;
    nameUrl: string;
}

const menuStyles = makeStyles(theme => ({
    menuWrapper: {
        backgroundColor: "white",
    },
    menuContainer: {
        backgroundColor: "white"
    },
    menuTabs: {
        color: "black",
        fontSize: 15,
        fontWeight: 800
    }
}));

function a11yProps(index: Number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export function Menu({menuTabs, selectedTab}: MenuProps) {

    const classes = menuStyles();
    const tabs: Array<Object> = [];

    menuTabs.forEach((val: MenuItem, index: number) => {
        tabs.push(<Tab component={Link} to={val.nameUrl} label={val.name} key={index} {...a11yProps(index)}
                       className={classes.menuTabs}/>);
    });

    return (
        <div className={classes.menuWrapper}>
            <Container className={classes.menuContainer}>
                <Tabs
                    variant="fullWidth"
                    orientation={isMobile ? "vertical" : "horizontal"}
                    aria-label="nav tabs example"
                    value={selectedTab}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "#38a9ff"
                        }
                    }}
                >
                    {tabs}
                </Tabs>
            </Container>
        </div>
    );
}


interface MenuContentProps {
    content: Object,
    tabId: number
}

export function MenuContent3({content, tabId}: MenuContentProps) {

    return (
        <Box style={{backgroundColor: "#edeff4", paddingTop: 40, paddingBottom: 40}}>
            <Container style={{backgroundColor: "#edeff4"}}
                       component="div"
                       role="tabpanel"
                       id={`simple-tabpanel-${tabId}`}
                       aria-labelledby={`simple-tab-${tabId}`}
            >
                {content}
            </Container>
        </Box>
    );
}


