import React from "react";
import {Match} from "../../../common/matches/matches";
import {noLogo, serverUrl} from "../../../../index";
import {Box, Button, createStyles, FormControl, Hidden, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import HeaderTitle from "../../../common/headerTitle";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import {resultWinningClass} from "../../../../util";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            paddingLeft: 10,
            marginTop: 20,
            marginBottom: 20
        },
        noLastItem: {
            borderRight: "1px solid #dce0e4",
            textAlign: "center"
        },
        lastItem: {
            textAlign: "center"
        }
    }),
);


export default function LastMatches({clubId, clubNameUrl}: { clubId: number, clubNameUrl: string }) {
    const classes = useStyles();

    const [matches, setMatches] = React.useState<Array<Match>>([])
    const [types, setTypes] = React.useState<Array<string>>([])

    React.useEffect(() => {
        fetch(serverUrl + '/club/' + clubId + '/club-teams-types')
            .then(res => res.json() as Promise<Array<string>>)
            .then((result) => {

                if (result.length > 0) {
                    performRequest(result[0])
                }

                setTypes(result)
            })
            .catch(console.log)
    }, [clubId]);

    const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        performRequest(event.target.value as string)
    };

    const performRequest = (type: string) => {
        fetch(serverUrl + '/club/' + clubId + '/last-matches?teamType=' + type)
            .then(res => res.json() as Promise<Array<Match>>)
            .then((result) => {
                setMatches(result.reverse())
            })
            .catch(console.log)
    }

    const renderSelectBox = () => {

        let resultOption = [];

        for (let type of types) {
            resultOption.push(<option value={type}>{type}</option>)
        }

        return (
            <Grid container>
                <Grid item sm={6} xs={12} className={classes.formControl}>
                    <FormControl variant="outlined">
                        <Select
                            native
                            onChange={handleChange}
                            name="teamsType"
                            inputProps={{'aria-label': 'age'}}
                        >
                            {resultOption}
                        </Select>
                    </FormControl></Grid>
                <Grid item sm={6} xs={12} className={classes.formControl} justify={"flex-end"} style={{
                    textAlign: "right", paddingRight: 20, marginTop: 30
                }}>
                    <Button component={Link} variant="outlined" style={{color: "#008B8B"}}
                            to={"/klub/" +clubNameUrl + "/" + clubId + "/zapasy"}> Další zápasy {'>>'}</Button>
                </Grid>
            </Grid>
        );
    }

    const renderMatches = () => {

        const result: any = [];
        const logoSize = 75;
        let i: number = 0;

        for (let match of matches) {
            result.push(<>
                    <Grid container item xs={12} sm={4} direction={"column"} spacing={1}
                          className={i < 2 ? classes.noLastItem : classes.lastItem}>
                        <Grid item style={{paddingTop: 20}}>
                            <img width={logoSize} height={logoSize}
                                 src={match.home.logo ?? noLogo}
                                 alt={"Logo domácího týmu."}/>
                        </Grid>
                        <Grid item>
                            <Link style={{color: "#008B8B", textDecoration: "none"}}
                                  to={"/tym/" + match.home.nameUrl + "/" + match.home.id}><Typography
                                variant={"h6"}>{match.home.name}</Typography></Link>

                        </Grid>
                        <Grid item style={{marginTop: 15, marginBottom: 15}}>
                            <Typography variant={"h4"}
                                        color={resultWinningClass(match, true)}
                                        display={"inline"}>{match.homeTeamFullTimeScoreWithKicks} {match.homeTeamKicks > match.awayTeamKicks ? "p" : ""}</Typography>
                            <Typography variant={"h4"}
                                        display={"inline"}>{" : "}</Typography>
                            <Typography variant={"h4"}
                                        color={resultWinningClass(match, false)}
                                        display={"inline"}>{match.awayTeamFullTimeScoreWithKicks} {match.awayTeamKicks > match.homeTeamKicks ? "p" : ""}</Typography>
                        </Grid>
                        <Grid item>
                            <Link style={{color: "#008B8B", textDecoration: "none"}}
                                  to={"/tym/" + match.away.nameUrl + "/" + match.away.id}><Typography
                                variant={"h6"}>{match.away.name}</Typography></Link>
                        </Grid>
                        <Grid item style={{paddingTop: 20}}><img width={logoSize} height={logoSize}
                                                                 src={match.away.logo ?? noLogo}
                                                                 alt={"Logo domácího týmu."}/></Grid>
                        <Grid item style={{marginTop: 10}}>

                            <Link style={{color: "black", textDecoration: "none"}}
                                  to={"/liga/" + match.league.nameUrl + "/" + match.league.id}><Typography variant={"caption"}>
                                {match.league.name}</Typography></Link>
                        </Grid>
                        <Grid item><Typography
                            variant={"caption"}>Dne {match.formattedDate} v {match.formattedTime} hod.</Typography></Grid>
                        <Grid item style={{marginTop: 20, textAlign: "center"}}>
                            <Button component={Link} variant="outlined" style={{color: "#008B8B"}}
                                    to={"/zapas/" + match.nameUrl + "/" + match.id}> Detail zápasu {'>>'}</Button>
                        </Grid>
                    </Grid>
                    <Hidden smUp>
                        <Divider style={{width: "100%", marginTop: 20}}/>
                    </Hidden>
                </>
            )
            i++;
        }

        return (
            <Grid container style={{paddingTop: 10, paddingBottom: 30, borderTop: "1px solid #dce0e4"}}
                  justify={"center"}>
                {result}
            </Grid>
        );
    }

    return (
        <Box style={{
            border: "1px solid #dce0e4",
            minHeight: 200,
            backgroundColor: "white",
            marginBottom: 10
        }}>
            <HeaderTitle title={"Poslední zápasy týmů klubu"}/>
            {renderSelectBox()}
            {renderMatches()}
        </Box>
    );


}