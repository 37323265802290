import React, {useContext} from "react";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {serverUrl} from "../../../index";
import {UserContext} from "../../auth/userContext";

interface WhoWinsData {
    vote1: number,
    voteX: number,
    vote2: number
}

export default function WhoWins({played, matchId}: { played: boolean, matchId: number }) {

    const [voted, setVoted] = React.useState<string | null>(null);
    const [data, setData] = React.useState<WhoWinsData | null>(null);
    const {userData, isLogged} = useContext(UserContext);

    React.useEffect(() => {
        let savedVoted = localStorage.getItem("match-" + matchId);
        let y: string | null;
        if (savedVoted !== null || played) {
            y = savedVoted;
            fetch(serverUrl + "/match/" + matchId + "/votes")
                .then(res => res.json() as Promise<WhoWinsData>)
                .then(result => {
                    setData(result);
                }).catch(console.log);
        } else {
            y = null;
            setData(null);
        }
        setVoted(y);
    }, [matchId])

    const setUserVote = (value: string) => {
        setVoted(value);
        localStorage.setItem("match-" + matchId, value);

        fetch(serverUrl + "/match/" + matchId + "/vote",
            {
                method: "POST",
                headers: {
                    "X-Auth": userData.token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "vote": value
                }, null, 2)
            })
            .then(res => res.json() as Promise<WhoWinsData>)
            .then((result) => {
                setData(result)
            })
            .catch(console.log)
    }


    const render = () => {
        return voted !== null || played ?
            renderVoted()
            :
            renderNonVoted()
    }

    const renderNonVoted = () => {

        return (
            <Grid container style={{textAlign: "center", paddingTop: 20}}>
                <Grid item xs={12}><Typography variant={"button"}>Kdo vyhraje?</Typography></Grid>
                <Grid item container style={{minHeight: 24, maxHeight: 36}}>
                    <Grid item xs={4} style={{padding: 10}}>
                        <Button variant="outlined" style={{
                            backgroundColor: "rgb(82, 176, 48)",
                            color: "white",
                            width: "100%"
                        }} onClick={() => {
                            setUserVote("1")
                        }}>
                            1
                        </Button>
                    </Grid>
                    <Grid item xs={4} style={{padding: 10}}>
                        <Button variant="outlined" style={{
                            backgroundColor: "rgb(164, 170, 176)",
                            color: "white",
                            width: "100%"
                        }} onClick={() => {
                            setUserVote("X")
                        }}>
                            X
                        </Button>
                    </Grid>
                    <Grid item xs={4} style={{padding: 10}}>
                        <Button variant="outlined" style={{
                            backgroundColor: "rgb(0, 109, 204)",
                            color: "white",
                            width: "100%"
                        }} onClick={() => {
                            setUserVote("2")
                        }}>
                            2
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const renderVoted = () => {
        let perc1: number = 33;
        let percX: number = 33;
        let perc2: number = 33;

        if (data !== null) {
            let sum: number = data.vote1 + data.voteX + data.vote2;

            if(sum !== 0)
            {
                perc1 = data.vote1 / sum * 100;
                percX = data.voteX / sum * 100;
                perc2 = data.vote2 / sum * 100;

                perc1 = Math.round(perc1 * 100) / 100;
                percX = Math.round(percX * 100) / 100;
                perc2 = Math.round(perc2 * 100) / 100;
            }
        }

        return (
            <Grid container style={{marginTop: 20, padding: 10}}>
                <Grid item xs={12} style={{textAlign: "center", paddingBottom: 15}}><Typography variant={"button"}>Kdo
                    vyhraje?</Typography></Grid>
                {
                    data !== null &&
                    <Grid container>
                        <Grid item xs={4}><Typography align={"left"} variant={"body2"}
                                                      style={{color: "rgb(82, 176, 48)", paddingLeft: 5}}>{data.vote1 + (voted !== null && voted === "1" ? " (Vy)" : "")}</Typography></Grid>
                        <Grid item xs={4}><Typography align={"center"} variant={"body2"}
                                                      style={{color: "rgb(164, 170, 176)"}}>{data.voteX + (voted !== null && voted === "X" ? " (Vy)" : "")}</Typography></Grid>
                        <Grid item xs={4}><Typography align={"right"} variant={"body2"}
                                                      style={{color: "rgb(0, 109, 204)", paddingRight: 5}}>{data.vote2 + (voted !== null && voted === "2" ? " (Vy)" : "")}</Typography></Grid>
                    </Grid>
                }
                <Grid container style={{minHeight: 30, color: "white"}}>
                    <Grid item style={{
                        backgroundColor: "rgb(82, 176, 48)",
                        width: perc1 + "%",
                        paddingTop: 4
                    }}><Typography style={{paddingLeft: 5}}>{perc1 !== 0 ? " " + perc1 + "%" : ""}</Typography></Grid>
                    <Grid item style={{
                        backgroundColor: "rgb(164, 170, 176)",
                        width: percX + "%",
                        paddingTop: 4,
                        textAlign: "center"
                    }}><Typography>{percX !== 0 ? percX + "%" : ""}</Typography></Grid>
                    <Grid item style={{
                        backgroundColor: "rgb(0, 109, 204)",
                        width: perc2 + "%",
                        paddingTop: 4,
                        textAlign: "right"
                    }}><Typography style={{paddingRight: 5}}>{perc2 !== 0 ? perc2 + "% " : ""}</Typography></Grid>
                </Grid>
            </Grid>
        )
    }


    return (
        <>
            {
                render()
            }
        </>
    );
}