import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {Box, Container, Grid, Paper, Typography} from "@material-ui/core";
import Navbar from "../navbar/Navbar";
import {serverUrl} from "../../index";
import {Match, MatchPlayer} from "../common/matches/matches";
import MatchEvents, {EventType, getMatchEventIcon} from "../common/matches/matchEvents";
import HeaderTitle from "../common/headerTitle";
import LeagueTable from "../common/table/leagueTable";
import "./matchDetail.css"
import Footer from "../common/footer/footer";
import Divider from "@material-ui/core/Divider";
import MatchInfo from "./matchInfo";
import MatchScoreExt from "./matchScoreExt";
import ReactGA from "react-ga";
import MatchHelmet from "./matchHelmet";
import MatchTeamsScoreHeader from "./matchTeamsScoreHeader";
import Upload from "../common/gallery/upload";
import TvcomVideoWrapper from "../common/video/tvcomVideoWrapper";
import DognetAd, {AdType} from "../common/ads/dognetAd";
import Ad from "../common/ads/ad";

function MatchPlayers({matchPlayers, homePlayers}: { matchPlayers: Array<MatchPlayer>, homePlayers: boolean }) {
    return (
        <>
            {
                matchPlayers.length === 0 ? (
                        <Grid item>
                            <Typography color={"textSecondary"} style={{
                                paddingLeft: 20,
                                paddingTop: 20,
                                paddingBottom: 5
                            }}>Sestava není k dispozici.</Typography>
                        </Grid>) :
                    (
                        matchPlayers.map((matchPlayer: MatchPlayer) => (
                            <MatchPlayerDetail matchPlayer={matchPlayer}
                                               homePlayers={homePlayers}/>
                        )))
            }
        </>
    );
}

function MatchPlayerDetail({matchPlayer, homePlayers}: { matchPlayer: MatchPlayer, homePlayers: boolean }) {

    let classDress = homePlayers ? "home-team-player-dress" : "away-team-player-dress";
    let colorNumber = homePlayers ? "black" : "white";

    // @ts-ignore
    function processPlayerEvent(matchEventsMap: Map<EventType, number> | undefined) {
        if (matchEventsMap === undefined) {
            return "";
        }

        let result: Array<React.ReactFragment> = [];

        // @ts-ignore
        Object.keys(matchEventsMap).forEach(value =>

            result.push(
                <>
                    {
                        // @ts-ignore
                        matchEventsMap[value] > 1 ? (
                            <Typography style={{paddingLeft: 10, paddingRight: 5}} display={"inline"}
                                        variant={"body2"}>
                                {
                                    // @ts-ignore
                                    matchEventsMap[value] + "x"
                                }
                            </Typography>
                        ) : <Typography display="inline" style={{paddingLeft: 5}}/>
                    }
                    {getMatchEventIcon(value as EventType)}
                </>
            )
        );

        return result;
    }

    return (
        <Grid item key={matchPlayer.player.id}
              style={{
                  paddingLeft: 20,
                  paddingBottom: 5,
                  borderBottom: "1px dashed rgba(0, 0, 0, 0.12)",
                  backgroundColor: "white"
              }}>
            <Typography style={{
                display: "inline-block",
                padding: "15px 15px 0 15px",
                fontSize: 12,
                color: colorNumber,
                fontWeight: "bold"
            }}
                        className={classDress}>{matchPlayer.number}</Typography>
            <Link style={{
                verticalAlign: "middle",
                marginLeft: 15,
                fontSize: "0.875rem",
                textDecoration: "none",
                color: "black"
            }}
                  to={"/hrac/" + matchPlayer.player.nameUrl + "/" + matchPlayer.player.id}>
                <Typography display={"inline"}>
                    {matchPlayer.player.firstName + " " + matchPlayer.player.lastName}
                </Typography>
            </Link>
            <Typography display={"inline"}>
                {
                    processPlayerEvent(matchPlayer.matchEventsMap)
                }
            </Typography>
        </Grid>
    );
}

export default function MatchDetail() {

    // @ts-ignore
    let {matchName, matchId}: { matchName: string, matchId: number } = useParams();
    const [selectedMatch, setSelectedMatch] = useState<Match | null>(null);

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        ReactGA.initialize("UA-116060364-1");
        ReactGA.pageview(window.location.pathname);

        fetch(serverUrl + "/match/" + matchId + "/detail?players=true")
            .then(res => res.json())
            .then(result => {
                setSelectedMatch(result);
            })
            .catch(console.log)
    }, [matchId]);


    return (
        selectedMatch == null ? <Box/> : (
            <Box>
                <MatchHelmet selectedMatch={selectedMatch}/>
                <Navbar/>
                <Box style={{backgroundColor: "#edeff4", paddingTop: 20, minHeight: 800}}>
                    <MatchTeamsScoreHeader selectedMatch={selectedMatch}/>
                    <Container style={{backgroundColor: "#edeff4", marginBottom: 30}}>
                        <MatchScoreExt match={selectedMatch}/>

                        <Box style={{paddingBottom: 10, paddingTop: 10}}>
                            <Container>
                                <ins className="adsbygoogle"
                                     style={{display: "block"}}
                                     data-ad-client="ca-pub-7325589669239459"
                                     data-ad-slot="2637581797"
                                     data-ad-format="auto"
                                     data-full-width-responsive="true"/>
                            </Container>
                        </Box>

                        <Grid container spacing={3} style={{marginTop: 10}}>
                            <Grid item container xs={12} sm={6} direction={"column"}>
                                <Grid item>
                                    <Paper style={{minHeight: 250}}>
                                        <HeaderTitle title={"Události v zápase"}/>
                                        <MatchEvents matchId={selectedMatch.id} events={selectedMatch.events}
                                                     homeTeamId={selectedMatch.home.id}
                                                     halfScoreHome={selectedMatch.homeTeamHalfTimeScore}
                                                     halfScoreAway={selectedMatch.awayTeamHalfTimeScore}/>
                                    </Paper>
                                </Grid>
                                <Grid item>
                                    {/*<DognetAd marginY={2} webAdType={AdType.HORIZONTAL} mobileAdType={AdType.CUBE}/>*/}
                                    <Ad ad={<ins className="adsbygoogle"
                                                 style={{display:"block"}}
                                                 data-ad-client="ca-pub-7325589669239459"
                                                 data-ad-slot="2653121138"
                                                 data-ad-format="auto"
                                                 data-full-width-responsive="true"></ins>}
                                        height={100} marginY={3}
                                    />
                                </Grid>
                                <Grid item container spacing={2} style={{marginTop: 20}}>
                                    <Grid item xs={12} sm={6}>
                                        <Paper>
                                            <HeaderTitle title={selectedMatch.home.name}
                                                         color={"rgb(195,230,203)"}/>

                                            <MatchPlayers matchPlayers={selectedMatch.homeTeamPlayers}
                                                          homePlayers={true}/>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Paper>
                                            <Grid item>
                                                <HeaderTitle title={selectedMatch.away.name}
                                                             color={"rgb(184, 218, 255)"}/>
                                            </Grid>
                                            <MatchPlayers matchPlayers={selectedMatch.awayTeamPlayers}
                                                          homePlayers={false}/>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} sm={6} direction={"column"}>
                                <Grid item style={{marginBottom: 20}}>
                                    <MatchInfo match={selectedMatch}/>
                                </Grid>
                                <Grid item>
                                    {/*<DognetAd marginY={2} webAdType={AdType.HORIZONTAL} mobileAdType={AdType.CUBE}/>*/}
                                    <Ad ad={<ins className="adsbygoogle"
                                                 style={{display:"block"}}
                                                 data-ad-client="ca-pub-7325589669239459"
                                                 data-ad-slot="2653121138"
                                                 data-ad-format="auto"
                                                 data-full-width-responsive="true"></ins>}
                                        height={100} marginY={3}
                                    />
                                </Grid>
                                <Grid item style={{marginBottom: 20}}>
                                    <Paper>
                                        <HeaderTitle title={"Fotogalerie"} beta={true}/>
                                        <Upload matchId={matchId}/>
                                        {/*<Divider/>*/}
                                        {/*<DisplayGalleries matchId={matchId}/>*/}
                                    </Paper>
                                </Grid>
                                {selectedMatch.tvcomVideoCode &&
                                <Grid item style={{marginBottom: 20}}>
                                    <Paper>
                                        <HeaderTitle title={"Video"}/>
                                        <TvcomVideoWrapper videoCode={selectedMatch.tvcomVideoCode}/>
                                    </Paper>
                                </Grid>
                                }
                            </Grid>
                        </Grid>
                        <Grid container style={{marginTop: 20}}>
                            <LeagueTable leagueId={selectedMatch.league.id} homeTeamId={selectedMatch.home.id}
                                         awayTeamId={selectedMatch.away.id}/>
                        </Grid>
                        {/*<DognetAd marginY={3} webAdType={AdType.HORIZONTAL} mobileAdType={AdType.CUBE}/>*/}
                        <Ad ad={<ins className="adsbygoogle"
                                     style={{display:"block"}}
                                     data-ad-client="ca-pub-7325589669239459"
                                     data-ad-slot="2653121138"
                                     data-ad-format="auto"
                                     data-full-width-responsive="true"></ins>}
                            height={100} marginY={3}
                        />
                    </Container>
                    <Divider/>
                    <Footer/>
                </Box>
            </Box>
        ));
}



