import React from "react";
import Iframe from "react-iframe";


export default function TvcomVideoWrapper({videoCode}: { videoCode: String }) {

    const [loaded, setLoaded] = React.useState<boolean>(false);

    const loadVideo = () => {
        setLoaded(true);
    }

    return (
        <>
            {
                loaded ?
                    <Iframe url={"//embed.tvcom.cz/" + videoCode}
                            width={"100%"}
                            height="405px"
                            id={"" + videoCode}
                        // @ts-ignore
                            display="initial"
                            position="relative"
                            allowFullScreen
                            frameBorder={0}
                    /> :
                    <img alt="Blurred image"
                         onClick={() => loadVideo()}
                         src="https://storage.googleapis.com/kdesehraje-design/blured-video-image.png"
                         height="405px"
                         width="100%"/>
            }
        </>
    );
}