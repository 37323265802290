import React from "react";
import {Match} from "../common/matches/matches";
import {Breadcrumbs, Grid, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import {resultWinningClass} from "../../util";
import Container from "@material-ui/core/Container";
import {noLogo} from "../../index";


export default function MatchTeamsScoreHeader({selectedMatch}: { selectedMatch: Match }) {

    const logoSize = 150;

    const renderBreadcrumbs = () => {
        if (selectedMatch !== null) {
            return (<Breadcrumbs aria-label="breadcrumb" style={{paddingLeft: 10}}>
                <Link color={"inherit"} to="/" style={{textDecoration: "none", color: "grey"}}>
                    Domů
                </Link>
                <Link style={{textDecoration: "none", color: "black"}}
                      to={"/liga/" + selectedMatch.league.nameUrl + "/" + selectedMatch.league.id}>
                    {selectedMatch.league.name}
                </Link>
            </Breadcrumbs>)
        }
    }


    return (
        <Container>
            <Typography
                style={{color: "rgba(0, 0, 0, 0.50)", marginTop: 30, marginBottom: 10, paddingLeft: 10}}
                variant={"h3"}
                align={"center"}>{selectedMatch.home.name + " vs. " + selectedMatch.away.name}</Typography>
            <Grid container alignItems={"center"} alignContent={"center"}
                  style={{
                      paddingTop: 40,
                      paddingBottom: 10,
                      backgroundColor: "white",
                      border: "1px solid #d3d3d3",
                      borderTopRightRadius: 25,
                      borderTopLeftRadius: 25
                  }}>
                <Grid container item alignItems={"center"} alignContent={"center"}
                      direction="column" xs={12} sm={4} spacing={1}>
                    <Grid item>
                        <img width={logoSize} height={logoSize}
                             src={selectedMatch.home.logo ?? noLogo}
                             alt={"Logo domácího týmu."}/>
                    </Grid>
                    <Grid item>
                        <Link style={{color: "#008B8B", textDecoration: "none"}}
                              to={"/tym/" + selectedMatch.home.nameUrl + "/" + selectedMatch.home.id}>{selectedMatch.home.name}</Link>
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={4} direction="column" alignItems={"center"}
                      alignContent={"center"}>
                    <Grid item xs={12}>
                        {
                            selectedMatch.homeTeamHalfTimeScore !== -1 ?
                                <>
                                    <Typography variant={"h2"}
                                                color={resultWinningClass(selectedMatch, true)}
                                                display={"inline"}>{selectedMatch.homeTeamFullTimeScoreWithKicks}</Typography>
                                    <Typography variant={"h2"}
                                                display={"inline"}>{" : "}</Typography>
                                    <Typography variant={"h2"}
                                                color={resultWinningClass(selectedMatch, false)}
                                                display={"inline"}>{selectedMatch.awayTeamFullTimeScoreWithKicks}</Typography>
                                </>
                                :
                                <Typography variant={"h4"}>{selectedMatch.formattedDate}</Typography>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {
                            selectedMatch.homeTeamHalfTimeScore !== -1 ?
                                <Typography style={{paddingBottom: 10}} color={"textSecondary"} variant={"h4"}>
                                    {"( " + selectedMatch.homeTeamHalfTimeScore + " : " + selectedMatch.awayTeamHalfTimeScore + " )"}
                                </Typography>
                                :
                                <Typography style={{paddingTop: 10, paddingBottom: 10}} variant={"h5"}
                                            color={"textSecondary"}>
                                    {selectedMatch.formattedTime}
                                </Typography>

                        }
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={4} direction="column" alignItems={"center"} spacing={1}
                      alignContent={"center"}>
                    <Grid item>
                        <img width={logoSize} height={logoSize} src={selectedMatch.away.logo ?? noLogo}
                             alt={"Logo domácího týmu."}/>
                    </Grid>
                    <Grid item>
                        <Link style={{color: "#008B8B", textDecoration: "none"}}
                              to={"/tym/" + selectedMatch.away.nameUrl + "/" + selectedMatch.away.id}>{selectedMatch.away.name}</Link>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{marginTop: 30, paddingTop: 10, borderTop: "1px solid #e0e0e0"}}>
                    {renderBreadcrumbs()}
                </Grid>
            </Grid>
        </Container>
    )
}