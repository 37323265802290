import React from "react";
import {PlayerStat} from "../../../common/statistics/statistics";
import {serverUrl} from "../../../../index";
import Box from "@material-ui/core/Box";
import HeaderTitle from "../../../common/headerTitle";
import {makeStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import {Grid, Hidden} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 4
    }
}));


export default function BestShooters({leagueId, leagueName}: { leagueId: number, leagueName: string }) {

    const classes = useStyles();

    const [shooters, setShooters] = React.useState<Array<PlayerStat>>([])

    React.useEffect(() => {
        fetch(serverUrl + '/league/' + leagueId + '/statistics?type=GOALS&limited=false')
            .then(res => res.json() as Promise<Array<PlayerStat>>)
            .then((result) => {
                setShooters(result)
            })
            .catch(console.log)
    }, [leagueId]);

    const renderShooters = () => {
        const result: any = [];
        let i = 1;
        for (let shooter of shooters) {
            if (i > 10) break;
            result.push(
                <TableRow>
                    <TableCell className={classes.root} align={"center"}>{i + "."}</TableCell>
                    <TableCell className={classes.root}>
                        <Link to={"/hrac/" + shooter.player.nameUrl + "/" + shooter.player.id}
                              style={{textDecoration: "none", color: "rgb(30, 65, 100)", fontSize: 12}}>
                            <Grid container alignItems={"center"} alignContent={"center"}>
                                <Hidden smDown>
                                    <Grid item container sm={1} alignItems={"center"} alignContent={"center"}>
                                        <img width={25} height={25} src={shooter.player.club.logoCrop}
                                        />
                                    </Grid>
                                </Hidden>
                                <Grid item container xs={12} sm={11} direction={"column"}>
                                    <Grid item xs={12}>

                                        <Typography
                                            variant={"body1"}>  {shooter.player.firstName + " " + shooter.player.lastName}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant={"button"} color={"textSecondary"}
                                                    style={{fontSize: 10}}>{shooter.player.club.name}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Link>
                    </TableCell>
                    <TableCell className={classes.root}>{shooter.player.positionRole}</TableCell>
                    <TableCell className={classes.root} align={"center"}>{shooter.count}</TableCell>
                </TableRow>
            )
            i++;
        }


        return result;
    }

    return (
        <Box mt={2} style={{backgroundColor: "white", border: '1px solid lightGrey'}}>
            <HeaderTitle title={"TOP 10 nejlepších střelců"}/>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.root} align={"center"}>Poř.</TableCell>
                        <TableCell className={classes.root}>Hráč</TableCell>
                        <TableCell className={classes.root}>Pozice</TableCell>
                        <TableCell className={classes.root} align={"center"}>Góly</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        renderShooters()
                    }
                    <TableRow>
                        <TableCell colSpan={4} align={"center"}>
                            <Link to={"/liga/" + leagueName + "/" + leagueId + "/statistiky"}
                                  style={{textDecoration: "none", color: "black", fontSize: 14}}>
                                {"Další střelci a statistiky >>"}</Link>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Box>
    );
}