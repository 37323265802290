import React from "react";
import {Match} from "../../../common/matches/matches";
import {serverUrl} from "../../../../index";
import {Box, Button, Grid, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import HeaderTitle from "../../../common/headerTitle";
import {resultWinningClass} from "../../../../util";


interface MatchWrapper {
    empty: boolean,
    match?: Match
}

export default function LastMatch({teamId}: { teamId: number }) {

    const [match, setMatch] = React.useState<Match | null>();

    React.useEffect(() => {
        fetch(serverUrl + '/team/' + teamId + '/last-match')
            .then(res => res.json() as Promise<MatchWrapper>)
            .then((result) => {
                if (!result.empty) {
                    // @ts-ignore
                    setMatch(result.match);
                }
            })
            .catch(console.log)


    }, [teamId]);

    const renderLastMatch = () => {
        if (match == null) {
            return (
                <Box py={3} pl={3}>
                    Žádný zápas nenalezen.
                </Box>
            );
        }

        const logoSize = 100;
        return (
            <Grid container alignItems={"center"} alignContent={"center"}
                  style={{
                      paddingTop: 15,
                      paddingBottom: 20,
                  }}>
                <Grid item container xs={12}
                      style={{color: "grey", borderBottom: '1px solid lightGrey', paddingBottom: 10, marginBottom: 30}}>
                    <Grid item xs={6} style={{paddingLeft: 15}}>
                        <Typography variant={"caption"}>{match.league.name}</Typography>
                    </Grid>
                    <Grid item xs={6} style={{paddingRight: 15, textAlign: "right"}}>
                        <Typography
                            variant={"caption"}>Dne {match.formattedDate} v {match.formattedTime} hod.</Typography>
                    </Grid>
                </Grid>
                <Grid container item alignItems={"center"} alignContent={"center"}
                      direction="column" xs={12} sm={4}>
                    <Grid item xs={12}>
                        <img width={logoSize} height={logoSize} src={match.home.logo}
                             alt={"Logo domácího týmu."}/>
                    </Grid>
                    <Grid item xs={12} style={{marginTop: 10}}>
                        <Link style={{color: "#008B8B", textDecoration: "none"}}
                              to={"/tym/" + match.home.nameUrl + "/" + match.home.id}>{match.home.name}</Link>
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={4} direction="column" alignItems={"center"}
                      alignContent={"center"}>
                    <Grid item xs={12}>
                        <Typography variant={"h3"}
                                    color={resultWinningClass(match, true)}
                                    display={"inline"}>{match.homeTeamFullTimeScoreWithKicks} {match.homeTeamKicks > match.awayTeamKicks ? "p" : ""}</Typography>
                        <Typography variant={"h3"}
                                    display={"inline"}>{" : "}</Typography>
                        <Typography variant={"h3"}
                                    color={resultWinningClass(match, false)}
                                    display={"inline"}>{match.awayTeamFullTimeScoreWithKicks} {match.awayTeamKicks > match.homeTeamKicks ? "p" : ""}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{paddingBottom: 10}} color={"textSecondary"}
                                    variant={"h6"}>{"( " + match.homeTeamHalfTimeScore + " : " + match.awayTeamHalfTimeScore + " )"}</Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={4} direction="column" alignItems={"center"}
                      alignContent={"center"}>
                    <Grid item xs={12}>
                        <img width={logoSize} height={logoSize} src={match.away.logo}
                             alt={"Logo hostujícího týmu."}/>
                    </Grid>
                    <Grid item xs={12} style={{marginTop: 10}}>
                        <Link style={{color: "#008B8B", textDecoration: "none"}}
                              to={"/tym/" + match.away.nameUrl + "/" + match.away.id}>{match.away.name}</Link>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{marginTop: 20, textAlign: "center"}}>
                        <Button component={Link} variant="outlined" style={{color: "#008B8B"}} to={"/zapas/" + match.nameUrl + "/" + match.id}> Detail zápasu {'>>'}</Button>
                </Grid>
            </Grid>
        )

    }

    return (
        <Box mb={2} style={{backgroundColor: "white", border: '1px solid lightGrey'}}>
            <HeaderTitle title={"Poslední odehraný zápas"}/>
            {renderLastMatch()}
        </Box>
    );
}