import React from "react"
import HeaderTitle from "../../../common/headerTitle";
import {Box, Typography} from "@material-ui/core";


export default function News() {

    return (
        <Box style={{
            border: "1px solid #dce0e4",
            minHeight: 400,
            backgroundColor: "white"
        }}>
            <HeaderTitle title={"Novinky"}/>
            <Typography style={{padding: 20}}>Novinky budou postupně přidávány.</Typography>
        </Box>
    );
}