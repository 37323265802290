import React from 'react';
import {fade, makeStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import {AppBar, Container, Grid, InputAdornment, InputBase, Menu} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import HomeIcon from '@material-ui/icons/Home';
import MapIcon from '@material-ui/icons/Map';
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';
import LaptopIcon from '@material-ui/icons/Laptop';

import Autocomplete from '@material-ui/lab/Autocomplete';
import {noLogo, serverUrl} from "../../index";
import {AccountCircle} from "@material-ui/icons";
// @ts-ignore
import {Link} from "react-router-dom";
import UserContextProvider from "../auth/userContextProvider";
import ContactMailIcon from '@material-ui/icons/ContactMail';
import AnnouncementIcon from '@material-ui/icons/Announcement';


interface ClubSearch {
    id: number,
    name: string,
    nameUrl: string,
    logoCrop: string
}

// @ts-ignore
function renderSuggestion(suggestionProps) {
    const {suggestion, index, itemProps, highlightedIndex, selectedItem} = suggestionProps;
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;

    return (
        <MenuItem
            {...itemProps}
            key={suggestion.label}
            selected={isHighlighted}
            component="div"
            style={{
                fontWeight: isSelected ? 500 : 400,
            }}
        >
            {suggestion.label}
        </MenuItem>
    );
}

renderSuggestion.propTypes = {
    highlightedIndex: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]).isRequired,
    index: PropTypes.number.isRequired,
    itemProps: PropTypes.object.isRequired,
    selectedItem: PropTypes.string.isRequired,
    suggestion: PropTypes.shape({
        label: PropTypes.string.isRequired,
    }).isRequired,
};


const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
        marginRight: theme.spacing(2),
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            marginRight: 0,
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 400,
            '&:focus': {
                width: 450,
            }
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    link: {
        color: 'white',
        marginRight: theme.spacing(2),
        textDecoration: "none",
        '&:hover': {
            color: "lightgrey",
            textDecoration: 'none',
        }
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        left: 0,
        right: 0,
    },
}));

export default function Navbar() {
    //let history = useHistory();

    const classes = useStyles();
    const [state, setState] = React.useState({
        showed: false
    });
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState<Array<ClubSearch>>([]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };


    // @ts-ignore
    const logoutUser = (context) => {
        //context.logoutUser();
        //history.push("/");
        //return undefined;
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={menuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <UserContextProvider.context.Consumer>
                {
                    // @ts-ignore
                    context => (
                        context.isLogged() ?
                            (
                                <>
                                    <MenuItem><Link to={"/uzivatel/profil"}>Profil</Link></MenuItem>
                                    <MenuItem onClick={context.logoutUser}><Link to={"/"}>Odhlásit</Link></MenuItem>
                                </>
                            )
                            :
                            (
                                <>
                                    <MenuItem><Link to={"/prihlaseni"}>Přihlásit</Link></MenuItem>
                                    <MenuItem><Link to={"/registrace"}>Registrovat</Link></MenuItem>
                                </>
                            )
                    )

                }
            </UserContextProvider.context.Consumer>

        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            {/*<MenuItem>*/}
            {/*    <IconButton aria-label="show 11 new notifications" color="inherit">*/}
            {/*        <Badge badgeContent={11} color="secondary">*/}
            {/*            <NotificationsIcon />*/}
            {/*        </Badge>*/}
            {/*    </IconButton>*/}
            {/*    <p>Notifications</p>*/}
            {/*</MenuItem>*/}
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle/>
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );

    // @ts-ignore
    const handleChange = event => {
        if (event == null || event.target == null || event.target.value == null || event.target.value.length < 2) {
            setOptions([]);
            return;
        }
        setInputValue(event.target.value);
    };


    React.useEffect(() => {
        let active = true;

        if (inputValue === '') {
            setOptions([]);
            return undefined;
        }

        fetch(serverUrl + '/club/find-by-name?q=' + inputValue)
            .then(res => res.json())
            .then((result) => {
                if (active) {
                    setOptions(result.data.clubs || []);
                }
            })
            .catch(console.log)

        return () => {
            active = false;
        };
    }, [inputValue]);

// @ts-ignore
    const toggleDrawer = (open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({...state, showed: open});
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const sideList = (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                <ListItem>
                    <ListItemIcon><SportsSoccerIcon/></ListItemIcon>
                    <ListItemText primary={"Kdesehraje.cz"}/>
                </ListItem>
                <Divider/>
                <ListItem>
                    <ListItemIcon><HomeIcon/></ListItemIcon>
                    <ListItemText><Link to={"/"}>Domů</Link></ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon><MapIcon/></ListItemIcon>
                    <ListItemText><Link to={"/mapa"}>Mapa</Link></ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon><ContactMailIcon/></ListItemIcon>
                    <ListItemText><Link to={"/kontakt"}>Kontakt</Link></ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon><SportsSoccerIcon/></ListItemIcon>
                    <ListItemText><Link to={"/hrdinove-v-nizsich-soutezich"}>Profesionálové v amatér. klubech</Link></ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon><AnnouncementIcon/></ListItemIcon>
                    <ListItemText><Link to={"/co-je-noveho"}>Novinky</Link></ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon><LaptopIcon/></ListItemIcon>
                    <ListItemText><Link to={"/spoluprace"}>Spolupráce</Link></ListItemText>
                </ListItem>
            </List>
        </div>
    );

    const mobileButton = (
        <div className={classes.sectionMobile}>
            <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer(true)}
            >
                <MenuIcon/>
            </IconButton>
        </div>
    );

    return (
        <div className={classes.grow}>
            <AppBar position="fixed" style={{backgroundColor: "#0057a3"}}>
                <Container>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer(true)}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Typography className={classes.title} variant="h6" noWrap>
                            <Link to="/" className={classes.link}>Kdesehraje.cz</Link>
                        </Typography>

                        <Autocomplete
                            filterOptions={x => x}
                            autoComplete
                            id="combo-box-demo"
                            options={options}
                            getOptionLabel={option => option.name}
                            noOptionsText={"Žádný klub nenalezen."}
                            loadingText={"Načítám kluby..."}
                            loading={true}
                            freeSolo
                            includeInputInList
                            onInputChange={handleChange}
                            clearOnEscape
                            classes={{root: classes.search}}
                            renderInput={params => <InputBase
                                {...params}
                                placeholder="Vyhledej klub..."
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                ref={params.InputProps.ref}
                                startAdornment={<InputAdornment style={{paddingLeft: 10}} position="start"><SearchIcon/></InputAdornment>}
                            />}
                            renderOption={option => {

                                return (
                                    <Link to={"/klub/" + option.nameUrl + "/" + option.id}
                                          style={{textDecoration: "none", color: "grey", width: "100%"}}>
                                        <Grid container alignItems="center">
                                            <Grid item>
                                                <img alt={"Logo klubu."}
                                                     src={option.logoCrop === undefined || option.logoCrop === null ? noLogo : option.logoCrop}
                                                     height={30} width={30}
                                                     style={{paddingRight: 10}}/>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant={"button"}>{option.name}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Link>
                                );
                            }}
                        />
                        <div className={classes.grow}/>
                        <div className={classes.sectionDesktop}>
                            <UserContextProvider.context.Consumer>
                                {
                                    // @ts-ignore
                                    context => (
                                        <>
                                            {
                                                context.isLogged() ?
                                                    (
                                                        <Link to={"/uzivatel/profil"} style={{
                                                            color: "white",
                                                            textDecoration: "none",
                                                            fontSize: 17
                                                        }}
                                                              color={"inherit"}>{context.userData.user.nickname !== undefined ? context.userData.user.nickname : context.userData.user.email}</Link>
                                                    ) :
                                                    (
                                                        <Link to={"/prihlaseni"} color="inherit" style={{color: "white", textDecoration: "none", fontSize: 17}}>
                                                            Přihlásit / Registrovat
                                                            se</Link>
                                                    )
                                            }
                                        </>
                                    )}
                            </UserContextProvider.context.Consumer>
                        </div>
                        <div className={classes.sectionDesktop}>
                            <IconButton
                                edge="end"
                                aria-label="account of current user"
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                color="inherit"
                            >
                                <AccountCircle/>
                            </IconButton>
                        </div>
                        <div className={classes.sectionMobile}>
                            <IconButton
                                aria-label="show more"
                                aria-haspopup="true"
                                onClick={handleMobileMenuOpen}
                                color="inherit"
                            >
                                <MoreIcon/>
                            </IconButton>
                        </div>
                    </Toolbar>
                </Container>
                <SwipeableDrawer
                    open={state.showed}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                >
                    {sideList}
                </SwipeableDrawer>
                {renderMenu}
                {renderMobileMenu}
            </AppBar>
            <Toolbar/>
        </div>
    );
}
