import React from "react";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import {Team} from "../../team/team";
import {Link} from "react-router-dom";
import WhoWins from "./whoWins";

interface Player {
    id: number,
    firstName: string,
    lastName: string,
    nameUrl: string
}

export interface MatchEvent {
    id: number,
    player: Player,
    matchMinute: number,
    eventType: EventType,
    changed: Player,
    team: Team,
}

export enum EventType {
    RED_CARD = "RED_CARD",
    YELLOW_CARD1 = "YELLOW_CARD1",
    YELLOW_CARD2 = "YELLOW_CARD2",
    GOAL = "GOAL",
    OWN_GOAL = "OWN_GOAL",
    PENALTY = "PENALTY",
    CHANGE1 = "CHANGE1",
    CHANGE2 = "CHANGE2"
}

export function getMatchEventIcon(eventType: EventType) {

    let baseUrl = "https://www.kdesehraje.cz/media/match-events/";

    switch (eventType) {
        case EventType.GOAL:
            return <img style={{verticalAlign: "middle"}} src={baseUrl + "/goal.png"} alt={"Gól."} title={"Gól."}/>;
        case EventType.OWN_GOAL:
            return <img style={{verticalAlign: "middle"}} src={baseUrl + "/goal.png"} alt={"Vlastní gól."}
                        title={"Vlastní gól"}/>;
        case EventType.CHANGE2:
        case EventType.CHANGE1:
            return <img style={{verticalAlign: "middle"}} src={baseUrl + "/change.png"} alt={"Střídání."}
                        title={"Střídání"}/>;
        case EventType.PENALTY:
            return <img style={{verticalAlign: "middle"}} src={baseUrl + "/penalty.png"} alt={"Penalta."}
                        title={"Penalta"}/>;
        case EventType.YELLOW_CARD1:
            return <img style={{verticalAlign: "middle"}} src={baseUrl + "/yellow-card1.png"}
                        alt={"První žlutá karta."} title={"První žlutá karta"}/>;
        case EventType.YELLOW_CARD2:
            return <img style={{verticalAlign: "middle"}} src={baseUrl + "/yellow-card2.png"}
                        title={"Druhá žlutá karta"}
                        alt={"Druhá žlutá karta."}/>;
        case EventType.RED_CARD:
            return <img style={{verticalAlign: "middle"}} src={baseUrl + "red-card.png"} alt={"Červená karta"}
                        title={"Červená karta"}/>;
    }
}

function getEventActors(matchEvent: MatchEvent) {

    switch (matchEvent.eventType) {
        case EventType.CHANGE2:
        case EventType.CHANGE1:
            return <Typography>
                <strong style={{color: "#FF3A3D", fontSize: "0.875rem"}}>Ven: </strong>
                <Link style={{color: "black", fontSize: "0.875rem", textDecoration: "none"}}
                      to={"/hrac/" + matchEvent.player.nameUrl + "/" + matchEvent.player.id}>{matchEvent.player.firstName + " " + matchEvent.player.lastName}</Link>
                <strong style={{color: "green", fontSize: "0.875rem"}}> Do: </strong>
                <Link style={{color: "black", fontSize: "0.875rem", textDecoration: "none"}}
                      to={"/hrac/" + matchEvent.changed.nameUrl + "/" + matchEvent.changed.id}>{matchEvent.changed.firstName + " " + matchEvent.changed.lastName}</Link>
            </Typography>;
        case EventType.OWN_GOAL:
            return <Link style={{color: "black", fontSize: "0.875rem", textDecoration: "none"}}
                         to={"/hrac/" + matchEvent.player.nameUrl + "/" + matchEvent.player.id}>{matchEvent.player.firstName + " " + matchEvent.player.lastName} (vlastní
                gól)</Link>;
        default:
            return <Link style={{color: "black", fontSize: "0.875rem", textDecoration: "none"}}
                         to={"/hrac/" + matchEvent.player.nameUrl + "/" + matchEvent.player.id}>{matchEvent.player.firstName + " " + matchEvent.player.lastName}</Link>;
    }
}

function MatchEventRow({matchEvent, revert}: { matchEvent: MatchEvent, revert: boolean }) {

    if (matchEvent.eventType == EventType.OWN_GOAL) {
        revert = !revert;
    }

    const align = revert ? "right" : "left";

    const eventTypeIcon = <Grid item xs={1} md={1}
                                style={{textAlign: "center"}}>{getMatchEventIcon(matchEvent.eventType)}</Grid>;
    const eventMinute = <Grid item xs={1} md={1} style={{textAlign: "center"}}><Typography
        variant={"subtitle2"}>{matchEvent.matchMinute + "'"}</Typography></Grid>;
    const eventActors = <Grid item style={{textAlign: align}} xs={10} md={10}>{getEventActors(matchEvent)}</Grid>;

    const result = revert ? [eventActors, eventMinute, eventTypeIcon] : [eventTypeIcon, eventMinute, eventActors];

    return (
        <Grid item container key={matchEvent.id}
              style={{
                  paddingLeft: 10,
                  paddingTop: 5,
                  paddingBottom: 5,
                  borderBottom: "1px dashed rgba(0, 0, 0, 0.12)"
              }}>
            {result}
        </Grid>
    );
}

function renderMatchRows(events: Array<MatchEvent>, homeTeamId: number, halfScoreHome: number, halfScoreAway: number) {
    let halfDelimiterUsed = false;
    const result: any = [];
    events.map((matchEvent: MatchEvent) => {
        if (!halfDelimiterUsed && matchEvent.matchMinute > 45) {
            halfDelimiterUsed = true
            result.push(
                <Grid item container alignContent={"center"} justify={"center"}
                      style={{
                          paddingTop: 10,
                          paddingBottom: 10,
                          borderBottom: "1px dashed rgba(0, 0, 0, 0.12)"
                      }}>
                    <Grid><Typography
                        color={"textSecondary"}>{"Poločas: " + halfScoreHome + ":" + halfScoreAway}</Typography></Grid>
                </Grid>
            )
        }
        result.push(<MatchEventRow matchEvent={matchEvent} revert={matchEvent.team.id !== homeTeamId}/>)
    })

    return result;
}


export default function MatchEvents({
                                        events,
                                        homeTeamId,
                                        halfScoreHome,
                                        halfScoreAway,
                                        matchId
                                    }: { events: Array<MatchEvent>, homeTeamId: number, halfScoreHome: number, halfScoreAway: number, matchId: number }) {

    return (
        <Grid container direction={"column"}>
            {
                events === undefined || events.length === 0 ?
                    (
                        <Grid item>
                            <Typography color={"textSecondary"} style={{
                                paddingLeft: 20,
                                paddingTop: 20,
                                paddingBottom: 20
                            }}>Nejsou k dispozici žádné události. </Typography>
                        </Grid>) :
                    (
                        renderMatchRows(events, homeTeamId, halfScoreHome, halfScoreAway)
                    )
            }
            <WhoWins played={halfScoreHome !== -1 || halfScoreAway !== -1}
                     matchId={matchId}
            />
        </Grid>
    );
}