import React from "react";
import {Match, Referee} from "../common/matches/matches";
import {Grid, Paper, Typography} from "@material-ui/core";
import HeaderTitle from "../common/headerTitle";


export default function MatchInfo({match}: { match: Match }) {

    const joinAssistants = (assistants: Array<Referee>) => assistants.map(assistant => assistant.firstName + " " + assistant.lastName)
        .reduce((prev, curr) => prev + ', ' + curr);

    const render = (key: string, value: string) => {
        return (
            <Grid container style={{paddingLeft: 15, paddingTop: 10}}>
                <Grid item xs={4} sm={3}> <Typography style={{fontWeight: "bold"}}
                                                      color={"textPrimary"}>{key}</Typography> </Grid>
                <Grid item xs={8} sm={9}><Typography>{value}</Typography></Grid>
            </Grid>);
    };

    return (
        <Paper style={{paddingBottom: 20}}>
            <Grid container>
                <Grid item sm={12}>
                    <HeaderTitle title={"Info o zápase"}/>
                </Grid>
                {
                    render("Datum", match.formattedDate)
                }
                {
                    render("Čas", match.formattedTime)
                }
                {
                    match.mainReferee !== undefined ? (render("Hl. rozhodčí", `${match.mainReferee.firstName} ${match.mainReferee.lastName}`)) : (<></>)
                }
                {
                    match.assistants !== undefined && match.assistants.length !== 0 ? (render("As. rozhodčího", joinAssistants(match.assistants))) : (<></>)
                }
                {
                    render("Stadion", match.field)
                }

            </Grid>
        </Paper>
    );
}