import React from "react";
import {
    Avatar,
    Backdrop,
    Box,
    CircularProgress,
    createStyles,
    FormControl,
    Grid,
    Hidden,
    NativeSelect,
    Theme,
    Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import HeaderTitle from "../headerTitle";
import {noLogo, serverUrl} from "../../../index";
import {PlayerDetail} from "../../player/player";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            paddingLeft: 20,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }),
);

export interface PlayerStat {
    player: PlayerDetail,
    count: number
}

function PlayerStatRow({playerStat, index}: { playerStat: PlayerStat, index: number }) {

    let avatarUrl = playerStat.player.club != undefined && playerStat.player.club.logoCrop != undefined ? playerStat.player.club.logoCrop : noLogo;

    return (
        <Grid item container alignContent={"flex-start"} alignItems={"center"} style={{paddingBottom: 10}}>
            <Grid item xs={1}><Typography color={"textSecondary"}>{index + "."}</Typography></Grid>
            <Hidden smDown>
                <Grid item xs={2}><Avatar alt="Remy Sharp" src={avatarUrl}/></Grid>
            </Hidden>
            <Grid item container direction="column" xs={8}>
                <Grid item
                      xs={12}>
                    <Link to={"/hrac/" + playerStat.player.nameUrl + "/" + playerStat.player.id}
                          style={{color: "#008bbb", textDecoration: "none"}}>
                        <Typography
                            variant={"body1"}>{playerStat.player.firstName.length + playerStat.player.lastName.length > 20 ? playerStat.player.lastName : playerStat.player.firstName + ' ' + playerStat.player.lastName}</Typography>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="button" style={{fontSize: "0.75rem"}}
                                color={"textSecondary"}>{playerStat.player.club?.name}</Typography>
                </Grid>
            </Grid>
            <Grid item xs={1} alignContent={"center"}>
                <Typography align={"center"}>{playerStat.count}</Typography>
            </Grid>
        </Grid>
    );
}

export default function Statistics({id, entity}: { id: number, entity: string }) {

    const classes = useStyles();
    const [statsType, setStatsType] = React.useState<string>("GOALS");
    const [playerStats, setPlayerStats] = React.useState<Array<PlayerStat>>([]);
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        setOpen(true);
        fetch(serverUrl + '/' + entity + '/' + id + '/statistics?type=' + statsType + "&limited=false")
            .then(res => res.json() as Promise<Array<PlayerStat>>)
            .then((result) => {
                setPlayerStats(result)
                setTimeout(() => {
                    setOpen(false);
                }, 200);
            })
            .catch(console.log)
    }, [id, statsType]);

    const renderStats = () => {
        return playerStats.map((playerStat, index) => <PlayerStatRow playerStat={playerStat} index={++index}/>);
    }

    const handleChange = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
        setStatsType(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <Box style={{
            borderTop: "1px solid #dce0e4",
            borderRight: "1px solid #dce0e4",
            borderLeft: "1px solid #dce0e4",
            minHeight: 500
        }}>
            <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <HeaderTitle title={"Statistiky hráčů"}/>
            <FormControl className={classes.formControl}>
                <NativeSelect
                    onChange={handleChange}
                    name="statsType"
                    className={classes.selectEmpty}
                    inputProps={{'aria-label': 'age'}}
                >
                    <option value="GOALS">Střelci</option>
                    <option value="YELLOW_CARDS">Žluté karty</option>
                    <option value="RED_CARDS">Červené karty</option>
                </NativeSelect>
            </FormControl>
            <Grid container
                  style={{marginTop: "20px", paddingBottom: 50, marginLeft: 20}} spacing={2}>
                <Grid item container xs={12} sm={6} alignContent={"flex-start"} alignItems={"center"}>
                    <Grid item container>
                        <Grid item xs={1}><Typography variant={"body2"} color={"textSecondary"}>#</Typography></Grid>
                        <Hidden smDown>
                            <Grid item xs={2}/>
                        </Hidden>
                        <Grid item xs={8}/>
                        <Grid item xs={1}><Typography variant={"body2"}
                                                      color={"textSecondary"}>{statsType === 'GOALS' ? "Střelci" : statsType === 'YELLOW_CARDS' ? "Žluté" : "Červené"}</Typography></Grid>
                    </Grid>
                    {renderStats()}
                </Grid>
                <Grid item container xs={12} sm={6}></Grid>
            </Grid>

        </Box>
    );

}