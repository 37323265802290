import React from "react";
import {Grid} from "@material-ui/core";
import TopMatches from "../../common/matches/top/topMatches";


export default function Top() {
    return (
        <Grid container style={{paddingTop: 40, paddingBottom: 60}} >
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4}><TopMatches statsType={"GOALS"} leagueId={undefined}/> </Grid>
                <Grid item xs={12} sm={4}><TopMatches statsType={"YELLOW_CARDS"} leagueId={undefined}/> </Grid>
                <Grid item xs={12} sm={4}><TopMatches statsType={"RED_CARDS"} leagueId={undefined}/> </Grid>
            </Grid>

        </Grid>
    );


}