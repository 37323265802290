import React from "react";
import {Grid} from "@material-ui/core";
import PopularClubs from "./popularClubs";
import PopularLeagues from "./popularLeagues";
import PopularMatches from "./popularMatches";


export default function Popular() {
    return (
        <Grid container style={{paddingTop: 40, paddingBottom: 60}}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={3}><PopularClubs/> </Grid>
                <Grid item xs={12} sm={4}><PopularLeagues/> </Grid>
                <Grid item xs={12} sm={5}><PopularMatches/> </Grid>
            </Grid>

        </Grid>
    );


}