import React from "react";
import {noLogo, serverUrl} from "../../../index";
import {Grid, TableBody, TableHead, TableRow} from "@material-ui/core";
import {Link} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import {makeStyles} from "@material-ui/core/styles";
import {Match} from "../../common/matches/matches";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 8
    }
}));

export default function PopularMatches() {

    const classes = useStyles();

    const [matches, setMatches] = React.useState<Array<Match>>([]);

    React.useEffect(() => {
        fetch(serverUrl + '/suggest/popular-matches')
            .then(res => res.json() as Promise<Array<Match>>)
            .then((result) => {
                setMatches(result);
            })
            .catch(
                console.log
            )
    }, []);

    return (
        <Grid>
            <Table aria-label="simple table" style={{padding: 10, border: "1px solid #e0e0e0"}}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.root}>
                            <Typography variant={"h6"} component={"h2"}
                                        style={{color: "#008e45"}}>Populární zápasy</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        matches.map((match: Match) => (
                            <TableRow key={match.id}>
                                <TableCell className={classes.root} align="left">
                                    <Link style={{textDecoration: "none", color: "rgb(30, 65, 100)"}}
                                    to={"/zapas/" + match.nameUrl + "/" + match.id}>
                                    <Grid container>
                                          <Grid item xs={4}>
                                              <img width={25} height={25} src={match.home.logo ?? noLogo}
                                                   style={{
                                                       paddingRight: 10,
                                                       marginRight: 10,
                                                       verticalAlign: "middle"
                                                   }}/>
                                              <Typography component={"span"} style={{fontSize: 14}}>{match.home.name}</Typography>
                                          </Grid>
                                          <Grid container item xs={4} alignContent={"center"} alignItems={"center"} justify={"center"}>
                                              <Grid item style={{marginLeft: 10, marginRight: 10, fontSize: 10, color: "grey", textAlign: "center"}} component={"span"}>
                                                  {

                                                      match.homeTeamHalfTimeScore === -1 ?
                                                          <Typography style={{fontSize: 12}} component={"span"} variant={"body2"}>{match.formattedDate + ", " + match.formattedTime + " hod."}</Typography>
                                                          :
                                                          <Typography component={"span"} variant={"body2"} align={"center"}>{match.homeTeamFullTimeScoreWithKicks + ":" + match.awayTeamFullTimeScoreWithKicks}</Typography>
                                                  }
                                              </Grid>
                                          </Grid>
                                          <Grid item xs={4}>
                                              <Typography component={"span"} style={{fontSize: 14}}>{match.away.name}</Typography>
                                              <img width={25} height={25} src={match.away.logo ?? noLogo}
                                                   style={{
                                                       paddingLeft: 10,
                                                       marginLeft: 10,
                                                       verticalAlign: "middle"
                                                   }}/>
                                          </Grid>
                                    </Grid>
                                </Link>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>

        </Grid>
    );
}