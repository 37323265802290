import React from "react"
import {Menu} from "../../../common/content/content";
import {MenuContent3} from "../../../common/content/content3";
import Matches, {MatchesContext} from "../../../common/matches/matches";
import {useOutletContext} from "react-router-dom";
import {ClubSubPageContext, getMenuItems} from "../../club";
import DognetAd, {AdType} from "../../../common/ads/dognetAd";
import Ad from "../../../common/ads/ad";


export default function ClubMatches ()
{
    const context = useOutletContext<ClubSubPageContext>();

    return (
        <>
            <Menu menuTabs={getMenuItems(context.clubName, context.clubId)} selectedTab={1}/>
            <MenuContent3 tabId={1} content={
                <>
                    <Matches id={context.clubId} context={MatchesContext.CLUB}/>
                    <Ad ad={<ins className="adsbygoogle"
                                 style={{display:"block"}}
                                 data-ad-client="ca-pub-7325589669239459"
                                 data-ad-slot="2653121138"
                                 data-ad-format="auto"
                                 data-full-width-responsive="true"></ins>}
                        height={100} marginY={3}
                    />
                    {/*<DognetAd marginY={3} webAdType={AdType.HORIZONTAL} mobileAdType={AdType.CUBE}/>*/}
                </>
            }/>
        </>
    )
}