import React from "react";
import {Match} from "../common/matches/matches";
import {Grid, Paper, Typography} from "@material-ui/core";

export default function MatchScoreExt({match}: { match: Match }) {

    const render = (color: string, text: string) => {
        return (
            <Grid item sm={12} style={{paddingLeft: 20, paddingTop: 10, paddingBottom: 10, color: color}}>
                <Typography
                    variant={"h6"}>{text}</Typography>
            </Grid>
        )
    };


    let extScore = match.homeTeamKicks != match.awayTeamKicks || match.contumacy || match.bothSideContumacy;

    return (
        extScore ?
            <Paper>
                <Grid container>
                    {match.homeTeamKicks != match.awayTeamKicks && render("blue", `Penalty: ${match.homeTeamKicks} : ${match.awayTeamKicks}`)}
                    {match.contumacy && render("blue", `Kontumace: ${match.contumacyHomeTeamResult} : ${match.contumacyAwayTeamResult}`)}
                    {match.bothSideContumacy && render("blue", "Zápas byl oboustraně kontumován.")}
                </Grid>
            </Paper>
            : <></>
    );
}
