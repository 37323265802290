import React from "react";
import {Helmet} from "react-helmet";
import Navbar from "../navbar/Navbar";
import Box from "@material-ui/core/Box";
import Footer from "../common/footer/footer";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import CollaborationInterest, {CollabTypeE} from "./collaborationInterest";


export default function Collaboration() {

    return (
        <Box>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Spolupráce - www.kdesehraje.cz</title>
                <meta name="description"
                      content={"kdesehraje, kde se hraje, fotbal, fotbalové zápasy, fotbalové statistiky, fotbalové výsledky"}/>
            </Helmet>
            <Navbar/>

            <Box minHeight={800} my={4}>
                <Container>
                    <Typography variant={"h3"} component={"h1"} align={"center"}
                                style={{color: "rgb(30, 65, 100)"}}>
                        Spolupráce
                    </Typography>

                    <Box mt={4} mb={4}>
                        <Typography variant={"h5"} component={"h2"} style={{marginBottom: 5, color: "rgb(0, 142, 69)"}}>
                            Reklama na Kdesehraje.cz
                        </Typography>
                        <Typography style={{color: "#222", fontSize: 15}}>
                            Reklama na portálu <strong>Kdesehraje.cz</strong> je chytrý a efektivní
                            způsob <strong> propagace vašich výrobků a služeb </strong>.
                            Máte možnost využít různé reklamní formáty v podobě bannerů, placených odkazů až po
                            prezentaci např. obchodů se sportovním vybavením.
                        </Typography>
                        <CollaborationInterest collabType={CollabTypeE.AD}/>
                    </Box>
                    <Box mt={4}>
                        <Typography variant={"h5"} component={"h2"} style={{marginBottom: 5, color: "rgb(0, 142, 69)"}}>
                            Správcovství profilu klubu nebo týmu - Vaše nová webová prezentace!
                        </Typography>
                        <Typography style={{color: "#222", fontSize: 15}}>
                            Nemá Váš klub nebo tým webovou prezentaci? Nemáte čas přidávat veškeré informace k zápasům
                            Vašeho klubu/týmu a přesto byste chtěli prezentovat Vás klub/tým na internetu? Na
                            portálu <strong>kdesehraje.cz</strong> máte tuto příležitost. Budete mít možnost:
                        </Typography>
                        <ul style={{color: "#222", fontSize: 15}}>
                            <li>přidávat hodnocení k zápasům</li>
                            <li>vytvářet fotogalerie k zápasům/týmu/klubu</li>
                            <li>přidávat články o klubu/týmu</li>
                            <li>možnost oslovit příznivce klubu/týmu v podobě newsletteru</li>
                            <li>přidat informace k tréninkům mužstev</li>
                            <li>vytvářet ankety</li>
                            <li>propojení se soc. sítěmi</li>
                            <li>přidávat práva dalším lidem, kteří budou moci dělat výše zmíněné úkony</li>
                            <li>a další...</li>
                        </ul>
                        <CollaborationInterest collabType={CollabTypeE.PROFIL}/>
                    </Box>
                    <Box mt={4} mb={6}>
                        <Typography variant={"h5"} component={"h2"} style={{marginBottom: 5, color: "rgb(0, 142, 69)"}}>
                            Ostatní spolupráce
                        </Typography>
                        <Typography style={{color: "#222", fontSize: 15}}>
                            Pokud byste s námi chtěli spolupracovat i jinou formou, dejte nám vědět.
                        </Typography>
                        <CollaborationInterest collabType={CollabTypeE.OTHER}/>
                    </Box>
                </Container>
            </Box>

            <Footer/>
        </Box>
    );
}