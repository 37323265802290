import React from "react";
import {ClubDetail} from "../../club/club";
import {noLogo, serverUrl} from "../../../index";
import {Grid, TableBody, TableHead, TableRow} from "@material-ui/core";
import {Link} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 8
    }
}));

export default function PopularClubs() {

    const classes = useStyles();

    const [clubs, setClubs] = React.useState<Array<ClubDetail>>([]);

    React.useEffect(() => {
        fetch(serverUrl + '/suggest/popular-clubs')
            .then(res => res.json() as Promise<Array<ClubDetail>>)
            .then((result) => {
                setClubs(result);
            })
            .catch(
                console.log
            )
    }, []);

    return (
        <Grid>
            <Table aria-label="simple table" style={{padding: 10, border: "1px solid #e0e0e0"}}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.root}>
                            <Typography variant={"h6"} component={"h2"}
                                        style={{color: "#008e45"}}>Populární kluby</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        clubs.map((club: ClubDetail) => (
                            <TableRow key={club.id}>
                                <TableCell className={classes.root} align="left"><Link
                                    style={{textDecoration: "none", color: "rgb(30, 65, 100)", fontSize: 14}}
                                    to={"/klub/" + club.nameUrl + "/" + club.id}>
                                    <img width={25} height={25} src={club.logoCrop ?? noLogo}
                                         style={{
                                             paddingRight: 10,
                                             marginRight: 10,
                                             verticalAlign: "middle"
                                         }}/>
                                    {club.name}
                                </Link></TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>

        </Grid>
    );
}