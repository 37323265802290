import {Match} from "./components/common/matches/matches";


export function resultWinningClass(match: Match, home: boolean) {
    if (match.homeTeamFullTimeScoreWithKicks === undefined || match.awayTeamFullTimeScoreWithKicks === undefined)
        return "textPrimary";

    if (match.homeTeamFullTimeScoreWithKicks > match.awayTeamFullTimeScoreWithKicks) {
        return home ? "textPrimary" : "textSecondary";
    } else if (match.homeTeamFullTimeScoreWithKicks < match.awayTeamFullTimeScoreWithKicks) {
        return home ? "textSecondary" : "textPrimary";
    } else {
        return "textPrimary";
    }
}