import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './components/navbar/Navbar'
import Club from "./components/club/club";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Home} from "./components/home/home";
import {Team} from "./components/team/team";
import {League} from './components/league/league';
import {Player} from "./components/player/player";
import NoMatch from "./components/common/noMatch";
import MatchDetail from "./components/match/matchDetail";
import SignIn from "./components/user/signin";
import SignUp from "./components/user/signup";
import UserContextProvider from "./components/auth/userContextProvider";
import Profile from "./components/user/profile";
import Activation from "./components/user/activation";
import Contact from "./components/contact/contact";
import HeroesInLowerLeagues from "./components/articles/heroesInLowerLeagues";
import Competitions from "./components/competitions/competitions";
import MakeLog from "./components/makelog/makeLog";
import Collaboration from "./components/collaboration/collaboration";
import ResetPassword from "./components/user/resetPassword";
import SetNewPassword from "./components/user/setNewPassword";
import ClubHome from "./components/club/tabs/home/clubHome";
import ClubMatches from "./components/club/tabs/matches/clubMatches";
import ClubTeams from "./components/club/tabs/teams/clubTeams";
import TeamHome from "./components/team/tabs/home/teamHome";
import TeamTable from "./components/team/tabs/table/teamTable";
import TeamMatches from "./components/team/tabs/matches/teamMatches";
import TeamPlayers from "./components/team/tabs/players/teamPlayers";
import TeamStatistics from "./components/team/tabs/statistics/teamStatistics";
import LeagueHome from "./components/league/tabs/home/leagueHome";
import LeagueMatches from "./components/league/tabs/matches/leagueMatches";
import LeagueTableTab from "./components/league/tabs/table/leagueTableTab";
import LeagueStatistics from "./components/league/tabs/statistics/leagueStatistics";
import SeznamMap from "./components/map/seznamMap";

export const serverUrl = "https://www.api.kdesehraje.cz";
//export const serverUrl = "http://localhost:8081";
export const noLogo = "https://www.kdesehraje.cz/media/logo.png";

export default function App() {

    return (
        <BrowserRouter>
            <UserContextProvider>
                <Routes>
                    <Route index element={<Home/>}/>
                    {/*<Route path="/mapa" element={<MapService/>}/>*/}
                    <Route path="/mapa" element={<SeznamMap/>}/>
                    {/*<Route path="/zapasy-v-okoli" element={<NearestMatches/>}/>*/}
                    <Route path="/klub/:clubName/:clubId" element={<Club/>}>
                        <Route index element={<ClubHome/>}/>
                        <Route path="zapasy" element={<ClubMatches/>}/>
                        <Route path="tymy" element={<ClubTeams/>}/>
                    </Route>
                    <Route path="/tym/:teamName/:teamId" element={<Team/>}>
                        <Route index element={<TeamHome/>}/>
                        <Route path="zapasy" element={<TeamMatches/>}/>
                        <Route path="tabulka" element={<TeamTable/>}/>
                        <Route path="hraci" element={<TeamPlayers/>}/>
                        <Route path="statistiky" element={<TeamStatistics/>}/>
                    </Route>
                    <Route path="/liga/:leagueName/:leagueId" element={<League/>}>
                        <Route index element={<LeagueHome/>}/>
                        <Route path="zapasy" element={<LeagueMatches/>}/>
                        <Route path="tabulka" element={<LeagueTableTab/>}/>
                        <Route path="statistiky" element={<LeagueStatistics/>}/>
                    </Route>

                    <Route path="/souteze/:competitionName/:competitionId" element={<Competitions/>}/>
                    <Route path="/hrac/:playerName/:playerId" element={<Player/>}/>
                    <Route path="/zapas/:matchName/:matchId" element={<MatchDetail/>}/>
                    <Route path="/prihlaseni" element={<SignIn/>}/>
                    <Route path="/registrace" element={<SignUp/>}/>
                    <Route path="/hrdinove-v-nizsich-soutezich" element={<HeroesInLowerLeagues/>}/>
                    <Route path="/uzivatel/reset-hesla" element={<ResetPassword/>}/>
                    <Route path="/uzivatel/nastaveni-noveho-hesla" element={<SetNewPassword/>}/>
                    <Route path="/uzivatel/profil" element={<Profile/>}/>
                    <Route path="/uzivatel/aktivace" element={<Activation/>}/>
                    <Route path="/kontakt" element={<Contact/>}/>
                    <Route path="/co-je-noveho" element={<MakeLog/>}/>
                    <Route path="/spoluprace" element={<Collaboration/>}/>
                    <Route element={NoMatch}/>
                </Routes>
            </UserContextProvider>
        </BrowserRouter>
    );
}


ReactDOM.render(<App/>, document.getElementById("root"))
