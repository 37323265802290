import React from "react";

const defaultUser = {
    user: {
        nickname: "nickname",
        firstName: "firstName",
        lastName: "lastName",
        email: "email@email.cz"
    },
    logged: false,
    token: "",
    expireAt: undefined,
};


const defaultContext = {
    userData: defaultUser,
    setLoggedUser: (newLoggedUser: any) => {
    },
    logoutUser: () => {
    },
    isLogged: () => {
        return false
    }
};


export const UserContext = React.createContext(defaultContext);