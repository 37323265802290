import React from "react";
import HeaderTitle from "../common/headerTitle";
import {Box, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";
import {noLogo, serverUrl} from "../../index";
import {ClubDetail} from "../club/club";
import {Link} from "react-router-dom";

interface PlayerTransfer {
    clubFrom: ClubDetail,
    clubTo: ClubDetail,
    fromDateFormatted: string,
    toDateFormatted: string,
    activity: string
}

export default function PlayerTransferBox({playerId}: { playerId: number }) {

    const [playerTransfers, setPlayerTransfers] = React.useState<Array<PlayerTransfer> | null>(null);

    React.useEffect(() => {
        fetch(serverUrl + '/player/' + playerId + '/transfers')
            .then(response => {
                if (response.status < 300) {
                    return Promise.resolve(response.json()) as Promise<Array<PlayerTransfer>>;
                }
                return Promise.reject(response);
            })
            .then((result) => {
                setPlayerTransfers(result)
            }, error => {
                console.log(error);
            })
            .catch(console.log)
    }, [playerId]);

    return (
        <Box style={{marginTop: 20, backgroundColor: "white", border: "1px solid #dce0e4"}}>
            <HeaderTitle title={"Transakce"}/>
            <Table size={"medium"} style={{borderTop: "1px solid #dce0e4"}}>
                <TableHead>
                    <TableRow>
                        <TableCell>Z klubu</TableCell>
                        <TableCell>Do klubu</TableCell>
                        <TableCell>Od - do</TableCell>
                        <TableCell>Typ</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {playerTransfers == null ?
                        <TableRow>
                            <TableCell colSpan={4}> Žádný záznam nenalezen.</TableCell>
                        </TableRow> :
                        (playerTransfers.map((playerTransfer: PlayerTransfer) => (
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <img
                                        src={playerTransfer.clubFrom.logoCrop !== undefined ? playerTransfer.clubFrom.logoCrop : noLogo}
                                        height={30} alt={"Logo klubu z kterého se přestupovalo"}
                                        width={30} style={{paddingRight: 10, verticalAlign: "middle"}}/>
                                    <Link style={{textDecoration: "none", color: "#008bbb"}}
                                        to={"/klub/" + playerTransfer.clubFrom.nameUrl + "/" + playerTransfer.clubFrom.id}><Typography
                                        display={"inline"}>{playerTransfer.clubFrom.name}</Typography></Link>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <img
                                        src={playerTransfer.clubTo.logoCrop !== undefined ? playerTransfer.clubTo.logoCrop : noLogo}
                                        height={30} alt={"Logo klubu do kterého se přestupovalo"}
                                        width={30} style={{paddingRight: 10, verticalAlign: "middle"}}/>
                                    <Link style={{textDecoration: "none", color: "#008bbb"}}
                                        to={"/klub/" + playerTransfer.clubTo.nameUrl + "/" + playerTransfer.clubTo.id}><Typography
                                        display={"inline"}>{playerTransfer.clubTo.name}</Typography></Link>
                                </TableCell>
                                <TableCell>{playerTransfer.fromDateFormatted + " - " + playerTransfer.toDateFormatted}</TableCell>
                                <TableCell>{playerTransfer.activity}</TableCell>
                            </TableRow>
                        )))}
                </TableBody>
            </Table>
        </Box>
    );

}