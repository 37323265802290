import React from "react";
import {Box} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {Helmet} from "react-helmet";
import Navbar from "../navbar/Navbar";
import Divider from "@material-ui/core/Divider";
import Footer from "../common/footer/footer";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {Alert} from "@material-ui/lab";
import ReactGA from "react-ga";

function MakeLogItem({title, text, image, created}: { title: string, text: string, image: object, created: string }) {
    return (
        <Box style={{borderBottom: "1px solid #dce0e4", marginBottom: 10, paddingBottom: 10}}>
            <Grid container direction={"column"} style={{padding: 15}}>
                <Grid item>
                    <Typography variant={"h5"} style={{color: "#008e45", paddingBottom: 10, paddingTop: 10}}>
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography align={"justify"} style={{marginBottom: 20}}>
                        {text}
                    </Typography>
                </Grid>
                <Grid item>
                    {image}
                </Grid>
                <Grid item style={{paddingTop: 30}}>
                    <Typography variant={"subtitle2"} style={{color: "grey"}}>
                        {created}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}


export default function MakeLog() {

    React.useEffect(() => {
        ReactGA.initialize("UA-116060364-1");
        ReactGA.pageview(window.location.pathname);
    }, [])

    return (
        <Box>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Co je nového na našich stránkách</title>
                <meta name="description"
                      content={"kdesehraje, kde se hraje, fotbal, fotbalové zápasy, fotbalové statistiky, fotbalové výsledky, novinky"}/>
            </Helmet>
            <Navbar/>
            <Container style={{paddingTop: 20, marginTop: 20}}>
                <Typography variant={"h3"} component={"h1"}
                            style={{color: "#1e4164", textAlign: "center", marginTop: 40}}>
                    Co je nového?
                </Typography>

                <Alert icon={false} elevation={6} variant={"filled"} severity="info"
                       style={{marginBottom: 10, marginTop: 20}}>
                    Chybí Vám na našich stránkách nějaká funkce? Dejte nám o ní vědět
                    <Button component={Link} variant="contained" color="primary" style={{marginLeft: 10}}
                            to={"/kontakt"}> zde</Button>
                </Alert>

                <MakeLogItem
                    title={"Detail zápasu se všemi důležitými událostmi"}
                    text={"Abyste byli ihned v obraze, jak jednotlivé zápasy týmů dopadly, je možné klikat na jednotlivé zápasy a zobrazí se podrobné informace."}
                    image={
                        <img src={"https://storage.googleapis.com/make-log/club-matches-highlighted.png"}
                             style={{height: "auto", maxWidth: "70%"}}
                             alt={"Detail zápasu se všemi podrobnostmi"}/>
                    }
                    created={"Přidáno 2. 12. 2020"}
                />
                <MakeLogItem
                    title={"Možnost úpravy pozice a číslo dresu hráče"}
                    text={"Občas se stane, že pozice hráče nebo číslo dresu není správné. Proto jsme připravili funkci, kde můžete zadat správné údaje. Tato funkce je dostupná na profilu týmu v sekci hráči."}
                    image={
                        <img src={"https://storage.googleapis.com/make-log/team-players-screenshot-highlighted.png"}
                             style={{height: "auto", maxWidth: "70%"}}
                             alt={"Změna pozice a číslo dresu hráče"}/>
                    }
                    created={"Přidáno 2. 12. 2020"}
                />
                <MakeLogItem
                    title={"Možnost hlasování o výsledků zápasu"}
                    text={"U detailu zápasu je nyní možné přidat hlas tak, jak si myslíte, že zápas dopadne."}
                    image={
                        <img src={"https://storage.googleapis.com/make-log/user-votes-highlighted.png"}
                             style={{height: "auto", maxWidth: "70%"}}
                             alt={"Hlasování o výsledku zápasu"}/>
                    }
                    created={"Přidáno 2. 12. 2020"}
                />
                <MakeLogItem
                    title={"Populární kluby na hlavní stránce"}
                    text={"Na hlavní stránce nyní zobrazujeme populární kluby. Po prokliku je možné se dostat na profil klubu."}
                    image={
                        <img src={"https://storage.googleapis.com/make-log/homepage-popular-clubs-highlighted.png"}
                             style={{height: "auto", maxWidth: "70%"}}
                             alt={"Populární kluby"}/>
                    }
                    created={"Přidáno 2. 12. 2020"}
                />
                <MakeLogItem
                    title={"Možnost přidávání odkazů na sociální sítě klubu"}
                    text={"Nyní je možné přidat odkaz na sociální sítě klubu - Instagram, Facebook, Youtube nebo webová stránka."}
                    image={
                        <img src={"https://storage.googleapis.com/make-log/socmedia-edit-highlighted.png"}
                             style={{height: "auto", maxWidth: "70%"}}
                             alt={"Přidání odkazů na sociální sítě"}/>
                    }
                    created={"Přidáno 2. 12. 2020"}
                />
                <MakeLogItem
                    title={"Poslední odehraný zápas v profilu týmu"}
                    text={"U každého týmu je nyní možné vidět poslední odehraný zápas."}
                    image={
                        <img src={"https://storage.googleapis.com/make-log/team-last-match-highlighted.png"}
                             style={{height: "auto", maxWidth: "70%"}}
                             alt={"Náhled profilu týmu s posledním zápasem"}/>
                    }
                    created={"Přidáno 1. 12. 2020"}
                />
            </Container>
            <Divider/>
            <Footer/>
        </Box>
    );
}