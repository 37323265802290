import React from "react";
import {Box} from "@material-ui/core";


export default function Ad({ad, height, marginY}: {ad:object, height: number, marginY:number}) {

    React.useEffect(() => {
        // @ts-ignore
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, [])


    return (
        <Box my={marginY} style={{
            maxHeight: height,
            backgroundColor: "white",
            textAlign: "center"
        }}>
            {ad}
        </Box>
    );
}