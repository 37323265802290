import React from "react"
import {Box, Breadcrumbs} from "@material-ui/core";
import {useParams} from "react-router";
import {Mapping, MenuItem} from "../common/content/content";
import Navbar from "../navbar/Navbar";
import Header from "../common/header/header";
import Divider from "@material-ui/core/Divider";
import {LeagueType} from "../team/team";
import {serverUrl} from "../../index";
import Footer from "../common/footer/footer";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga";
import Grid from "@material-ui/core/Grid";
import Follow from "../common/follow/follow";
import {Region} from "../competitions/competitions";
import {Link, Outlet} from "react-router-dom";

function getMenuTabs(leagueName: string, leagueId: number) {

    const base: string = "/liga/" + leagueName + "/" + leagueId;

    const news: MenuItem = {
        name: "Novinky",
        nameUrl: base + ""
    };

    const matches: MenuItem = {
        name: "Zápasy",
        nameUrl: base + "/zapasy"
    };

    const teams: MenuItem = {
        name: "Tabulka",
        nameUrl: base + "/tabulka"
    };

    const stats: MenuItem = {
        name: "Statistiky",
        nameUrl: base + "/statistiky"
    };

    return [
        news,
        matches,
        teams,
        stats
    ];
}

export type LeagueSubPageContext = { leagueName: string, leagueId: number, menuTabs: Array<MenuItem> };

const mapping: Mapping = {
    header: "Ligy",
    navigation: "Liga"
};

const leagueDefault: LeagueDetail = {
    name: "",
    type: {name: "", color: ""},
    region: {nameUrl: "", name: "", id: 0}
};

export interface League {
    id: number,
    name: string,
    nameUrl: string
}

interface LeagueDetail {
    name: string,
    type: LeagueType
    region: Region
}

export function League() {

    // @ts-ignore
    let {leagueName, leagueId}: { leagueName: string, leagueId: number } = useParams();
    const [leagueDetail, setLeagueDetail] = React.useState<LeagueDetail>(leagueDefault);


    React.useEffect(() => {
        ReactGA.initialize("UA-116060364-1");
        ReactGA.pageview(window.location.pathname);

        fetch(serverUrl + '/league/' + leagueId + '/detail')
            .then(res => res.json())
            .then((result) => {
                setLeagueDetail(result);
            })
            .catch(console.log)
    }, [leagueId]);

    return (
        <Box>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Liga {leagueDetail.name} - www.kdesehraje.cz</title>
                <meta name="description"
                      content={"kdesehraje, kde se hraje, fotbal, fotbalové zápasy, fotbalové statistiky, fotbalové výsledky, " + leagueDetail.name}/>
            </Helmet>
            <Navbar/>

            <Header
                logoUrl={""}
                name={leagueDetail.name}
                type={leagueDetail.type.name}
                useLogo={false}
                content={
                    <Grid container style={{paddingBottom: 10}}>
                        <Grid item xs={12} sm={6}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link to="/" style={{textDecoration: "none", color: "grey"}}>
                                    Domů
                                </Link>
                                <Link
                                    to={"/souteze/" + leagueDetail.region.nameUrl + "/" + leagueDetail.region.id}
                                    style={{textDecoration: "none", color: "grey"}}>
                                    {leagueDetail.region.name}
                                </Link>
                                <Link
                                    to={"#"}
                                    style={{textDecoration: "none", color: "black"}}>
                                    {leagueDetail.name}
                                </Link>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Follow entityId={leagueId} entityType={"league"}/>
                        </Grid>
                    </Grid>
                }
            />
            <Divider/>
            <Outlet context={{
                "leagueId": leagueId,
                "leagueName": leagueName,
                menuTabs: getMenuTabs(leagueName, leagueId)
            }}/>
            <Divider/>
            <Footer/>
        </Box>
    );
}